<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 " style=" font-size: 0.98em;">
              Manage User Permissions
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for a user permissions"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"
              />
          </v-list-item-content>
          <v-list-item-content >
          <v-data-table
              class="px-4 elevation-0"
              :headers="headers"
              :items="items"
              sort-by="userGroupName"
              :search="search"
              style="cursor: pointer;"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                <p>Groups Permissions</p>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        tile
                        style="font-size: 0.88em;"
                        class="px-12 text-none"
                        v-bind="attrs"
                        v-on="on"
                        color="#339966"
                      >
                        Add a Permission
                      </v-btn>
                    </template>
                    <v-card tile>
                      <v-card-title>
                        <span style="font-size: 0.88em;" >{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                            <v-autocomplete
                              class="ma-0 pa-0"
                              v-model="userId"
                              item-value="id"
                              :items="userItems"
                              label="User"
                              color="#006622"
                              dense
                              filled
                              :item-text=" item=>item.firstname + ' ' + item.surname"
                              required
                              prepend-icon="mdi-account-group"
                              @change="userClick"
                            />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                            <v-autocomplete
                              multiple
                              chips
                              class="ma-0 pa-0"
                              v-model="reportId"
                              item-value="id"
                              :items="reportItems"
                              label="Report"
                              color="#006622"
                              filled
                              dense
                              :item-text=" item=>item.name + ', Workspace ' + item.workspace_name "
                              required
                              prepend-icon="mdi-newspaper"
                              @change="reportClick"
                            />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#006622"
                          text
                          style="font-size: 0.88em;"
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#006622"
                          text
                          @click="save"
                          style="font-size: 0.88em;"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card tile>
                    <v-card-text class="text-center pt-4 pb-2" style=" font-size: 0.98em;" ><h4>Are you sure you want to delete this permission?</h4></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#339966" text @click="closeDelete" style=" font-size: 0.88em;">Cancel</v-btn>
                        <v-btn color="#339966" text @click="deleteItemConfirm" style=" font-size: 0.88em;">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  color="#339966"
                  medium
                  @click="deleteItem(item)"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  dark
                  tile
                  style="font-size: 0.88em;"
                  class="text-none"
                  color="#339966"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
        <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      snackbar: false,
      selectedUserId: null,
      selectedReportIds: [],
      seletedDeleteItem: null,
      userId: null,
      reportId: null,
      dialog: false,
      dialogDelete: false,
      search: null,
      items: [],
      userItems: [],
      reportItems: [],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      headers: [ { text: 'Report Name', value: 'reportName' },
        { text: 'Workspace Name', value: 'workspace_name' },
        { text: 'Firstname', value: 'firstname' },
        { text: 'Firstname', value: 'surname' },
        { text: 'HIS Partner', value: 'user_type' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: '',
        reportName: '',
        userGroupName: ''
      },
      defaultItem: {
        id: '',
        reportName: 0,
        userGroupName: ''
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/userPermission/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      const userTypeId = localStorage.getItem('utp')
      window.axios.post('/user/findAllByUserType', { userTypeId: userTypeId })
        .then((response) => {
          this.userItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add a Permission' : 'Edit Permission'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    reloadGroupPermissions () {
      window.axios.get('/userPermission/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      // this.editedIndex = this.items.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      // check the item to delete here
      this.selectedDeleteItem = item
    },

    deleteItemConfirm () {
      // delete the permission here
      this.closeDelete()
      const id = this.selectedDeleteItem.id
      window.axios.post('/userPermission/delete', { id: id })
        .then((response) => {
          this.reloadGroupPermissions()
          if (response.data.affectedRows === 1) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully deleted the user report permission.' }
          } else {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to delete the user report permission.' }
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred please try again.' }
        })
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        // above is the edited item
      } else {
        if (this.selectedUserId == null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please select the stakeholder group to add a report permission to.' }
        } else if (this.selectedReportIds == null || this.selectedReportIds.length === 0) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please select the report to add permisssions for.' }
        } else {
          window.axios.post('/userPermission/insert', { userId: this.selectedUserId, reportIds: this.selectedReportIds })
            .then((response) => {
              this.selectedUserId = null
              this.selectedReportIds = []
              this.reloadGroupPermissions()
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully added the group report permission.' }
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to add the group report permission.' }
            })
        }
      }
      this.close()
    },
    userClick (item) {
      this.selectedUserId = item
      window.axios.post('/report/findUnselectedByUserId', { userId: item })
        .then((response) => {
          this.reportItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    reportClick (item) {
      this.selectedReportIds = item
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}

.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
