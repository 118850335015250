<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2 " style=" font-size: 0.98em;">
              Edit Profile
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-8 ma-4">
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="firstname"
                    color="#006622"
                    filled
                    label="Firstname"
                    name="Firstname"
                    prepend-icon="mdi-account-box"
                    type="text"
              />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="surname"
                    color="#006622"
                    filled
                    label="Surname"
                    name="Surname"
                    prepend-icon="mdi-account"
                    type="text"
              />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
            <v-text-field
                  v-model="email"
                  color="#006622"
                  filled
                  label="Email"
                  name="Email"
                  prepend-icon="mdi-mail"
                  type="text"
            />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="phone"
                    color="#006622"
                    filled
                    label="Phone"
                    name="Phone"
                    prepend-icon="mdi-phone"
                    type="text"
              />
              <v-card-actions id="btn-group" class="pb-6" width="100%">
                <v-btn @click="updatePassword" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;"> Edit Password </v-btn>
                <v-btn @click="update" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;"> Edit Profile</v-btn>
            </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
        <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white" > {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      snackbar: false,
      loading: false,
      systemMessage: null,
      id: null,
      firstname: null,
      surname: null,
      email: null,
      phone: null,
      password: null,
      search: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      var id = localStorage.getItem('kwkk')
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.post('/admin/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.id = response.data[0].id
            this.password = response.data[0].password
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    reset () {
      this.firstname = ''
      this.surname = ''
      this.email = ''
      this.phone = ''
      this.password = ''
    },
    update () {
      if (this.firstname === '' || this.firstname === null || this.surname === '' || this.surname === null || this.email === '' || this.email === null) {
        if (this.firstname === '' || this.firstname === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in your firstname' }
        } else if (this.surname === '' || this.surname === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in your surname' }
        } else if (this.email === '' || this.email === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in your email address' }
        } else if (this.phone === '' || this.phone.length < 10) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in the textfield with your correct phone number' }
        }
      } else {
        this.loading = true
        this.systemMessage = ''
        window.axios.post('/admin/update', { id: this.id, firstname: this.firstname, surname: this.surname, email: this.email, phone: this.phone, password: this.password })
          .then((response) => {
            // eslint-disable-next-line
            var affectedRows = response.data.response.affectedRows
            if (affectedRows === 1) {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited your profile' }
            } else {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited your profile' }
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
            this.snackbar = true
          })
      }
    },
    updatePassword () {
      this.$router.push('/editPassword')
    },
    load () {
      var id = localStorage.getItem('kwkk')
      window.axios.post('/admin/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.id = response.data[0].id
            this.password = response.data[0].password
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
