<template>
  <v-app id="inspire" class="grey lighten-3" xs12>
   <Navbar/>
   <v-main >
     <v-flex>
        <v-container
        fluid
      >
        <v-layout px-5  row class="mt-1">
          <v-flex xs10 sm10 md10 lg11 xl11 dense class="text-left">
            <div class="subtitle" style=" font-size: 0.98em;">Dashboard</div>
          </v-flex>
          <v-flex class="text-left" xs2 sm10 md2 lg1 xl1>
            <v-spacer/>
            <v-breadcrumbs class="pa-0" :items="crumbItems" id="crumbs">
              <template v-slot:item="{item}">
                <v-breadcrumbs-item :href="item.href" class=" text-right" :disabled="item.disabled" @click="breadCrumbClick(item)" style=" font-size: 0.98em;">
                  {{item.text}}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>
         <Header/>
        <v-layout row>
          <v-flex xs12 pa-5>
            <v-card
              tile
              class="mx-auto"
            >
              <v-list>
                <v-list-item-content>
                  <v-list-item-subtitle class="pl-4" style="font-size: 0.98em;" >
                    System Usage Trends
                  </v-list-item-subtitle>
                  <div class="pl-4 pr-4 pt-6">
                    <v-select
                      v-model="selectedGraphData"
                      :items="states"
                      menu-props="auto"
                      label="Select"
                      prepend-icon="mdi-looks"
                      single-line
                      color="#339966"
                      :hint="`Visualizing Data by ${selectedGraphData}`"
                      style="font-size: 1.0em;"
                      dense
                      persistent-hint
                      @change="fillData()"
                    ></v-select>
                  </div>
                </v-list-item-content>
                <v-list-item-content class="pl-2 pr-2 pt-4">
                  <LineChart :chart-data="datacollection" :height="350" :options="chartoptions"></LineChart>
                </v-list-item-content>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
        <Latest/>
      </v-container>
     </v-flex>
   </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Header from '@/components/Header'
import Latest from '@/components/Latest'
import LineChart from '../linechart'

export default {
  components: {
    Navbar,
    Header,
    Latest,
    LineChart
  },
  data () {
    return {
      selectedGraphData: 'Days',
      states: [
        'Days', 'Months'
      ],
      crumbItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '#',
          route: '/home'
        }
      ],
      datacollection: {},
      chartoptions: {
        zoom: true,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      // getting the viewership chart data
      if (this.selectedGraphData === 'Months') {
        window.axios.get('viewership/viewershipMonthlyChartData')
          .then((response) => {
            var chartData = response.data
            var day = []
            var count = []
            for (var i = 0; i < chartData.length; i++) {
              day.push(chartData[i].month)
              count.push(chartData[i].count)
            }
            // getting the signin chart data
            window.axios.get('viewership/signinMonthlyChartData')
              .then((signinResponse) => {
                var signinChartData = signinResponse.data
                var signinDay = []
                var signinCount = []
                for (var i = 0; i < signinChartData.length; i++) {
                  signinDay.push(signinChartData[i].month)
                  signinCount.push(signinChartData[i].count)
                }
                this.datacollection = {
                  labels: day,
                  datasets: [
                    {
                      label: `Monthly Viewership`,
                      borderColor: '#339966',
                      backgroundColor: '#339966',
                      fill: true,
                      data: count
                    },
                    {
                      label: `Daily Signins`,
                      borderColor: '#bc4749',
                      backgroundColor: '#bc4749',
                      fill: true,
                      data: signinCount
                    }
                  ]
                }

                this.chartoptions = {
                  responsive: true,
                  maintainAspectRatio: false
                }
                this.chartloaded = true
                this.chartloading = false
              })
          })
          // eslint-disable-next-line handle-callback-err
          .catch((error) => {
            this.barchartloading = false
          })
      } else {
        window.axios.get('viewership/viewershipChartData')
          .then((response) => {
            var chartData = response.data
            var day = []
            var count = []
            for (var i = 0; i < chartData.length; i++) {
              day.push(chartData[i].day)
              count.push(chartData[i].count)
            }
            // getting the signin chart data
            window.axios.get('viewership/signinChartData')
              .then((signinResponse) => {
                var signinChartData = signinResponse.data
                var signinDay = []
                var signinCount = []
                for (var i = 0; i < signinChartData.length; i++) {
                  signinDay.push(signinChartData[i].month)
                  signinCount.push(signinChartData[i].count)
                }
                this.datacollection = {
                  labels: day,
                  datasets: [
                    {
                      label: `Daily Viewership`,
                      borderColor: '#339966',
                      backgroundColor: '#339966',
                      fill: true,
                      data: count
                    },
                    {
                      label: `Daily Signins`,
                      borderColor: '#bc4749',
                      backgroundColor: '#bc4749',
                      fill: true,
                      data: signinCount
                    }
                  ]
                }

                this.chartoptions = {
                  responsive: true,
                  maintainAspectRatio: false
                }
                this.chartloaded = true
                this.chartloading = false
              })
          })
          // eslint-disable-next-line handle-callback-err
          .catch((error) => {
            this.barchartloading = false
          })
      }
    }
  }
}
</script>

<style scoped>
#crumbs{
  align-content: right;
  justify-content: right;
}

.v-select__selections {
 font-size: 0.0em;
}

text-size {
  font-size: 0.8em;
}

.override-class .v-select {
   font-size: 0.8em;
}

</style>

<style>
.v-select >>> .v-select__selections {
    font-size: 0.8em;
}
</style>
