<template>
  <v-app id="inspire">
    <v-main>
      <RegisterUser/>
    </v-main>
  </v-app>
</template>

<script>
import RegisterUser from '../components/RegisterUser'
export default {
  props: {
    source: String
  },
  components: {
    RegisterUser
  }
}
</script>
