<template>
  <v-app id="inspire">
    <v-main>
      <ForgotPassword/>
    </v-main>
  </v-app>
</template>

<script>
import ForgotPassword from '../components/ForgotPassword'
export default {
  props: {
    source: String
  },
  components: {
    ForgotPassword
  }
}
</script>
