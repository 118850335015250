<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 " style=" font-size: 0.98em;">
              Manage User Admin
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for users"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"/>
          </v-list-item-content>
          <v-list-item-content >
          <v-data-table
              class="px-4 elevation-0"
              :headers="headers"
              :items="items"
              sort-by=""
              style="cursor: pointer;"
              :search="search">
              <template v-slot:top>
                <v-toolbar
                  flat>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        tile
                        class="px-12 text-none"
                        v-bind="attrs"
                        v-on="on"
                        color="#339966"
                        style="font-size: 0.88em;"
                        @click="findUserAdminData">
                        Add a User Admin
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="subtitle-1">
                        <span style="font-size: 0.88em;" >{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12">
                            <v-autocomplete
                              class="ma-0 pa-0"
                              v-model="userId"
                              item-value="id"
                              :items="userItems"
                              label="User Details"
                              color="#006622"
                              dense
                              filled
                              :item-text=" item=>item.firstname + ' ' + item.surname "
                              required
                              prepend-icon="mdi-crown"
                              return-object
                              @change="getUserId"/>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#339966"
                          text
                          style="font-size: 0.88em;"
                          @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#339966"
                          text
                          style="font-size: 0.88em;"
                          @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card tile>
                    <v-card-text class="text-center pt-4 pb-2" style=" font-size: 0.98em;"><h4>Are you sure you want to remove this user admin?</h4></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#339966" text @click="closeDelete" style=" font-size: 0.88em;">Cancel</v-btn>
                        <v-btn color="#339966" text @click="deleteItemConfirm" style=" font-size: 0.88em;">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  color="#339966"
                  medium
                  @click="deleteItem(item)">
                  mdi-delete-forever
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  dark
                  tile
                  color="#339966"
                  style="font-size: 0.88em;"
                  class="px-12 text-none">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white" > {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      snackbar: false,
      userId: null,
      selectedUserId: null,
      dialog: false,
      dialogDelete: false,
      search: null,
      userItems: null,
      items: [],
      headers: [ { text: 'Firstame', value: 'firstname' },
        { text: 'Surname', value: 'surname' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'HIS Partner', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      editedIndex: -1,
      editedItem: {
        name: ''
      },
      defaultItem: {
        name: ''
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/userAdmin/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add a User Admin' : 'Edit User Admin'
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      // delete the permission here
      const id = this.editedItem.id
      window.axios.post('/userAdmin/delete', { id: id })
        .then((response) => {
          this.reload()
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully deleted the user status.' }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
        })
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // edit the item on the server and in the browser
      } else {
        // add a new user admin
        if (this.selectedUserId !== null) {
          window.axios.post('/userAdmin/insert', { userId: this.selectedUserId })
            .then((response) => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the user status.' }
              this.reload()
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
            })
        } else {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please select the user to add as a user admin.' }
        }
      }
      this.close()
    },
    reload () {
      window.axios.get('/userAdmin/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    findUserAdminData () {
      window.axios.get('/userAdmin/findAllUnavailable')
        .then((response) => {
          this.userItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    getUserId (item) {
      this.selectedUserId = item.id
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
