<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2" style=" font-size: 0.98em;">
              Delete {{ownerName}} Report
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-4 ma-6">
          <v-list dense  class="ma-0 pa-0">
              <v-autocomplete
                v-model="id"
                item-value="id"
                :items="item"
                label="Report Name"
                color="#006622"
                filled
                dense
                :item-text=" item=>item.name + ' Uploaded on ' + item.date_uploaded + ', Workspace ' + item.workspace_name "
                required
                prepend-icon="mdi-chart-box-outline"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
            <v-card-actions id="btn-group" class="pb-6" width="100%">
              <v-btn @click="deleteReport" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;"> Delete Report </v-btn>
            </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      ownerName: '',
      snackbar: false,
      loading: false,
      systemMessage: null,
      item: [],
      type: null,
      name: null,
      dateCreated: null,
      link: null,
      author: null,
      id: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/report/findByReportOwner', { userTypeId: userTypeId })
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/userType/find', { id: userTypeId })
        .then((response) => {
          this.ownerName = response.data[0].name
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    allReports () {
      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/report/findByReportOwner', { userTypeId: userTypeId })
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    deleteReport () {
      this.loading = true
      this.systemMessage = ''
      if (this.id === null) {
        this.loading = false
        this.snackbar = true
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please select a report to delete.' }
      } else {
        window.axios.post('/report/delete', { id: this.id })
          .then((response) => {
            // eslint-disable-next-line
            var affectedRows = response.data.response.affectedRows
            if (affectedRows === 1) {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully deleted the report.' }
              this.allReports()
              // reloading the page after deleting the report
              window.location.reload()
            } else {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to dellete the report.' }
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.loading = false
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
          })
      }
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
