<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 text-left" style=" font-size: 0.98em;">
              Report Access Requests
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for users"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"
              />
          </v-list-item-content>
          <v-list-item-content >
          <v-data-table
              class="px-4 elevation-0"
              :headers="headers"
              :items="items"
              :search="search"
              :sort-desc="true"
              style="cursor: pointer;"
            >
              <template v-slot:top>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <v-card tile>
                      <v-card-title class="subtitle-1">
                        <span style=" font-size: 0.98em;">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                readonly
                                filled
                                dense
                                v-model="editedItem.fullname"
                                label="User Account"
                                color="#339966"
                                disabled
                                prepend-icon="mdi-account-reactivate-outline"
                              ></v-text-field>
                              <v-text-field
                                readonly
                                filled
                                dense
                                v-model="editedItem.name"
                                label="Report Name"
                                color="#339966"
                                disabled
                                prepend-icon="mdi-chart-donut-variant"
                              ></v-text-field>
                              <v-autocomplete
                                v-model="type"
                                item-value="id"
                                :items="userStatusItems"
                                label="Change Access Status"
                                color="#006622"
                                filled
                                dense
                                :item-text="item=>item.name"
                                required
                                prepend-icon="mdi-arrow-decision"
                                @change="statusChanged"
                                return-object
                                />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#339966"
                          text
                          style=" font-size: 0.88em;"
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#339966"
                          text
                          style=" font-size: 0.88em;"
                          @click="save"
                        >
                          Submit
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card tile>
                    <v-card-text>
                      <h4 class="text-left pt-4">
                        <v-icon
                          class=""
                          color="#339966"
                          medium
                          @click="deleteItem(item)"
                        >
                          mdi-message-text
                        </v-icon>
                        Request Message
                      </h4>
                      <p class=" text-center"> {{ editedItem.message }}</p>
                    </v-card-text>
                    </v-card>
                  </v-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2"
                  color="#339966"
                  medium
                  @click="editItem(item)"
                >
                  mdi-state-machine
                </v-icon>
                <v-icon
                  class="mr-2"
                  color="#339966"
                  medium
                  @click="deleteItem(item)"
                >
                  mdi-message-text
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  dark
                  tile
                  class="text-none"
                  style="font-size: 0.88em;"
                  color="#339966"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      type: null,
      snackbar: false,
      selectedStatus: '',
      dialog: false,
      dialogDelete: false,
      search: null,
      items: [],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      userStatusItems: [
        { name: 'Accept Request' },
        { name: 'Deny Request' }
      ],
      headers: [
        { text: 'Requested Report', value: 'name' },
        { text: 'Firstame', value: 'firstname' },
        { text: 'Surname', value: 'surname' },
        { text: 'Email', value: 'email' },
        // { text: 'Phone', value: 'phone' },
        // { text: 'Message', value: 'message' },
        { text: 'Phone', value: 'phone' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: ''
      },
      defaultItem: {
        name: ''
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/accessRequest/findPending')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add User Group' : 'Accept / Deny Access Request'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    editItem (item) {
      const fullname = item.firstname + ' ' + item.surname
      item.fullname = fullname
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1 && this.selectedStatus !== null && this.selectedStatus !== '') {
        // edit the item on the server and in the browser
        const id = this.editedItem.id
        const userId = this.editedItem.user_id
        const reportId = this.editedItem.report_id
        const status = this.selectedStatus

        window.axios.post('/accessRequest/updateStatus', { id: id, userId: userId, reportId: reportId, status: status })
          .then((response) => {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully submitted the access request.' }
            this.reload()
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
          })
      } else {
        // add a new item to the group permission
      }
      this.close()
    },
    reload () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/accessRequest/findPending')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    statusChanged (item) {
      this.selectedStatus = item.name
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
