<template>
  <nav>
  <v-app-bar
    app
    dense
    color="#339966"
    dark>
    <v-app-bar-nav-icon @click.stop="drawer=!drawer">
    </v-app-bar-nav-icon>
      <v-spacer/>
       <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        clearable
        hide-details
        hide-selected
        hide-no-data
        item-text="name"
        item-value="symbol"
        label="Search for reports"
        filled
        outlined
        single-line
        flat
        solo-inverted
        prepend-inner-icon="mdi-archive-search-outline"
        append-icon="null"
        class="hidden-sm-and-down"
        color="#339966"
        return-object
        dense
        @change="loadReport"/>
      <v-spacer/>
      <v-badge
        class="mr-6"
        color="#f8b500"
        dot
        left
        :value="visibility">
        <v-icon
            @click="pageRoute('/userNotifications')"
            color="white lighten-1"
            size="28">
            mdi-bell-badge-outline
          </v-icon>
      </v-badge>
      <v-avatar
        class="mr-4 red darken-2 lighten-1"
        size="26">
        <v-icon @click="pageRoute('/userViewedReports', 1)" size="16" dark>mdi-shaker</v-icon>
      </v-avatar>
      <v-menu
        left
        bottom>
      <template v-slot:activator="{on, attrs}">
         <v-btn
          icon
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        </template>
        <v-card class="mx-auto" max-width="300" tile>
        <v-list dense shaped v-if="isAdminLoaded" >
          <v-subheader>Menu</v-subheader>
          <v-list-item-group
            color="#339966">
            <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              @click="itemClick(item.action)">
                <v-list-item-icon >
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style=" font-size: 0.90em;" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </v-card>
      </v-menu>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    class="white"
    fill-height
    :width="290"
    app>
    <v-app-bar
      flat
      dense
      color="#39ac73">
    <v-spacer/>
    <v-img
        class="pa-0 ma-0"
        alt="EGPAF logo"
        contain
        src="../assets/coat-of-arms.png"
        transition="scale-transition"
        height="36px"
        width="0px"
        style="border-radius: 50%;"/>
     <span class="white--text large font-weight-medium ">MOH</span>
     <v-spacer/>
    </v-app-bar>
     <v-list shaped dense>
        <v-subheader class="pl-5">Main Menu</v-subheader>
        <v-list-item-group
            color="#339966">
        <template v-for="item in links">
          <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center">
            <v-col cols="6">
              <v-subheader class="black-text" v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              cols="6"
              class="text-center">
              <a
                href="#!"
                class="body-2 black--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon="">
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="caption black--text font-weight-medium">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
              <v-icon small>mdi-checkbox-multiple-blank-circle</v-icon>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
               @click="pageRoute(child.route)">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="black--text" style=" font-size: 0.88em;">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else-if="item.text==='Register Users' && isAdmin"
            :key="item.text"
            :to="item.route"
            active-class="highlighted"
            @click.stop="pageRoute(item.route)">
          <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text--black font-weight-medium" style=" font-size: 0.88em;">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item
            v-else-if="item.text==='Activate Users' && isAdmin"
            :key="item.text"
            :to="item.route"
            active-class="highlighted"
            @click.stop="pageRoute(item.route)">
          <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text--black font-weight-medium" style=" font-size: 0.88em;">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="activationNotification == true">
              <v-icon small color="red">mdi-bell-ring</v-icon>
            </v-list-item-action>
          </v-list-item>
           <v-list-item
            v-else-if="item.text!=='Register Users' && item.text!=='Activate Users'"
            :key="item.text"
            :to="item.route"
            active-class="highlighted"
            @click.stop="pageRoute(item.route)">
          <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text--black font-weight-medium" style=" font-size: 0.88em;">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        </v-list-item-group>
      </v-list>
      <v-list shaped dense>
        <v-subheader class="pl-5">Extras</v-subheader>
           <v-list-item-group
             color="#339966">
            <v-list-item @click="itemClick (8) ">
              <v-list-item-icon>
                <v-icon>mdi-power-settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style=" font-size: 0.88em;">Sign Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
      </v-list>
      <v-list>
         <v-list-item style="position: absolute; bottom: 0;" class="mt-2 mb-2">
              <img
                  class="pr-1 mt-1"
                  alt="EGPAF logo"
                  contain
                  src="../assets/new-pepfar.png"
                  transition="scale-transition"
                  style="width: 24%;"/>
              <img
                  class="pl-2 pr-1 ma-0"
                  alt="EGPAF logo"
                  src="../assets/egpaf-logo.jpg"
                  transition="scale-transition"
                  height="45px"
                  width="70%"/>
          </v-list-item>
      </v-list>
  </v-navigation-drawer>
  <template>
  <v-row justify="center">
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-row>
</template>
<template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="searchDialog"
      persistent
      max-width="315">
      <v-card tile>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-2">
          Loading the {{reportName}} Report
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> loading please wait...</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<template>
    <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="tokenDialog"
      persistent
      max-width="300">
      <v-card tile>
        <v-card-title class="subtitle-2">
          Access Authorization
        </v-card-title>
         <v-card-text class="pb-2 pt-1"><v-icon color="red">mdi-door-closed</v-icon> Your access token has expired.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            text
            style="font-size: 0.88em;"
            color="green darken-1"
            @click="logout">
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  </nav>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      ownerName: '',
      activationNotification: false,
      reportName: '',
      tokenDialog: false,
      searchDialog: false,
      snackbar: false,
      dialog: false,
      isAdmin: false,
      isAdminLoaded: false,
      visibility: false,
      drawer: !isMobile,
      links: [
        { icon: 'mdi-home-import-outline', text: 'Home', route: '/userHome' },
        { icon: 'mdi-card-bulleted-settings-outline', text: 'My History', route: '/UserHistory' },
        { icon: 'mdi-remote-desktop', text: 'Most Viewed Reports', route: '/userViewedReports' },
        { icon: 'mdi-archive-eye-outline', text: 'See All Reports', route: '/userSeeReports' },
        { icon: 'mdi-shimmer', text: 'Latest Reports', route: '/userLatestReports' },
        { icon: 'mdi-file-cabinet', text: 'Data lake', route: '/dataLake' },
        { icon: 'mdi-account-multiple-plus-outline', text: 'Register Users', route: '/userAddUser' },
        { icon: 'mdi-seal', text: 'Activate Users', route: '/userActivateUser' },
        { icon: 'mdi-file-document-multiple-outline', text: 'Request Report Access', route: '/allAvailableReports' }
      ],
      menuItems: [
        // { text: 'Manage User Permissions', icon: 'mdi-cog-clockwise' },
        // { text: 'Data Lake', icon: 'mdi-file-cabinet' },
        { text: 'Edit Profile', icon: 'mdi-account-edit-outline', action: 0 },
        { text: 'Add a Report', icon: 'mdi-chart-box-plus-outline', action: 1 },
        { text: 'Edit a Report', icon: 'mdi-circle-edit-outline', action: 2 },
        { text: 'Delete a Report', icon: 'mdi-delete-empty-outline', action: 3 },
        { text: 'See My Group\'s Reports', icon: 'mdi-select-group', action: 4 },
        { text: 'Manage User Report Permissions', icon: 'mdi-archive-lock-outline', action: 5 },
        { text: 'Manage Group Permissions', icon: 'mdi-account-group-outline', action: 6 },
        { text: 'Report Updates', icon: 'mdi-chart-box-outline', action: 7 },
        { text: 'Sign Out', icon: 'mdi-power-settings', action: 8 }
      ],
      isLoading: false,
      items: [],
      model: null,
      search: null,
      snackbarError: { icon: 'mdi-server-security', label: 'Cannot connect to the server, network error!' }
    }
  },
  methods: {
    logout () {
      const id = localStorage.getItem('kwkk')
      window.axios.post('/signin/signout', { userId: id })
        .then(async (thisResponse) => {
          this.$router.push('/')
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.$router.push('/')
        })
    },
    adminRequest () {
      var id = localStorage.getItem('kwkk')
      window.axios.post('/adminRequest/insert', { userId: id })
        .then((response) => {
          // console.log(response.data.affectedRows)
          if (response.data.affectedRows === 0) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: response.data.message }
          } else {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully sent the admin status request !' }
          }
          this.dialog = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.dialog = false
          this.snackbarError = { icon: 'mdi-server-security', label: 'Cannot connect to the server, network error !' }
          this.snackbar = true
        })
    },
    itemClick (i) {
      switch (i) {
        // case 0:
        //   this.$router.push('/userPermissions')
        //   break
        // case 0:
        //   this.$router.push('/dataLake')
        //   break
        case 0:
          this.selectedItem = 1
          this.$router.push('/userEditProfile')
          break
        case 1:
          this.selectedItem = 1
          this.$router.push('/userAddReport')
          break
        case 2:
          this.selectedItem = 1
          this.$router.push('/userEditReport')
          break
        case 3:
          this.selectedItem = 1
          this.$router.push('/userDeleteReport')
          break
        case 4:
          this.selectedItem = 1
          this.$router.push('/userAdminSeeReport')
          break
        case 5:
          this.selectedItem = 1
          this.$router.push('/userAdminManageUserPermissions')
          break
        case 6:
          this.selectedItem = 1
          this.$router.push('/userManagePermissions')
          break
        case 7:
          this.selectedItem = 1
          this.$router.push('/userReportUpdates')
          break
        default:
          const id = localStorage.getItem('kwkk')
          window.axios.post('/signin/signout', { userId: id })
            .then(async (thisResponse) => {
              this.$router.push('/')
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
            })
      }
    },
    pageRoute (route) {
      // finding the position of the route
      for (var i = 0; i < this.links.length; i++) {
        if (route === this.links[i].route) {
          localStorage.setItem('sl', i)
          break
        }
      }
      this.$router.push(route).catch(failure => {
        window.location.reload()
      })
    },
    loadReport (item) {
      this.reportName = item.type
      var userId = localStorage.getItem('kwkk')
      var reportId = item.id
      window.axios.post('/viewership/insert', { userId: userId, reportId: reportId })
        .then((response) => {
          const viewershipId = response.data.insertId
          localStorage.setItem('viewershipId', viewershipId)
          if (item.type === 'Power Bi') {
            this.searchDialog = true
            const reportId = item.report_id
            const datasetId = item.dataset_id
            const embedUrl = item.embed_url
            window.axios.post('/viewership/getEmbedToken', { reportId: reportId, datasetId: datasetId })
              .then(async (response) => {
                const token = response.data.token
                this.searchDialog = false
                const passphrase = process.env.VUE_APP_PASSPHRASE
                const encryptedToken = this.CryptoJS.AES.encrypt(token, passphrase).toString()
                const encryptedReportId = this.CryptoJS.AES.encrypt(reportId, passphrase).toString()
                const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
                const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
                const encryptedDatasetId = this.CryptoJS.AES.encrypt(datasetId, passphrase).toString()
                localStorage.setItem('datasetId', encryptedDatasetId)
                localStorage.setItem('name', encryptedName)
                localStorage.setItem('tken', encryptedToken)
                localStorage.setItem('reportId', encryptedReportId)
                localStorage.setItem('embUrl', encryptedEmbedUrl)
                this.$router.push('userPowerBiVisual')
                window.location.reload()
              })
              // eslint-disable-next-line handle-callback-err
              .catch(() => {
                this.snackbar = true
                this.searchDialog = false
              })
          } else if (item.type === 'Web Embed' || item.type === 'Dash') {
            this.searchDialog = true
            this.dialog = false
            const embedUrl = item.embed_url
            const passphrase = process.env.VUE_APP_PASSPHRASE
            const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
            const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
            localStorage.setItem('name', encryptedName)
            localStorage.setItem('embUrl', encryptedEmbedUrl)
            this.$router.push('userSeeWebMap')
          } else {
            // this.$router.push('userJavaScriptVisual')
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  },
  mounted: function () {
    var passphrase = process.env.VUE_APP_PASSPHRASE
    var accessToken = localStorage.getItem('token')
    var decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
    window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

    var id = localStorage.getItem('kwkk')
    if (id === '' || id === null) {
      this.$router.push('/')
    }
    // finding the user admin
    window.axios.post('/userAdmin/findUserAdmin', { userId: id })
      .then((response) => {
        if (response.data.length === 1) {
          this.isAdmin = true
        } else {
          // remove the items from the the sub menu
          this.menuItems.splice(1, 7)
        }
        this.isAdminLoaded = true
      })
      // eslint-disable-next-line handle-callback-err
      .catch(() => {
      })
    window.axios.post('/user/find', { id: id })
      .then((response) => {
        if (response.data.length === 0) {
          this.$router.push('/')
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(() => {
      })

    window.axios.get('/user/findCount', {
      headers: {
        'Authorization': `Bearer ${decryptedAccessToken}`
      }
    })
      .then((response) => {
        // var count = response.data[0].count
        // if (count === -1) {
        //   const message = response.data[0].message
        //   if (message === 'jwt malformed') {
        //     this.$router.push('/')
        //   } else {
        //   }
        // }
      })
      // eslint-disable-next-line handle-callback-err
      .catch((err) => {
        if (err.response.data === 'empty jwt token') {
          this.$router.push('/')
        } else if (err.response.data === 'jwt malformed') {
          // show the dialog that the access token has expired
          this.tokenDialog = true
        }
      })
    window.axios.post('/seen/findAllUnseen', { userId: id })
      .then((response) => {
        if (response.data.length === 0) {
          // removing the visibility if there are no unseen messages
          this.visibility = false
        } else {
          this.visibility = true
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(() => {
      })
    const userTypeId = localStorage.getItem('utp')
    const userId = localStorage.getItem('kwkk')
    window.axios.post('/user/findByUserTypeDeactivated', { userTypeId: userTypeId, userId: userId })
      .then((response) => {
        const length = response.data.length
        if (length > 0) {
          this.activationNotification = true
        } else {
          this.activationNotification = false
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch((error) => {
      })
  },
  watch: {
    model (val) {
      if (val != null) this.tab = 0
      else this.tab = null
    },
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      this.isLoading = true
      const userGroupId = localStorage.getItem('utp')
      const userId = localStorage.getItem('kwkk')
      var url = '/report/searchByUserGroupId' + '?searchText=' + val + '&userTypeId=' + userGroupId + '&userId=' + userId
      window.axios.get(url)
        .then((response) => {
          this.items = response.data
          this.isLoading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
