<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 text-left" style=" font-size: 0.98em;">
              Report Updates
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search reports"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"/>
          </v-list-item-content>
          <v-list-item-content >
            <v-data-table
              flat
              :sort-by="['type', 'date_uploaded']"
              :sort-desc="[true, true]"
              :search="search"
              class="px-4 elevation-0"
              color="#339966"
              :headers="headers"
              :items="items"
              :items-per-page=15
              style="cursor: pointer;"
              return-object
              >
              <template v-if="tableDialog" v-slot:top>
                <v-toolbar
                  flat>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="tableDialog"
                    max-width="720px">
                    <v-card tile>
                      <v-card-title class="subtitle-1">
                        <span style="font-size: 0.88em;" >{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12">
                              <v-text-field
                                filled
                                dense
                                v-model="editedItem.name"
                                label="Selected Report"
                                color="#339966"
                                disabled
                                prepend-icon="mdi-chart-box-outline"
                              ></v-text-field>
                              <v-textarea
                                label="Report Updates"
                                color="#339966"
                                v-model="updates"
                                filled
                                dense
                                clearable
                                hint="Enter the Report's Updates............."
                                prepend-icon="mdi-message-text-outline"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#339966"
                          text
                          style="font-size: 0.88em;"
                          @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#339966"
                          text
                          style="font-size: 0.88em;"
                          @click="send">
                          Send
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2"
                  color="#339966"
                  medium
                  size="22"
                  @click="editItem(item)">
                  mdi-message-bulleted
                </v-icon>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
    <template>
        <v-row justify="center">
          <v-dialog
            transition="dialog-bottom-transition"
            v-model="dialog"
            persistent
            max-width="315">
            <v-card tile>
              <v-progress-linear
                indeterminate
                color="green"
                striped
                class="mb-0"
              ></v-progress-linear>
              <v-card-title class="subtitle-2">
                Loading the {{reportName}} Report
              </v-card-title>
              <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> processing please wait...</v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
    </template>
     <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      reportName: '',
      updates: '',
      dialog: false,
      tableDialog: false,
      snackbar: false,
      search: null,
      items: [],
      headers: [
        { text: 'Report Name', value: 'name' },
        { text: 'Workspace', value: 'workspace_name' },
        { text: 'Date Uploaded', value: 'date_uploaded' },
        { text: 'Status', value: 'status' },
        { text: 'Report Type', value: 'type' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: '',
        reportName: '',
        userGroupName: ''
      },
      defaultItem: {
        id: '',
        reportName: 0,
        userGroupName: ''
      },
      snackbarError: { icon: 'mdi-server-network-off', label: 'Network error, cannot load the report, please check your network.' }
    }
  },
  mounted: [
    async function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)

      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.get('/report/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? ' ' : 'Report Updates'
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    loadReport (item) {
      this.reportName = item.type
      if (item.type === 'Power Bi') {
        this.dialog = true
        const reportId = item.report_id
        const datasetId = item.dataset_id
        const embedUrl = item.embed_url
        window.axios.post('/viewership/getEmbedToken', { reportId: reportId, datasetId: datasetId })
          .then(async (response) => {
            // getting the encryption passphrase
            const token = response.data.token
            this.dialog = false
            const passphrase = process.env.VUE_APP_PASSPHRASE
            const encryptedToken = this.CryptoJS.AES.encrypt(token, passphrase).toString()
            const encryptedReportId = this.CryptoJS.AES.encrypt(reportId, passphrase).toString()
            const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
            const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
            const encryptedDatasetId = this.CryptoJS.AES.encrypt(datasetId, passphrase).toString()
            localStorage.setItem('datasetId', encryptedDatasetId)
            localStorage.setItem('name', encryptedName)
            localStorage.setItem('tken', encryptedToken)
            localStorage.setItem('reportId', encryptedReportId)
            localStorage.setItem('embUrl', encryptedEmbedUrl)
            this.$router.push('powerBiVisual')
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.dialog = false
          })
      } else if (item.type === 'Web Embed' || item.type === 'Dash') {
        this.dialog = true
        this.dialog = false
        const embedUrl = item.embed_url
        const passphrase = process.env.VUE_APP_PASSPHRASE
        const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
        const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
        localStorage.setItem('name', encryptedName)
        localStorage.setItem('embUrl', encryptedEmbedUrl)
        this.$router.push('seeWebMap')
      } else {
        // this.$router.push('javaScriptVisual')
      }
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.tableDialog = true
    },
    close () {
      this.tableDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    send () {
      if (this.editedIndex > -1) {
        // dismiss the updates dialog
        this.tableDialog = false
        // sending the updates to the users
        // console.log(`${this.updates}  ${this.editedItem.id}`)
        const reportId = this.editedItem.id
        const updatesMessage = this.updates
        // check if the message is not blank
        if (this.updates !== null && this.updates.trim() !== '') {
          window.axios.post('/reportUpdate/insert', { reportId: reportId, updates: updatesMessage })
            .then((response) => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully submitted your report updates....' }
            })
            .catch(() => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to submit the report updates.' }
            })
        } else {
          // dismiss the dialog
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'You cannot submit an empty updates message...' }
        }
      } else {
      }
      this.close()
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>

<style>
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  font-size: 0.96em;
  }
</style>
