<template>
<v-layout row wrap class="px-2 pb-2">
    <v-flex v-for="(item, i) in headerItems" :key="item.title" xs12 md4 pl-5 pr-5 pt-5>
      <v-card
        tile
        outlined
        id="header-size"
        @click="cardClick(item)"
        class="mx-auto elevation-1 mb-2"
        :ripple="{ class: 'white--text', center: true }">
      <v-list dense>
        <v-list-item-content>
          <v-icon :color="colors[i]" size="36"  class="pr-2">{{icons[i]}}</v-icon>
          <v-list-item-subtitle class="text-center text-size-title font-bold mt-2" >
            {{item.fullname}}
          </v-list-item-subtitle>
          <v-list-item class="pb-0 mb-0 dense">
            <v-divider class=""></v-divider>
          </v-list-item>
          <v-list-item-subtitle class="text-left pl-6 font-bold text-size mt-2">
            Reports Description
          </v-list-item-subtitle>
          <v-list-item class="pb-0 mb-0 mt-0 px-2 dense">
            <v-card-subtitle v-if="item.description == null || item.description.trim() == '' || item.description.trim().length == 0" style="font-size: 0.76rem;">The category description is not available.</v-card-subtitle>
            <v-card-subtitle v-else style="font-size: 0.76rem;">{{item.description}}</v-card-subtitle>
          </v-list-item>
          <v-list-item-content style="position: absolute; bottom: 16px; width:100%;">
            <v-list-item class="pb-0 mb-0 dense" >
              <v-divider class="" style="background: white;" ></v-divider>
            </v-list-item>
            <v-list-item class="pb-0 mb-0 dense" >
              <!-- <v-chip tile color="#339966" dark class="ml-4" style="font-size: 0.72rem; border-radius: 0%;" label>See the {{item.count}} Reports</v-chip> -->
              <v-btn tile small class="px-sm-4 text-none" color="#339966" dark style="font-size: 0.72rem; font-weight: normal;" > See the {{item.count}} Reports</v-btn>
            </v-list-item>
          </v-list-item-content>
        </v-list-item-content>
      </v-list>
      </v-card>
    </v-flex>
 </v-layout>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  props: {
    source: String
  },
  computed: {
    ...mapState([
      'partners'
    ])
  },
  data: () => {
    return {
      icons: ['mdi-abacus', 'mdi-google-analytics', 'mdi-chart-box-multiple-outline', 'mdi-finance', 'mdi-chart-gantt', 'mdi-chart-multiple', 'mdi-math-norm', 'mdi-chart-scatter-plot', 'mdi-poll', 'mdi-approximately-equal-box', 'mdi-sign-text', 'mdi-chart-box', 'mdi-chart-arc'],
      colors: ['#44a04c', '#e73827', '#44a04c', '#e73827', '#44a04c', '#c5796d', '#44a04c', '#e73827', '#44a04c', '#44a04c', '#e58494', '#44a04c', '#e73827', '#44a04c'],
      oneCard: false,
      twoCards: false,
      moreThanTwoCards: false,
      headerItems: []
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      // getting the reports that the user can view
      const userTypeId = localStorage.getItem('utp')
      const userId = localStorage.getItem('kwkk')
      window.axios.get('reportCategory/findReportCount', { params: { userTypeId: userTypeId, userId: userId } })
        .then((response) => {
          var dataSize = response.data.length
          this.headerItems = response.data
          if (dataSize === 1) {
            this.oneCard = true
          } else if (dataSize === 2) {
            this.twoCards = true
          } else {
            this.moreThanTwoCards = true
          }
          // use these categories to get their views and the number of HIS partners viewing them
        }).catch(() => {
          return 'unavailable'
        })
    }
  ],
  methods: {
    cardClick (item) {
      if (item.count !== 0) {
        localStorage.setItem('cData', item.name)
        var route = '/userCardReports'
        this.$router.push(route).catch(failure => {
          window.location.reload()
        })
      }
    },
    getReportDetails (name) {
    }
  }
}
</script>
<style scoped>
.text-size {
  font-size: 0.92em;
  font-weight: 420;
  color: #595959;
}

.text-size-title {
  font-size: 0.94em;
  font-weight: 430;
  color: #595959;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1600px) {
  #header-size{
    height: 500px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  #header-size{
    height: 500px;
  }
}
</style>
