<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 text-left" style=" font-size: 0.98em;">
              Manage HIS Partners
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for an HIS Partner"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"/>
          </v-list-item-content>
          <v-list-item-content >
          <v-data-table
              class="px-4 elevation-0"
              :headers="headers"
              :items="items"
              sort-by=""
              style="cursor: pointer;"
              :search="search">
              <template v-slot:top>
                <v-toolbar
                  flat>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        tile
                        dark
                        style="font-size: 0.88em;"
                        class="px-12 text-none"
                        v-bind="attrs"
                        v-on="on"
                        color="#339966">
                        Add an HIS Partner
                      </v-btn>
                    </template>
                    <v-card tile>
                      <v-card-title class="subtitle-1">
                        <span style="font-size: 0.88em;" >{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12">
                              <v-Autocomplete
                                v-model="editedItem.group_name"
                                label="User Group"
                                color="#339966"
                                dense
                                prepend-icon="mdi-account-group-outline"
                                :items="userGroupItems"
                                :item-text="item=>item.name"
                                return-object
                                filled
                                @change="selectedUserGroup"/>
                              <v-text-field
                                v-model="editedItem.name"
                                label="HIS Partner's Name"
                                color="#339966"
                                filled
                                dense
                                prepend-icon="mdi-charity"
                              ></v-text-field>
                              <v-text-field
                                filled
                                dense
                                v-model="editedItem.email_pattern"
                                label="Email Pattern"
                                color="#339966"
                                prepend-icon="mdi-lock-pattern"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#339966"
                          text
                          style="font-size: 0.88em;"
                          @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#339966"
                          text
                          style="font-size: 0.88em;"
                          @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card tile>
                    <v-card-text class="text-center pt-4 pb-2" style=" font-size: 0.98em;"><h4>Are you sure you want to delete this permission?</h4></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#339966" text @click="closeDelete" style=" font-size: 0.88em;">Cancel</v-btn>
                        <v-btn color="#339966" text @click="deleteItemConfirm" style=" font-size: 0.88em;">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2"
                  color="#339966"
                  medium
                  @click="editItem(item)">
                  mdi-fountain-pen
                </v-icon>
                <v-icon
                  color="#339966"
                  medium
                  @click="deleteItem(item)">
                  mdi-delete-forever
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  dark
                  tile
                  class="text-none"
                  color="#339966">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
      <v-snackbar
        id="snack"
        color="#339966"
        v-model="snackbar"
        right
        style="position: fixed; "
        class="mb-6 ma-19"
      >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white" > {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      selectedUserGroupId: null,
      snackbar: false,
      dialog: false,
      dialogDelete: false,
      search: null,
      items: [],
      userGroupItems: [],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      headers: [
        { text: 'HIS Partner', value: 'name' },
        { text: 'Stakeholder Group Name', value: 'group_name' },
        { text: 'Email pattern', value: 'email_pattern' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        email_pattern: '',
        group_name: ''
      },
      defaultItem: {
        name: '',
        email_pattern: '',
        group_name: ''
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/userType/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/userGroup/findAll')
        .then((response) => {
          this.userGroupItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add an HIS Partner' : 'Edit an HIS Partner'
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    selectedUserGroup (item) {
      this.selectedUserGroupId = item.id
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      // delete the permission here
      const id = this.editedItem.id
      window.axios.post('/userType/delete', { id: id })
        .then((response) => {
          this.reload()
          if (response.data.affectedRows === 1) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully deleted the HIS partner.' }
          } else {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to delete the HIS partner.' }
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to delete the HIS partner.' }
        })
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // edit the item on the server and in the browser
        if (this.selectedUserGroupId === null) {
          const selectedUserGroupName = this.editedItem.group_name
          for (var i = 0; i < this.userGroupItems.length; i++) {
            var userGroupName = this.userGroupItems[i].name
            if (userGroupName === selectedUserGroupName) {
              this.selectedUserGroupId = this.userGroupItems[i].id
            }
          }
        }
        const id = this.editedItem.id
        const name = this.editedItem.name
        const emailPattern = this.editedItem.email_pattern
        if (this.selectedUserGroupId === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: `Please select a user group.` }
        } else if (name === '' || name === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: `You cannot submit an empty HIS partner's name.` }
        } else {
          window.axios.post('/userType/update', { name: name, id: id, userGroupId: this.selectedUserGroupId, emailPattern: emailPattern })
            .then((response) => {
              this.reload()
              if (response.data.affectedRows === 1) {
                this.snackbar = true
                this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the HIS partner.' }
              } else {
                this.snackbar = true
                this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to edit the HIS partner.' }
              }
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
            })
        }
      } else {
        const name = this.editedItem.name
        const emailPattern = this.editedItem.email_pattern
        if (this.selectedUserGroupId == null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: `Please select the HIS partner's user group.` }
        } else if (name === null || name === '') {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: `Please enter the stakeholder group's name.` }
        } else {
          window.axios.post('/userType/insert', { name: name, userGroupId: this.selectedUserGroupId, emailPattern: emailPattern })
            .then((response) => {
              this.reload()
              this.selectedUserGroupId = null
              if (response.data.affectedRows === 1) {
                this.editItem = ''
                this.snackbar = true
                this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully added the HIS partner.' }
              } else {
                this.snackbar = true
                this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to add the HIS partner.' }
              }
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
            })
        }
      }
      this.close()
    },
    reload () {
      window.axios.get('/userType/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
