<template>
  <v-container
    id="abstract"
    fluid
    container
    class="py-0 fill-height">
    <v-row
      align="center"
      justify="center">
        <v-col
          cols="12"
          xs="8"
          sm="8"
          md="5"
          lg="4"
          xl="3">
          <!-- src="../assets/round-logo.png" -->
            <v-card tile flat class="elevation-4 mx-sm-1 mx-md-8">
                <v-flex class="ml-2 mr-2 ">
                <v-progress-linear
                  color="#009933"
                  height="4.5"
                  indeterminate
                  :active="loading"/>
                </v-flex>
                <v-img
                  class="pt-12 mt-4"
                  id="logo"
                  alt="EGPAF Logo"
                  contain
                  src="../assets/coat-of-arms.png"
                  transition="scale-transition"
                  width="26%"
                  height="60%"/>
            <v-card-title class="layout justify-center pt-0 mt-0" >
              <h1 v-bind="size" class="font-weight-bold" style="color:#339966; font-size:0.86em;">MALAWI ANALYTICS PLATFORM</h1>
              </v-card-title>
              <v-card-text>
              <v-form>
                <v-text-field
                  dense
                  class="pt-4 px-4 mx-2"
                  v-model="username"
                  color="#006622"
                  filled
                  label="Email"
                  placeholder="Enter email"
                  name="Email"
                  prepend-inner-icon="mdi-account-box-outline"
                  type="text"
                  @keyup.enter='signin'
                ></v-text-field>
                <v-text-field
                  class="px-4 mx-2"
                  v-model="password"
                  color="#006622"
                  filled
                  id="password"
                  label="Password"
                  placeholder="Enter password"
                  name="password"
                  prepend-inner-icon="mdi-form-textbox-password"
                  :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  @keyup.enter='signin'
                ></v-text-field>
                  <p class="text-center"><a href="#" target="" @click="forgot" style="color: #339966; font-size: 0.94em;">Forgot your password?</a></p>
                <v-flex>
                  <v-row id="btn-group">
                  <v-card-actions  class="pb-6" width="100%">
                    <v-flex>
                      <v-btn @click="register" tile class="px-sm-8 text-none mr-4 mt-2" color="#339966" dark style="font-size: 0.88em;" > Register </v-btn>
                      <v-btn @click="signin" tile class="px-sm-8 text-none mt-2" color="#339966" dark style="font-size: 0.88em;" > Sign In </v-btn>
                    </v-flex>
                  </v-card-actions>
                  </v-row>
                </v-flex>
              </v-form>
            </v-card-text>
            <p class="text-center pb-6"><a href="#" target="" @click="termsDialog()" style="color: #339966; font-size: 0.99m;">Terms of service</a></p>
              </v-card>
        </v-col>
    </v-row>
       <v-snackbar
          id="snackbar"
          color="#339966"
          v-model="snackbar"
          class="mb-6">
          <v-layout class="pa-2">
            <span name="app" dark style="font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
            <v-spacer/>
          </v-layout>
        </v-snackbar>
        <template>
          <v-dialog
            transition="dialog-bottom-transition"
            v-model="dialog"
            persistent
            scrollable
            max-width="780"
          >
            <v-card tile>
              <v-card-title class="subtitle-1 grey lighten-5">
                <v-icon color="red" class="mr-1">mdi-scale-balance</v-icon>
                Agreement
              </v-card-title>
              <v-card-text class="pb-4 pt-4">You are accessing a Ministry of Health Malawi information system dabbed Malawi Analytics Platform (MAP), which includes (i) this information system; (ii) this information system’s data; (iii) all information systems connected to this platform; and (iv) all analytics and data sources attached to this information system. This information system is provided for the MOH Malawi-authorized use only.</v-card-text>
              <v-card-text class="pb-4">Unauthorized or improper use of this system may result in disciplinary action, as well as civil and criminal penalties.</v-card-text>
              <v-card-text class="pb-4">By using this information system, you understand and consent to the following:</v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="gray" small>
                      mdi-record
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap">You have no reasonable expectation of privacy regarding any communications or data transiting or stored on this information system. At any time, and for any lawful government purpose, the government may monitor, intercept, and search and seize any communication or data transiting or stored on this information system.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="gray" small>
                      mdi-record
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap">Any communications or data transiting or stored on this information system may be disclosed or used for any lawful government purpose.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="gray" small>
                      mdi-record
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap">Nothing herein consents to the search or seizure of a privately owned information system or other privately owned communications devices, or the contents thereof that is in the system user’s home</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap font-weight-medium" style="font-size: 0.99em;">USE OF THIS COMPUTER SYSTEM OR NETWORK BY ANY USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES EXPRESS CONSENT TO THIS MONITORING. IF YOU DO NOT CONSENT TO THIS MONITORING, OR IF YOU ARE NOT AN AUTHORIZED USER, YOU SHOULD EXIT THIS SYSTEM. IF YOU ARE AN AUTHORIZED USER AND CONSENT, SELECT "I AGREE" TO THE SYSTEM TERMS AND USAGE TO INDICATE YOU AGREE TO ALL THE CONDITIONS STATED HEREIN.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-card-actions class=" grey lighten-5">
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  text
                  small
                  color="green darken-1"
                  @click="closeInitDialog"
                  class="mb-2"
                >
                  I Agree
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </template>
      <template>
        <v-dialog
          v-model="thisTermsDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              dense
              color="#39ac73"
            >
            <v-btn
              icon
              dark
              @click="thisTermsDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span class="white--text large font-weight-bold">Terms of Service</span>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="thisTermsDialog = false"
                small
              >
                <span class="white--text large font-weight-bold text-none">Close</span>
              </v-btn>
            </v-toolbar-items>
            </v-toolbar>
              <v-list
                three-line
                subheader
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap pb-4 pt-4"><b>Analytics Runtime:</b> Please note that analytics is currently running at approximately <b>24 hour intervals</b>. The analytics refresh process is what allows newly-entered or deduplicated data to be viewed in the dashboards.
                    User accounts are automatically disabled if they are not used for a period of 6 months. To prevent your account from being deactivated, please log in frequently or at least every <b>six months</b>.
                    By logging in and accessing this system, you agree to abide by the terms of service.
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-left pb-6 pt-6">Malawi Analytic Platform (MAP) Terms of Service</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">By accessing MAP, I understand and agree to the provisions of the Data Use Policy and  User Access Policy governing the use and distribution of MAP visualizations/dashboards and data, including statements below:</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">1. Most information contained in the MAP system is considered Sensitive but Unclassified (SBU) and I will treat it as such. SBU is generally exempt from public disclosure, and depending on the type of information, dissemination may be restricted even within the federal government. This may include preliminary and planning information, business-confidential information protected by the Trade Secrets Act, or information protected by the Privacy Act of 1974.</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">2. Sharing MAP login information is strictly prohibited. I agree to safeguard my username and password at all times. If I believe my login has been compromised, I will change my password immediately and contact MAP administrator at <a>MalawiInformaticsTeam@pedaids.org</a></v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">3. I am aware that I must avoid inadvertent transmission of MAP information prohibited on the Internet, including use of "auto-forward" function to send emails to an address outside of the MoH  network</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">4. I will take extra time to verify data contained in MAP reports before using or distributing such reports even internally. Rather than editing and existing report for a purpose other than originally intended, I will encourage obtaining the information directly from the system to avoid misrepresenting or misinterpretation of system information</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">5. I understand that unauthorized disclosures of such information could result in administrative disciplinary action, including loss of access right.</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap pb-4">6. I will promptly refer any questions or concerns regarding compliance to the appropriate agency procurement ethics, or legal official(s). When in doubt, I will contact MAP Support at <a>MalawiInformaticsTeam@pedaids.org</a>.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-dialog>
      </template>
  </v-container>
</template>

<script>
export default {
  computed: {
    size () {
      const size = { xs: 'x-small', sm: 'small', lg: 'large', xl: 'x-large' }[this.$vuetify.breakpoint.name]
      return size ? { [size]: true } : {}
    }
  },
  data: () => {
    return {
      dialog: true,
      thisTermsDialog: false,
      snack: true,
      loading: false,
      username: null,
      password: null,
      snackbar: false,
      show: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('Email and passwor do not match')
      },
      emailError: { icon: 'mdi-server-security', label: 'You have submitted an invalid email!' },
      usernameError: { icon: 'mdi-server-security', label: 'You cannot sign in with an empty email!' },
      passwordError: { icon: 'mdi-server-security', label: 'You cannot sign in with an empty password!' },
      usernamePasswordError: { icon: 'mdi-server-security', label: 'You cannot sign in with an empty email and password!' },
      snackbarError: { icon: 'mdi-server-security', label: 'Failed to sign in, please verify your username & password!' },
      userError: { icon: 'mdi-server-security', label: 'Failed to sign in, please verify your username & password!' },
      networkError: { icon: 'mdi-server-network-off', label: 'You cannot connect to the server.' },
      invalidCredentials: { icon: 'mdi-server-network-off', label: 'Invalid username or password.' }
    }
  },
  methods: {
    closeInitDialog () {
      this.dialog = false
    },
    termsDialog () {
      this.thisTermsDialog = true
    },
    signin () {
      this.loading = true
      if (this.username == null || this.password == null || this.username === '' || this.password === '' || this.username.length === 0 || this.username.length < 4 || this.password.length === 0) {
        if ((this.username == null || this.username.trim() === '') && (this.password == null || this.password.trim() === '')) {
          this.snackbarError = this.usernamePasswordError
          this.snackbar = true
          this.loading = false
        } else if (this.username == null || this.username.trim() === '') {
          this.snackbarError = this.usernameError
          this.snackbar = true
          this.loading = false
        } else if (this.password == null || this.password.trim() === '') {
          this.snackbarError = this.passwordError
          this.snackbar = true
          this.loading = false
        } else {
          this.snackbarError = this.invalidCredentials
          this.snackbar = true
          this.loading = false
        }
        this.loading = false
      } else {
        window.axios.post('/admin/login', { username: this.username, password: this.password })
          .then((response) => {
            // eslint-disable-next-line
            if (response.data.length === 0) {
              // login
              window.axios.post('/user/login', { username: this.username, password: this.password })
                .then((response) => {
                  this.loading = false
                  if (response.data.length === 0) {
                    this.snackbarError = this.userError
                    this.snackbar = true
                  } else {
                    // valid non user log into the user part of the system
                    const id = response.data[0].id
                    const userTypeId = response.data[0].user_type_id
                    const status = response.data[0].status
                    const token = response.data[1]

                    if (status === 'Blocked') {
                      this.snackbarError = { icon: 'mdi-server-security', label: 'You cannot login, your account has been blocked' }
                      this.snackbar = true
                    } else if (status === 'Deactivated') {
                      this.snackbarError = { icon: 'mdi-server-security', label: 'You cannot login, your account has been deactivated' }
                      this.snackbar = true
                    } else {
                      window.axios.defaults.headers.common.Authorization = `Bearer ${token}`
                      // this.loading = false
                      const passphrase = process.env.VUE_APP_PASSPHRASE
                      // encrypting the data now
                      const encryptedText = this.CryptoJS.AES.encrypt(token, passphrase).toString()
                      localStorage.setItem('kwkk', id)
                      localStorage.setItem('utp', userTypeId)
                      localStorage.setItem('token', encryptedText)

                      window.axios.post('/signin/signin', { userId: id })
                        .then(async (thisResponse) => {
                          this.loading = false
                          this.$router.push('/userHome')
                        })
                        // eslint-disable-next-line handle-callback-err
                        .catch(() => {
                        })
                    }
                  }
                })
                // eslint-disable-next-line handle-callback-err
                .catch(() => {
                  // console.log(error)
                  this.loading = false
                  this.snackbarError = this.networkError
                  this.snackbar = true
                })
            } else {
              // go to the admin login
              const id = response.data[0].id
              const token = response.data[1]

              window.axios.defaults.headers.common.Authorization = `Bearer ${token}`
              // getting the encryption passphrase
              this.loading = false
              const passphrase = process.env.VUE_APP_PASSPHRASE
              // encrypting the data now
              const encryptedText = this.CryptoJS.AES.encrypt(token, passphrase).toString()
              localStorage.setItem('kwkk', id)
              localStorage.setItem('token', encryptedText)
              this.$router.push('/home')
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            // console.log(error)
            this.loading = false
            this.snackbarError = this.networkError
            this.snackbar = true
          })
      }
    },
    register () {
      this.$router.push('/registerUser')
    },
    forgot () {
      this.$router.push('/forgotPassword')
    }
  },
  mounted: [
    function () {
      localStorage.clear()
    }
  ]
}
</script>

<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #abstract{
    background-size: cover;
    background: url("../assets/abstract.svg");
    background-repeat: no-repeat;
    width: 100%;
  }
  #logo{
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #btn-group{
    justify-content: center;
  }
  #forgot-text{
    text-decoration: underline;
  }
  a:link, a:visited {
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
  a:hover, a:active {
    color: #339966;
  }
}
#abstract{
  background-size: cover;
  background: url("../assets/abstract.svg");
  background-repeat: no-repeat;
  width: 100%;
}
#logo{
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#btn-group{
  justify-content: center;
}
#forgot-text{
  text-decoration: underline;
}
a:link, a:visited {
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
a:hover, a:active {
  color: #339966;
}
</style>
