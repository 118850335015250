<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4" style=" font-size: 0.98em;">
              Data Lake
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content class="mb-4" >
            <DataLakeDownloads/>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
       <template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
      max-width="315">
      <v-card>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-2">
          Loading the {{reportName}} Report
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> processing please wait...</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  </template>
   <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
import DataLakeDownloads from '@/components/DataLakeDownloads'

export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      reportName: '',
      snackbar: false,
      dialog: false,
      search: null,
      items: [],
      headers: [ { text: 'Report Name', value: 'name' },
        { text: 'Date Uploaded', value: 'date_uploaded' },
        { text: 'Report Type', value: 'type' }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'Network error, cannot load the report, please check your network.' }
    }
  },
  components: {
    DataLakeDownloads
  },
  mounted: [
    function () {
      var userTypeId = localStorage.getItem('utp')
      var userId = localStorage.getItem('kwkk')
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.post('/report/findByUserGroup', { userTypeId: userTypeId, userId: userId })
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
