<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2 text-left" style=" font-size: 0.98em;">
              Add {{ownerName}} Report
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-4 ma-6">
          <v-list dense  class="ma-0 pa-0">
              <v-autocomplete
                v-model="type"
                item-value="id"
                :items="item"
                label="Report Type"
                color="#006622"
                filled
                :item-text="item=>item.name"
                required
                dense
                prepend-icon="mdi-card-bulleted-settings-outline"
                @change="selectedReportType(type)"
              />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="name"
                    color="#006622"
                    filled
                    dense
                    label="Report Name"
                    name="Report Name"
                    prepend-icon="mdi-card-bulleted-settings"
                    type="text"
              />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="reportId"
                    color="#006622"
                    filled
                    dense
                    label="Report Id"
                    name="ReportID"
                    prepend-icon="mdi-link"
                    type="text"
                    :disabled="leafletDisable"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
            <v-text-field
                  v-model="datasetId"
                  color="#006622"
                  filled
                  dense
                  label="Dataset Id"
                  name="Dataset Id"
                  prepend-icon="mdi-openid"
                  type="text"
                  :disabled="leafletDisable"
            />
          </v-list>
          <v-list dense class="ma-0 pa-0">
              <v-text-field
                    v-model="embedUrl"
                    color="#006622"
                    filled
                    dense
                    label="Embed URL / Docker URL"
                    name="Embed URL"
                    prepend-icon="mdi-web"
              />
              <v-card-actions id="btn-group" class="pb-6" width="100%">
                <v-btn @click="addReport" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;" > Add a Report </v-btn>
              </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
    <template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
      max-width="300"
    >
      <v-card tile>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-2">
          Adding Power Bi API Reports
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> processing please wait...</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  </template>
  <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      ownerName: '',
      leafletDisable: false,
      dialog: false,
      snackbar: false,
      loading: false,
      systemMessage: null,
      item: [],
      type: null,
      name: null,
      reportId: null,
      datasetId: null,
      embedUrl: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/reportType/findAll')
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/userType/find', { id: userTypeId })
        .then((response) => {
          this.ownerName = response.data[0].name
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    selectedReportType () {
      var name = ''
      for (var i = 0; i < this.item.length; i++) {
        if (this.type === this.item[i].id) {
          name = this.item[i].name
        }
      }
      if (name === 'Web Embed' || name === 'Dash') {
        this.leafletDisable = true
      } else {
        this.leafletDisable = false
      }
    },
    reset () {
      this.type = null
      this.name = ''
      this.reportId = ''
      this.datasetId = ''
      this.embedUrl = ''
    },
    addReport () {
      var name = ''
      for (var i = 0; i < this.item.length; i++) {
        if (this.type === this.item[i].id) {
          name = this.item[i].name
        }
      }
      if (name === 'Web Embed' && this.name !== null && this.name !== '' && this.embedUrl !== null && this.embedUrl !== '') {
        // inserting the leaflet web map
        this.loading = true
        this.systemMessage = ''
        var userTypeId = localStorage.getItem('utp')
        window.axios.post('/report/insert', { name: this.name, type: this.type, reportId: null, datasetId: null, embedUrl: this.embedUrl, userTypeId: userTypeId })
          .then((response) => {
            // eslint-disable-next-line
            this.name = null
            this.embedUrl = null
            var affectedRows = response.data.affectedRows
            if (affectedRows === 1) {
              this.systemMessage = 'Successfully added the report'
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully added the report' }
            } else {
              this.systemMessage = response.data.message
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: response.data.message }
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
            this.snackbar = true
          })
      } else if (this.type === '' || this.type === null || this.reportId === '' || this.reportId === null || this.name === null || this.name === '' || this.embedUrl === null | this.embedUrl === '') {
        if (this.name === '' || this.name === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the Report Name' }
        } else if (this.reportId === '' || this.reportId === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the report Id' }
        } else if (this.type === null || this.type === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the report type' }
        } else if (this.datasetId === '' || this.datasetId === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the Dataset Id' }
        } else if (this.embedUrl === null | this.embedUrl === '') {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the report Embed URL' }
        }
      } else {
        this.loading = true
        this.systemMessage = ''
        window.axios.post('/report/insert', { name: this.name, type: this.type, reportId: this.reportId, datasetId: this.datasetId, embedUrl: this.embedUrl })
          .then((response) => {
            this.name = null
            this.reportId = null
            this.datasetId = null
            this.embedUrl = null
            // eslint-disable-next-line
            var affectedRows = response.data.affectedRows
            if (affectedRows === 1) {
              this.systemMessage = 'Successfully added the report'
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully added the report' }
            } else {
              this.systemMessage = response.data.message
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: response.data.message }
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch((error) => {
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
            this.snackbar = true
          })
      }
    },
    addPowerBiAPIReport () {
      this.dialog = true
      window.axios.get('/viewership/getReports')
        .then((response) => {
          // eslint-disable-next-line
          window.location.reload()
          this.dialog = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
          this.dialog = false
          this.systemMessage = 'Sorry an error occurred, please try again. '
          this.loading = false
          this.snackbar = true
        })
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
    font-size: 0.84em;
}
.v-text-field >>> label {
    font-size: 0.84em;
}
</style>
