<template>
  <v-app id="inspire" class="grey lighten-3">
   <UserNavbar/>
   <v-main>
     <v-flex>
        <v-container
        fluid
      >
        <v-layout px-5  row class="mt-1">
          <v-flex xs10 dense>
            <div class="subtitle" style=" font-size: 0.98em;">Dashboard</div>
          </v-flex>
          <v-flex class="text-right" xs2 >
            <v-spacer/>
             <v-breadcrumbs class="pa-0" :items="crumbItems" id="crumbs">
              <template v-slot:item="{item}">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" @click="breadCrumbClick(item)" style=" font-size: 0.98em;">
                  {{item.text}}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>
        <UserHeader/>
        <v-layout row>
          <v-flex xs12 pa-5>
            <v-card
              tile
              class="mx-auto"
            >
            <v-flex>
            <v-progress-linear
              id="progress"
              color="#009933"
              height="4.5"
              indeterminate
              :active="loading"
            />
            </v-flex>
              <v-list>
                <v-list-item-content>
                  <v-list-item-subtitle :v-model="name" class="pl-4 pb-4"  style=" font-size: 0.96em;">
                    <span>{{name}}</span>
                    <v-icon
                      @click="fullscreen"
                      style="float: right;"
                      color="green"
                      class=" pr-7" >
                        mdi-presentation
                    </v-icon>
                    <v-icon
                      @click="() => {recommendationDialog = true}"
                      style="float: right;"
                      color="green"
                      class=" pr-4" >
                        mdi-share-all-outline
                    </v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="ml-6 mr-6"  >
                 <div v-if="!mobileCheck" id="embedContainer" style="height:90vh; border: 0px;" ></div>
                 <div v-if="mobileCheck" id="embedContainer" style=" height: 40vh; border: 0px;" ></div>
                 <div style="text-align: center;" class="mt-2">
                    <v-btn
                      @click="() => {feedbackDialog=!feedbackDialog} "
                      color="#339966"
                      class="text-none pa-4 mt-2 mb-4 mr-2"
                      dark
                      tile
                      style="font-size: 0.88em;">
                      Submit Feedback
                    </v-btn>
                    <v-btn
                      @click="() => {recommendationDialog = true}"
                      color="#339966"
                      class="text-none pa-4 mt-2 mb-4 mr-2"
                      dark
                      tile
                      style="font-size: 0.88em;">
                      Recommend to Others
                    </v-btn>
                    <v-btn
                      @click="() => {ratingDialog=!ratingDialog} "
                      color="#339966"
                      class="text-none pa-4 mt-2 mb-4"
                      dark
                      tile
                      style="font-size: 0.88em;">
                      Rate the Visual
                    </v-btn>
                    <v-btn
                      color="#339966"
                      class="text-none pa-4 mt-2 mb-4 ml-2"
                      dark
                      tile
                      style="font-size: 0.88em;"
                      @click="fullscreen">
                      Enter fullscreen mode
                    </v-btn>
                </div>
                </v-list-item-content>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <!-- the feedback pop up dialog -->
      <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="feedbackDialog"
          max-width="520"
        >
          <v-card tile>
            <v-progress-linear
              id="progress"
              color="green darken-3"
              height="4.5"
              indeterminate
              :active="feedbackLoader"
              striped
            />
            <v-card-title class="subtitle-2">
              Provide Feedback
            </v-card-title>
            <v-card-actions class="mt-2">
              <v-textarea
                dense
                outlined
                class="px-2"
                v-model="feedback"
                color="green darken-2"
                label="Feedback"
                hint="Rate the Report's Appearance, Disaggregation, Usefulness, & Make any Suggestions"
                name="Feedback"
                type="text"
                clearable
                @keyup.enter='signin'
              />
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                style="font-size: 0.90em;"
                color="green darken-1 text-none"
                @click="submitFeedback"
              >
                Submit
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
     <!-- the rating pop up dialog -->
     <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="ratingDialog"
          max-width="520"
        >
          <v-card tile>
            <v-progress-linear
              id="progress"
              color="green darken-3"
              height="4.5"
              indeterminate
              :active="feedbackLoader"
              striped
            />
            <v-card-title class="subtitle-2">
              Rate The Visual
            </v-card-title>
            <div class="text-center mb-2">
              <v-rating
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                hover
                size="26"
              ></v-rating>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                style="font-size: 0.90em;"
                color="green darken-1 text-none "
                @click="submitRating"
              >
                Submit
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
     <!-- the recommendation pop up dialog -->
     <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="recommendationDialog"
          max-width="720"
        >
          <v-card tile>
            <v-progress-linear
              id="progress"
              color="green darken-3"
              height="4.5"
              indeterminate
              :active="feedbackLoader"
              striped
            />
            <v-card-title class="subtitle-2">
              Recommend Visual
            </v-card-title>
            <v-card-actions class="mt-2">
              <v-col
                cols="12"
                sm="12"
                md="12">
              <v-autocomplete
                v-model="userIds"
                item-value="id"
                :items="items"
                label="Recommend To"
                color="#006622"
                filled
                :item-text="item=>item.firstname + ' ' + item.surname + ' - ' + item.group_name"
                required
                dense
                multiple
                prepend-icon="mdi-account-group-outline"
              />
              <v-textarea
                dense
                outlined
                class=""
                v-model="message"
                color="green darken-2"
                label="Message"
                hint="The Reason for making this recommendation."
                name="Recommendation"
                type="text"
                clearable
                @keyup.enter='signin'
                prepend-icon="mdi-message-text-outline"
              />
              </v-col>
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                style="font-size: 0.90em;"
                color="green darken-1 text-none"
                @click="submitRecommendation()"
              >
                Submit
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
      <v-snackbar
        id="snack"
        color="#339966"
        v-model="snackbar"
        center
        style="position: fixed; "
        class="mb-6 ma-19"
      >
        <v-layout class="pa-2">
          <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
          <v-spacer/>
        </v-layout>
      </v-snackbar>
      <v-snackbar
        id="snack"
        color="#339966"
        timeout="-1"
        v-model="ratingSnackbar"
        right
        closable
        style="position: fixed; "
        class="mb-6 ma-19"
      >
        <span
          style=" font-size: 0.82em;"
        >
          Would You Like to Provide Feedback for this Analytics Visual?
        </span>
        <v-layout  class="mt-2">
          <v-spacer/>
          <v-chip
            style=" font-size: 0.82em;"
            class="mr-2"
            @click="ratingSnackbar=!ratingSnackbar"
          >
            <v-icon
              class="mr-1"
            >
              mdi-star-remove
            </v-icon>
              No Thanks
          </v-chip>
          <v-chip
            style=" font-size: 0.82em;"
            class=" ml-2"
            @click="ratingSnackbar=!ratingSnackbar; feedbackDialog=!feedbackDialog;"
          >
            <v-icon
              class="mr-1"
            >
              mdi-star-shooting
            </v-icon>
            Yes Please
          </v-chip>
          <v-spacer/>
        </v-layout>
      </v-snackbar>
     </v-flex>
   </v-main>
  </v-app>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import UserHeader from '@/components/UserHeader'
import * as pbi from 'powerbi-client'
import { isMobile } from 'mobile-device-detect'
export default {
  data: () => {
    return {
      fullscreenPattern: [],
      feedback: '',
      rating: 0,
      items: [],
      userIds: null,
      message: '',
      feedbackDialog: false,
      ratingDialog: false,
      recommendationDialog: false,
      reportId: '',
      datasetId: '',
      mobileCheck: isMobile,
      loading: true,
      snackbar: false,
      ratingSnackbar: false,
      feedbackLoader: false,
      hasFeedback: false,
      name: '',
      crumbItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '#'
        }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  components: {
    UserNavbar,
    UserHeader
  },
  methods: {
    pageChange () {
      var viewershipId = localStorage.getItem('viewershipId')
      window.axios.post('/viewership/updatePageChange', { id: viewershipId })
        .then(() => {
        })
    },
    visualClick () {
      var viewershipId = localStorage.getItem('viewershipId')
      window.axios.post('/viewership/updateVisualClick', { id: viewershipId })
        .then(() => {
        })
    },
    submitRecommendation () {
      if (this.message !== '' && this.message !== null) {
        this.recommendationLoader = true
        var userId = localStorage.getItem('kwkk')
        window.axios.post('/suggestion/insert', {
          reportId: this.reportId,
          suggestedBy: userId,
          usersSuggested: this.userIds,
          message: this.message
        })
          .then((response) => {
            // getting the response from the server
            this.recommendationDialog = false
            this.recommendationLoader = false
            this.snackbarError = { icon: 'mdi-server-network', label: 'Your recommendation has been submitted.' }
            this.snackbar = true
          })
          .catch(() => {
            // dismiss with an error
            this.recommendationDialog = false
            this.recommendationLoader = false
            this.snackbarError = { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
            this.snackbar = true
          })
      } else {
        // dismiss with an error
        this.recommendationDialog = false
        this.recommendationLoader = false
        this.snackbarError = { icon: 'mdi-server-network-off', label: 'You cannot submit an empty feedback...' }
        this.snackbar = true
      }
    },
    feedbackCheck (reportId, userId) {
      window.axios.get('/feedback/exists', { params: { reportId: reportId, userId: userId } })
        .then((response) => {
          if (response.data.length !== 0) {
            this.hasFeedback = true
          }
        })
        .catch(() => {
        }
        )
    },
    submitFeedback () {
      if (this.feedback !== '' && this.feedback !== null) {
        this.feedbackLoader = true
        var userId = localStorage.getItem('kwkk')
        window.axios.post('/feedback/insert', { reportIdOrUrl: this.reportId, userId: userId, feedback: this.feedback })
          .then((response) => {
            // getting the response from the server
            this.feedbackDialog = false
            this.feedbackLoader = false
            this.snackbarError = { icon: 'mdi-server-network', label: 'Your feedback has been submitted.' }
            this.snackbar = true
          })
          .catch(() => {
            // dismiss with an error
            this.feedbackDialog = false
            this.feedbackLoader = false
            this.snackbarError = { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
            this.snackbar = true
          })
      } else {
        // dismiss with an error
        this.feedbackDialog = false
        this.feedbackLoader = false
        this.snackbarError = { icon: 'mdi-server-network-off', label: 'You cannot submit an empty feedback...' }
        this.snackbar = true
      }
    },
    submitRating () {
      this.feedbackLoader = true
      var userId = localStorage.getItem('kwkk')
      window.axios.post('/rating/insert', { reportIdOrUrl: this.reportId, userId: userId, star: this.rating })
        .then((response) => {
          // getting the response from the server
          this.ratingDialog = false
          this.feedbackLoader = false
          this.snackbarError = { icon: 'mdi-server-network', label: 'Your feedback has been submitted.' }
          this.snackbar = true
        })
        .catch(() => {
          // dismiss with an error
          this.dialog = false
          this.feedbackLoader = false
          this.snackbarError = { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
          this.snackbar = true
        })
    },
    fullscreen () {
      // check if fullscreen mode is available
      if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled) {
        // which element will be fullscreen
        var iframe = document.querySelector('#embedContainer iframe')
        // Do fullscreen
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen()
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen()
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen()
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen()
        }
      } else {
        document.querySelector('.error').innerHTML = 'Your browser is not supported'
      }
    },
    breadCrumbClick (item) {
      this.$router.push(item.route).catch(failure => {
        window.location.reload()
      })
    }
  },
  mounted: [
    function () {
      window.axios.get('/user/findAll')
        .then((response) => {
          this.items = response.data
        })
        .catch(() => {})
    },
    function () {
      setTimeout(() => {
        if (!this.hasFeedback) {
          this.ratingSnackbar = true
        }
      }, 1000 * 60 * 2)
    },
    async function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE

      const encryptedName = localStorage.getItem('name')
      this.name = await this.CryptoJS.AES.decrypt(encryptedName, passphrase).toString(this.CryptoJS.enc.Utf8)

      var encryptedAccessToken = localStorage.getItem('tken')
      var encryptedEmbedUrl = localStorage.getItem('embUrl')
      var encryptedEmbedReportId = localStorage.getItem('reportId')
      const encryptedDatasetId = localStorage.getItem('datasetId')
      const userId = localStorage.getItem('kwkk')

      const txtAccessToken = await this.CryptoJS.AES.decrypt(encryptedAccessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      const txtEmbedUrl = await this.CryptoJS.AES.decrypt(encryptedEmbedUrl, passphrase).toString(this.CryptoJS.enc.Utf8)
      const txtEmbedReportId = await this.CryptoJS.AES.decrypt(encryptedEmbedReportId, passphrase).toString(this.CryptoJS.enc.Utf8)
      const txtDatasetId = await this.CryptoJS.AES.decrypt(encryptedDatasetId, passphrase).toString(this.CryptoJS.enc.Utf8)
      this.reportId = txtEmbedReportId

      // Read embed type from radio
      var models = pbi.models
      // We give All permissions to demonstrate switching between View and Edit mode and saving report.
      var permissions = models.Permissions.All
      // Embed configuration used to describe the what and how to embed.
      var config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: txtAccessToken,
        embedUrl: txtEmbedUrl,
        id: txtEmbedReportId,
        permissions: permissions,
        settings: {
          panes: {
            filters: {
              visible: true
            },
            pageNavigation: {
              visible: true
            }
          }
        }
      }
      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)
      // Get a reference to the embedded report HTML element
      var embedContainer = document.getElementById('embedContainer')
      // Embed the report and display it within the div container.
      var report = powerbi.embed(embedContainer, config)
      // Report.off removes a given event handler if it exists.
      report.off('loaded')
      report.on('loaded', function () {
        parent.document.getElementById('progress').style.visibility = 'hidden'
      })
      // Report.off removes a given event handler if it exists.
      report.off('rendered')
      report.on('rendered', function () {
      })
      report.on('error', (event) => {
        parent.document.getElementById('progress').style.visibility = 'hidden'
        const message = event.detail.message
        if (message === 'TokenExpired') {
          // get the refresh embed token
          window.axios.post('/viewership/getEmbedToken', { reportId: txtEmbedReportId, datasetId: txtDatasetId })
            .then(async (response) => {
              const token = response.data.token
              const encryptedToken = this.$CryptoJS.AES.encrypt(token, passphrase).toString()
              localStorage.setItem('tken', encryptedToken)
              window.location.reload()
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
            })
        }
      })
      report.off('saved')
      report.on('saved', function (event) {
        if (event.detail.saveAs) {
        }
      })
      report.off('visualClicked')
      report.on('visualClicked', (event) => {
        // console.log('clicked')
        this.visualClick()
      })
      report.off('pageChanged')
      report.on('pageChanged', (event) => {
        // console.log('pageChanged', event)
        this.pageChange()
      })

      // check if the feedback is already available
      this.feedbackCheck(this.reportId, userId)
    },
    // listen for full screen key presses
    function () {
      document.addEventListener('keydown', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        // and only add the key if its not available
        const keyCheck = this.fullscreenPattern.some((data) => {
          return data === event.key
        })
        var patternSize = this.fullscreenPattern.length

        if (patternSize <= 3 && !keyCheck && (event.key === 'Control' || event.key === 'Shift' || event.key === 'F')) {
          this.fullscreenPattern.push(event.key)
          // check if the Ctrl, Shift & F pattern was pressed
          // console.log('added ---->', event.key)
          patternSize = this.fullscreenPattern.length
          if (patternSize === 3 && this.fullscreenPattern[0] === 'Control' && this.fullscreenPattern[1] === 'Shift' && this.fullscreenPattern[2] === 'F') {
            this.fullscreen()
          }
        }
        // console.log(this.fullscreenPattern)
      })

      document.addEventListener('keyup', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        if (event.key === 'Control' || event.key === 'Shift' || event.key === 'F') {
          // remove the key from the pattern
          const size = this.fullscreenPattern.length

          for (let i = 0; i < size; i++) {
            const key = this.fullscreenPattern[i]

            // if it there remove it
            if (event.key === key) {
              this.fullscreenPattern.splice(i, 1)
              break
            }
          }
        }
      })
    }
  ]
}
</script>

<style scoped>

.v-text-field >>> input {
  font-size: 0.86em;
}
.v-text-field >>> label {
  font-size: 0.86em;
}

#crumbs{
  align-content: right;
  justify-content: right;
}
#powerBiFrame{
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;
}
</style>
