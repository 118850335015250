<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4" style=" font-size: 0.98em;">
              Activate Users
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for users"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"/>
          </v-list-item-content>
          <v-list-item-content >
          <v-data-table
              class="px-4 elevation-0"
              :headers="headers"
              :items="items"
              :search="search"
              style="cursor: pointer;"
              :sort-desc="true">
              <template v-slot:top>
                <v-toolbar
                  flat>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px">
                   <template v-slot:activator="{ }">
                      <v-btn
                        dark
                        tile
                        style="font-size: 0.88em;"
                        class="px-12 text-none"
                        color="#339966"
                        @click.native="registerUsers">
                        Register Users
                      </v-btn>
                    </template>
                    <v-card tile>
                      <v-card-title class="subtitle-1">
                        <span style=" font-size: 0.98em;">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12">
                              <v-text-field
                                readonly
                                dense
                                filled
                                v-model="editedItem.status"
                                label="Current Status"
                                color="#339966"
                                prepend-icon="mdi-state-machine"
                              ></v-text-field>
                              <v-autocomplete
                                v-model="type"
                                item-value="id"
                                dense
                                :items="userStatusItems"
                                label="Change User Status"
                                color="#006622"
                                filled
                                :item-text="item=>item.name"
                                required
                                prepend-icon="mdi-newspaper-variant"
                                @change="statusChanged"
                                return-object/>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#339966"
                          text
                          style=" font-size: 0.88em;"
                          @click="close">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#339966"
                          text
                          style=" font-size: 0.88em;"
                          @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card tile>
                    <v-card-text style=" font-size: 0.98em;" class="text-center pt-4 pb-2"><h4>Are you sure you want to delete this permission?</h4></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#339966" style=" font-size: 0.88em;" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="#339966" style=" font-size: 0.88em;" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2"
                  color="#339966"
                  medium
                  @click="editItem(item)">
                  mdi-account-remove
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  dark
                  tile
                  style="font-size: 0.88em;"
                  class="text-none"
                  color="#339966">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      type: null,
      snackbar: false,
      selectedStatus: '',
      dialog: false,
      dialogDelete: false,
      search: null,
      items: [],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      userStatusItems: [ { name: 'Active' },
        { name: 'Deactivated' }
      ],
      headers: [ { text: 'Firstame', value: 'firstname' },
        { text: 'Surname', value: 'surname' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Stakeholder Group', value: 'group_name' },
        { text: 'HIS Partner', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: ''
      },
      defaultItem: {
        name: ''
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      const userTypeId = localStorage.getItem('utp')
      const userId = localStorage.getItem('kwkk')
      window.axios.post('/user/findByUserType', { userTypeId: userTypeId, userId: userId })
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add User Group' : 'Block / Unblock a User'
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    registerUsers () {
      this.$router.push('userAddUser')
    },
    save () {
      if (this.editedIndex > -1) {
        // edit the item on the server and in the browser
        const id = this.editedItem.id
        window.axios.post('/user/updateStatus', { status: this.selectedStatus, id: id })
          .then((response) => {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the user status.' }
            this.reload()
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
          })
      } else {
        // add a new item to the group permission
      }
      this.close()
    },
    reload () {
      const userTypeId = localStorage.getItem('utp')
      const userId = localStorage.getItem('kwkk')
      window.axios.post('/user/findByUserType', { userTypeId: userTypeId, userId: userId })
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
        })
    },
    statusChanged (item) {
      this.selectedStatus = item.name
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
