<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"/>
        </v-flex>
        <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2" style=" font-size: 0.98em;">
              Register Users
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-2 ma-6">
            <p class="caption black--text font-weight-medium">{{systemMessage}}</p>
            <v-list dense  class="ma-0 pa-0">
              <v-text-field
                v-model="firstname"
                color="#006622"
                filled
                dense
                label="Firstname"
                name="Firstname"
                prepend-icon="mdi-account-box-outline"
                type="text"/>
            </v-list>
            <v-list dense  class="ma-0 pa-0">
              <v-text-field
                v-model="surname"
                color="#006622"
                filled
                dense
                label="Surname"
                name="Surname"
                prepend-icon="mdi-account-box-multiple-outline"
                type="text"/>
          </v-list>
          <v-list dense  class="ma-0 pa-0">
            <v-text-field
              v-model="email"
              color="#006622"
              filled
              dense
              label="Email"
              name="Email"
              prepend-icon="mdi-email-variant"
              type="text"/>
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                v-model="phone"
                color="#006622"
                filled
                dense
                label="Phone"
                name="Phone"
                prepend-icon="mdi-cellphone"
                type="number"/>
          </v-list>
          <v-list dense  class="ma-0 pa-0">
            <v-text-field
              v-model="password"
              color="#006622"
              filled
              dense
              label="Enter Password"
              name="password"
              prepend-icon="mdi-form-textbox-password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required,rules.min]"
              hint="Enter at least 8 characters"
              :type="show ? 'text' : 'password'"
              counter
              @click:append="show = !show"
            ></v-text-field>
          </v-list>
          <v-list>
          <v-card-actions id="btn-group" class="pb-6" width="100%">
            <v-btn @click="register" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;"> Register </v-btn>
          </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      vertical: false,
      userType: null,
      items: [],
      loading: false,
      systemMessage: null,
      firstname: null,
      surname: null,
      email: null,
      phone: null,
      password: '',
      show: false,
      search: null,
      snackbar: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('Email and passwor do not match')
      },
      emailEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty email!' },
      passwordEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty password!' },
      phoneEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty password!' },
      firstnameEmpty: { icon: 'mdi-server-security', label: 'You cannot submit an empty firstname!' },
      surnameEmpty: { icon: 'mdi-server-security', label: 'You cannot submit an empty surname!' },
      userTypeEmpty: { icon: 'mdi-server-security', label: 'Please select your user type!' },
      tooShortFirstname: { icon: 'mdi-server-security', label: 'The firstname submitted is too short!' },
      tooShortSurname: { icon: 'mdi-server-security', label: 'The surname submitted is too short!' },
      tooShortPhone: { icon: 'mdi-server-security', label: 'The phone number submitted is empty or its too short!' },
      tooShortPassword: { icon: 'mdi-server-security', label: 'The password should have at leasat 8 characters!' },
      passwordResetFailed: { icon: 'mdi-server-security', label: 'Pasword reset failed, please try again!' },
      codeEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty reset code!' },
      emailError: { icon: 'mdi-server-security', label: 'You have submitted an invalid email!' },
      emailNotAvailable: { icon: 'mdi-server-security', label: 'The email submitted is not available in our system!' },
      networkError: { icon: 'mdi-server-network-off', label: 'Cannot connect to the internet.' },
      sendingEmailError: { icon: 'mdi-server-network-off', label: 'Failed to send the reset code email, please try again.' },
      systemError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      wrongCodeError: { icon: 'mdi-server-security', label: 'Wrong reset code, please verify your reset !' },
      invalidError: { icon: 'mdi-server-security', label: 'You have submitted an invalid email !' },
      okInsert: { icon: 'mdi-server-security', label: 'Successfully registered the user !' },
      // eslint-disable-next-line no-useless-escape
      emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    reset () {
      this.firstname = ''
      this.surname = ''
      this.email = ''
      this.phone = ''
      this.password = ''
    },
    // validation here
    register () {
      this.loading = true
      this.systemMessage = ''
      if (this.firstname === null) {
        this.snackbarError = this.firstnameEmpty
        this.snackbar = true
        this.loading = false
      } else if (this.surname === null) {
        this.snackbarError = this.surnameEmpty
        this.snackbar = true
        this.loading = false
      } else if (this.firstname.trim().length < 2) {
        this.snackbarError = this.tooShortFirstname
        this.snackbar = true
        this.loading = false
      } else if (this.surname.length < 2) {
        this.snackbarError = this.tooShortSurname
        this.snackbar = true
        this.loading = false
      } else if (!this.emailRegex.test(this.email)) {
        this.snackbarError = this.invalidError
        this.snackbar = true
        this.loading = false
      } else if (this.phone === null) {
        this.loading = false
        this.snackbar = true
        this.snackbarError = this.phoneEmpty
      } else if (this.password === null) {
        this.loading = false
        this.snackbar = true
        this.snackbarError = this.passwordEmpty
      } else if (this.phone.length < 10) {
        this.loading = false
        this.snackbar = true
        this.snackbarError = this.tooShortPhone
      } else if (this.password.length < 8) {
        this.loading = false
        this.snackbar = true
        this.snackbarError = this.tooShortPassword
      } else {
        // console.log(this.userTypeId)
        var userTypeId = localStorage.getItem('utp')
        var userId = localStorage.getItem('kwkk')
        window.axios.post('/user/insert', { userId: userId, firstname: this.firstname, surname: this.surname, email: this.email, phone: this.phone, password: this.password, userTypeId: userTypeId })
          .then((response) => {
            // eslint-disable-next-line
            console.log(response.data)
            var affectedRows = response.data.affectedRows
            if (affectedRows === 1) {
              this.snackbarError = this.okInsert
              this.snackbar = true
              this.reset()
            } else {
              var message = response.data.message
              this.snackbarError = { icon: 'mdi-server-security', label: message }
              this.snackbar = true
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
          })
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/userType/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ]
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
