import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import SeeUsers from '../views/SeeUsers'
import DeactivatedUsers from '../views/DeactivatedUsers'
import SeeReports from '../views/SeeReports'
import ViewedReports from '../views/ViewedReports'
import Profile from '../views/Profile'
import EditProfile from '../views/EditProfile'
import EditPassword from '../views/EditPassword'
import AddReport from '../views/AddReport'
import DeleteReport from '../views/DeleteReport'
import AssignUserType from '../views/AssignUserType'
import PowerBiVisual from '../views/PowerBiVisual'
import JavaScriptVisual from '../views/JavaScriptVisual'
import AddUserType from '../views/AddUserType'
import EditUserType from '../views/EditUserType'
import DeleteUserType from '../views/DeleteUserType'
import EditReport from '../views/EditReport'
import ManagePermissions from '../views/ManagePermissions'
import ManageUserGroups from '../views/ManageUserGroups'
import UserHome from '../views/UserHome'
import UserSeeReport from '../views/UserSeeReports'
import UserViewedReports from '../views/UserViewedReports'
import UserLatestReports from '../views/UserLatestReports'
import UserPowerBiVisual from '../views/UserPowerBiVisual'
import UserJavaScriptVisual from '../views/UserJavaScriptVisual'
import UserProfile from '../views/UserProfile'
import UserEditProfile from '../views/UserEditProfile'
import UserEditPassword from '../views/UserEditPassword'
import ForgotPassword from '../views/ForgotPassword'
import UserCardReports from '../views/UserCardReports'
import TotalViews from '../views/TotalViews'
import TotalViewsToday from '../views/TotalViewsToday'
import MakeUserAdmin from '../views/MakeUserAdmin'
import UserAddUser from '../views/UserAddUser'
import UserNotifications from '../views/UserNotifications'
import UserAdminRequests from '../views/UserAdminRequests'
import AdminAddUser from '../views/AdminAddUser'
import SeeWebMap from '../views/SeeWebMap'
import UserSeeWebMap from '../views/UserSeeWebMap'
import RegisterUser from '../views/RegisterUser'
import ManageHISPartners from '../views/ManageHISPartners'
import UserActivateUser from '../views/UserActivateUser'
import Activation from '../views/Activation'
import ManageUserPermissions from '../views/ManageUserPermissions'
import UserTypeViewedReports from '../views/UserTypeViewedReports'
import UsersViewedReports from '../views/UsersViewedReports'
import EditReportCategory from '../views/EditReportCategory'
import UserHistory from '../views/UserHistory'
import ManageReportCategories from '../views/ManageReportCategories'
import UserPermissions from '../views/UserPermissions'
import DataLake from '../views/DataLake'
import UserAddReport from '../views/UserAddReport'
import UserEditReport from '../views/UserEditReport'
import UserDeleteReport from '../views/UserDeleteReport'
import UserAdminSeeReport from '../views/UserAdminSeeReport'
import UserManagePermissions from '../views/UserManagePermissions'
import UserManageUserPermissions from '../views/UserAdminManageUserPermissions'
import UserReportRatings from '../views/UserReportRatings'
import ReportUpdates from '../views/ReportUpdates'
import UserReportUpdates from '../views/UserReportUpdates'
import AllAvailableReports from '../views/AllAvailableReports'
import AccessRequests from '../views/AccessRequests'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/seeUsers',
    name: 'seeUsers',
    component: SeeUsers
  },
  {
    path: '/seeReports',
    name: 'seeReports',
    component: SeeReports
  },
  {
    path: '/viewedReports',
    name: 'viewedReports',
    component: ViewedReports
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/editProfile',
    name: 'editProfile',
    component: EditProfile
  },
  {
    path: '/editPassword',
    name: 'editPassword',
    component: EditPassword
  },
  {
    path: '/addReport',
    name: 'addReport',
    component: AddReport
  },
  {
    path: '/deleteReport',
    name: 'deleteReport',
    component: DeleteReport
  },
  {
    path: '/assignUserType',
    name: 'assignUserType',
    component: AssignUserType
  },
  {
    path: '/powerBiVisual',
    name: 'powerBiVisual',
    component: PowerBiVisual
  },
  {
    path: '/javaScriptVisual',
    name: 'javaScriptVisual',
    component: JavaScriptVisual
  },
  {
    path: '/addUserType',
    name: 'addUserType',
    component: AddUserType
  },
  {
    path: '/editUserType',
    name: 'editUserType',
    component: EditUserType
  },
  {
    path: '/deleteUserType',
    name: 'deleteUserType',
    component: DeleteUserType
  },
  {
    path: '/editReport',
    name: 'editReport',
    component: EditReport
  },
  {
    path: '/managePermissions',
    name: 'managePermissions',
    component: ManagePermissions
  },
  {
    path: '/manageUserGroups',
    name: 'manageUserGroups',
    component: ManageUserGroups
  },
  {
    path: '/userHome',
    name: 'userHome',
    component: UserHome
  },
  {
    path: '/userSeeReports',
    name: 'userSeeReports',
    component: UserSeeReport
  },
  {
    path: '/userViewedReports',
    name: 'userViewedReports',
    component: UserViewedReports
  },
  {
    path: '/userLatestReports',
    name: 'userLatestReports',
    component: UserLatestReports
  },
  {
    path: '/userPowerBiVisual',
    name: 'userPowerBiVisual',
    component: UserPowerBiVisual
  },
  {
    path: '/userJavaScriptVisual',
    name: 'userJavaScriptVisual',
    component: UserJavaScriptVisual
  },
  {
    path: '/userProfile',
    name: 'userProfile',
    component: UserProfile
  },
  {
    path: '/userEditProfile',
    name: 'userEditProfile',
    component: UserEditProfile
  },
  {
    path: '/userEditPassword',
    name: 'userEditPassword',
    component: UserEditPassword
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/userCardReports',
    name: 'userCardReports',
    component: UserCardReports
  },
  {
    path: '/totalViews',
    name: 'totalViews',
    component: TotalViews
  },
  {
    path: '/totalViewsToday',
    name: 'totalViewsToday',
    component: TotalViewsToday
  },
  {
    path: '/makeUserAdmin',
    name: 'makeUserAdmin',
    component: MakeUserAdmin
  },
  {
    path: '/userAddUser',
    name: 'userAddUser',
    component: UserAddUser
  },
  {
    path: '/userNotifications',
    name: 'userNotifications',
    component: UserNotifications
  },
  {
    path: '/userAdminRequests',
    name: 'userAdminRequests',
    component: UserAdminRequests
  },
  {
    path: '/adminAddUser',
    name: 'adminAddUser',
    component: AdminAddUser
  },
  {
    path: '/seeWebMap',
    name: 'seeWebMap',
    component: SeeWebMap
  },
  {
    path: '/userSeeWebMap',
    name: 'userSeeWebMap',
    component: UserSeeWebMap
  },
  {
    path: '/registerUser',
    name: 'registerUser',
    component: RegisterUser
  },
  {
    path: '/manageHISPartners',
    name: 'manageHISPartners',
    component: ManageHISPartners
  },
  {
    path: '/userActivateUser',
    name: 'userActivateUser',
    component: UserActivateUser
  },
  {
    path: '/activation',
    name: 'activation',
    component: Activation
  },
  {
    path: '/manageUserPermissions',
    name: 'manageUserPermissions',
    component: ManageUserPermissions
  },
  {
    path: '/userTypeViewedReports',
    name: 'userTypeViewedReports',
    component: UserTypeViewedReports
  },
  {
    path: '/usersViewedReports',
    name: 'usersViewedReports',
    component: UsersViewedReports
  },
  {
    path: '/editReportCategory',
    name: 'editReportCategory',
    component: EditReportCategory
  },
  {
    path: '/userHistory',
    name: 'userHistory',
    component: UserHistory
  },
  {
    path: '/manageReportCategories',
    name: 'manageReportCategories',
    component: ManageReportCategories
  },
  {
    path: '/userPermissions',
    name: 'userPermissions',
    component: UserPermissions
  },
  {
    path: '/dataLake',
    name: 'dataLake',
    component: DataLake
  },
  {
    path: '/userAddReport',
    name: 'userAddReport',
    component: UserAddReport
  },
  {
    path: '/userEditReport',
    name: 'userEditReport',
    component: UserEditReport
  },
  {
    path: '/userDeleteReport',
    name: 'userDeleteReport',
    component: UserDeleteReport
  },
  {
    path: '/userAdminSeeReport',
    name: 'userAdminSeeReport',
    component: UserAdminSeeReport
  },
  {
    path: '/userManagePermissions',
    name: 'userManagePermissions',
    component: UserManagePermissions
  },
  {
    path: '/userAdminManageUserPermissions',
    name: 'userAdminManageUserPermissions',
    component: UserManageUserPermissions
  },
  {
    path: '/userReportRatings',
    name: 'userReportratings',
    component: UserReportRatings
  },
  {
    path: '/deactivatedUsers',
    name: 'deactivatedusers',
    component: DeactivatedUsers
  },
  {
    path: '/reportUpdates',
    name: 'reportUpdates',
    component: ReportUpdates
  },
  {
    path: '/userReportUpdates',
    name: 'userReportUpdates',
    component: UserReportUpdates
  },
  {
    path: '/allAvailableReports',
    name: 'allAvailablereports',
    component: AllAvailableReports
  },
  {
    path: '/accessRequests',
    name: 'accessRequests',
    component: AccessRequests
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  meta: {
    reload: true
  }
})

export default router
