<template>
  <v-app id="inspire" class="grey lighten-3">
   <Navbar/>
   <v-main >
     <v-flex>
         <v-container
        fluid
      >
        <v-layout px-5  row class="mt-1">
          <v-flex xs11 dense>
            <div class="subtitle" style=" font-size: 0.98em;">Dashboard</div>
          </v-flex>
          <v-flex class="text-right" xs1 >
            <v-spacer/>
             <v-breadcrumbs class="pa-0" :items="crumbItems" id="crumbs">
              <template v-slot:item="{item}">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" @click="breadCrumbClick(item)" style=" font-size: 0.98em;">
                  {{item.text}}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>
        <Header/>
        <v-layout row>
          <v-flex xs12 pa-5>
            <v-card
              tile
              class="mx-auto"
            >
              <v-flex>
                <v-progress-linear
                  id="progress"
                  color="#009933"
                  height="4.5"
                  indeterminate
                  :active="loading"
                />
              </v-flex>
              <v-list>
                <v-list-item-content>
                  <v-list-item-subtitle @click="fullscreen()" :v-model="name" class="pl-4 pb-4" style=" font-size: 0.96em;">
                    <span>{{name}}</span>
                    <v-icon
                      @click="fullscreen"
                      style="float: right;"
                      color="green"
                      class=" pr-7" >
                        mdi-presentation
                    </v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="ml-8 mr-8"  >
                <div  id="embedContainer" style="height:90vh; border: 0px;" ></div>
                <div style="text-align: center;">
                  <v-btn
                    color="#339966"
                    class="text-none pa-4 mt-2 mb-4"
                    dark
                    tile
                    style="font-size: 0.88em;"
                    @click="fullscreen">
                    Enter fullscreen mode
                  </v-btn>
                </div>
                </v-list-item-content>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
     </v-flex>
   </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Header from '@/components/Header'
import * as pbi from 'powerbi-client'

export default {
  data: () => {
    return {
      fullscreenPattern: [],
      loading: true,
      name: '',
      crumbItems: [
        {
          text: 'Report Visual',
          disabled: false,
          href: '#'
        }
      ]
    }
  },
  components: {
    Navbar,
    Header
  },
  methods: {
    breadCrumbClick (item) {
      this.$router.push(item.route).catch(failure => {
        window.location.reload()
      })
    },
    fullscreen () {
      // check if fullscreen mode is available
      if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled) {
        // which element will be fullscreen
        var iframe = document.querySelector('#embedContainer iframe')
        // Do fullscreen
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen()
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen()
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen()
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen()
        }
      } else {
        document.querySelector('.error').innerHTML = 'Your browser is not supported'
      }
    }
  },
  mounted: [
    async function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE

      const encryptedName = localStorage.getItem('name')
      this.name = await this.CryptoJS.AES.decrypt(encryptedName, passphrase).toString(this.CryptoJS.enc.Utf8)

      var encryptedAccessToken = localStorage.getItem('tken')
      var encryptedEmbedUrl = localStorage.getItem('embUrl')
      var encryptedEmbedReportId = localStorage.getItem('reportId')
      const encryptedDatasetId = localStorage.getItem('datasetId')

      const txtAccessToken = await this.CryptoJS.AES.decrypt(encryptedAccessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      const txtEmbedUrl = await this.CryptoJS.AES.decrypt(encryptedEmbedUrl, passphrase).toString(this.CryptoJS.enc.Utf8)
      const txtEmbedReportId = await this.CryptoJS.AES.decrypt(encryptedEmbedReportId, passphrase).toString(this.CryptoJS.enc.Utf8)
      const txtDatasetId = await this.CryptoJS.AES.decrypt(encryptedDatasetId, passphrase).toString(this.CryptoJS.enc.Utf8)

      var models = pbi.models
      // We give All permissions to demonstrate switching between View and Edit mode and saving report.
      var permissions = models.Permissions.All
      // Embed configuration used to describe the what and how to embed.
      var config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: txtAccessToken,
        embedUrl: txtEmbedUrl,
        id: txtEmbedReportId,
        permissions: permissions,
        settings: {
          panes: {
            filters: {
              visible: true
            },
            pageNavigation: {
              visible: true
            }
          }
        }
      }
      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)
      // Get a reference to the embedded report HTML element
      var embedContainer = document.getElementById('embedContainer')
      // Embed the report and display it within the div container.
      var report = powerbi.embed(embedContainer, config)
      // Report.off removes a given event handler if it exists.
      report.off('loaded')
      // Report.on will add an event handler which prints to Log window.
      report.on('loaded', function () {
        // console.log('Loaded')
        parent.document.getElementById('progress').style.visibility = 'hidden'
      })
      // Report.off removes a given event handler if it exists.
      report.off('rendered')
      // Report.on will add an event handler which prints to Log window.
      report.on('rendered', function () {
      })
      report.on('error', (event) => {
        parent.document.getElementById('progress').style.visibility = 'hidden'
        const message = event.detail.message
        if (message === 'TokenExpired') {
          // get the fresh embed token
          window.axios.post('/viewership/getEmbedToken', { reportId: txtEmbedReportId, datasetId: txtDatasetId })
            .then(async (response) => {
              const token = response.data.token
              const encryptedToken = this.$CryptoJS.AES.encrypt(token, passphrase).toString()
              localStorage.setItem('tken', encryptedToken)
              window.location.reload()
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
            })
        }
      })
      report.off('saved')
      report.on('saved', function (event) {
        if (event.detail.saveAs) {
        }
      })
    },
    // listen for full screen key presses
    function () {
      document.addEventListener('keydown', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        // and only add the key if its not available
        const keyCheck = this.fullscreenPattern.some((data) => {
          return data === event.key
        })
        var patternSize = this.fullscreenPattern.length

        if (patternSize <= 3 && !keyCheck && (event.key === 'Control' || event.key === 'Shift' || event.key === 'F')) {
          this.fullscreenPattern.push(event.key)
          // check if the Ctrl, Shift & F pattern was pressed
          // console.log('added ---->', event.key)
          patternSize = this.fullscreenPattern.length
          if (patternSize === 3 && this.fullscreenPattern[0] === 'Control' && this.fullscreenPattern[1] === 'Shift' && this.fullscreenPattern[2] === 'F') {
            this.fullscreen()
          }
        }
        // console.log(this.fullscreenPattern)
      })

      document.addEventListener('keyup', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        if (event.key === 'Control' || event.key === 'Shift' || event.key === 'F') {
          // remove the key from the pattern
          const size = this.fullscreenPattern.length

          for (let i = 0; i < size; i++) {
            const key = this.fullscreenPattern[i]

            // if it there remove it
            if (event.key === key) {
              this.fullscreenPattern.splice(i, 1)
              break
            }
          }
        }
      })
    }
  ]
}
</script>

<style>
#crumbs{
  align-content: right;
  justify-content: right;
}
#powerBiFrame{
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;
}
iframe {
  border: none;
  height: 100%;
  width: 100%
}
</style>
