<template>
  <nav>
  <v-app-bar
    app
    dense
    color="#339966"
    dark>
    <v-app-bar-nav-icon @click.stop="drawer=!drawer">
    </v-app-bar-nav-icon>
      <v-spacer/>
       <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        clearable
        hide-details
        hide-selected
        item-text="name"
        item-value="symbol"
        label="Search for reports"
        filled
        outlined
        hide-no-data
        single-line
        flat
        solo-inverted
        prepend-inner-icon="mdi-archive-search-outline"
        append-icon="null"
        class="hidden-sm-and-down"
        color="#339966"
        return-object
        dense
        @change="loadReport"/>
      <v-spacer/>
        <v-badge
        class="mr-6"
        color="red"
        dot
        left
        :value="visibilityAdminRequest">
        <v-icon
            color="white lighten-1"
            size="27"
            @click="pageRoute('/userAdminRequests')">
            mdi-account-tie-outline
          </v-icon>
      </v-badge>
      <v-badge
        class="mr-6"
        color="#f8b500"
        dot
        left
        :value="visibility">
        <v-icon
            color="white lighten-1"
            size="28"
            @click="pageRoute('/makeUserAdmin')"
          >
            mdi-crown-outline
          </v-icon>
      </v-badge>
      <v-avatar
        size="26"
        class="red darken-2 lighten-1 mr-2">
         <v-icon @click="pageRoute('/viewedReports', 1)" size="16" dark>mdi-shaker</v-icon>
      </v-avatar>
      <v-menu
        left
        bottom>
      <template v-slot:activator="{on, attrs}">
         <v-btn
          icon
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        </template>
        <v-card class="mx-auto" max-width="300" tile>
        <v-list dense shaped menu-props="auto" >
          <v-subheader>Menu</v-subheader>
          <v-list-item-group
             color="#339966">
            <v-list-item
              v-for="(item, i) in menuItems"
              :key="i"
              @click="itemClick(i)"
              >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" style=" font-size: 0.90em;"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </v-card>
      </v-menu>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    class="white"
    fill-height
    :width="290"
    app>
    <v-app-bar
      flat
      dense
      color="#39ac73">
    <v-spacer/>
    <!-- src="../assets/round-logo.png" -->
    <v-img
        class="pa-0 ma-0"
        alt="EGPAF logo"
        contain
        src="../assets/coat-of-arms.png"
        transition="scale-transition"
        height="36px"
        width="0"
        style="border-radius: 50%;"/>
     <span class="white--text large font-weight-bold">MOH</span>
     <v-spacer/>
    </v-app-bar>
     <v-list shaped dense>
        <v-subheader class="pl-5">Main Menu</v-subheader>
        <v-list-item-group
            color="#339966">
        <template v-for="item in links">
          <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center">
            <v-col cols="6">
              <v-subheader class="black-text text-left" v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              cols="6"
              class="text-center">
              <a
                href="#!"
                class="body-2 black--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon="">
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="caption black--text font-weight-medium">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
              <v-icon small>mdi-checkbox-multiple-blank-circle</v-icon>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
               @click="pageRoute(child.route)">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="black--text text-left">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            :to="item.route"
            active-class="highlighted"
            @click.stop="pageRoute(item.route)">
          <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text--black font-weight-medium text-left" style=" font-size: 0.88em;">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        </v-list-item-group>
      </v-list>
      <v-list shaped dense>
        <v-subheader class="pl-5 pt-0 pb-0">Extras</v-subheader>
           <v-list-item-group
             color="#339966">
            <v-list-item @click="itemClick (10) ">
              <v-list-item-icon>
                <v-icon>mdi-power-settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-left" style=" font-size: 0.9em;">Sign Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item class="mt-0 mb-4" style="position: absolute; bottom: 0; margin-bottom: 6px;">
              <img
                  id="pepfar-logo"
                  class="mt-1"
                  alt="EGPAF logo"
                  style="width: 24%;"
                  src="../assets/new-pepfar.png"
              />
              <img
                  id="egpaf-logo"
                  class="pl-2 pr-1 ma-0"
                  alt="EGPAF logo"
                  height="45px"
                  width="80%"
                  src="../assets/egpaf-logo.jpg"
              />
          </v-list-item>
      </v-list>
  </v-navigation-drawer>
   <template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="searchDialog"
      persistent
      max-width="315">
      <v-card tile>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-2">
          Loading the {{reportName}} Report
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> loading please wait...</v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-row>
</template>
  </nav>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      reportName: '',
      searchDialog: false,
      snackbar: false,
      dialog: false,
      visibility: true,
      visibilityAdminRequest: false,
      drawer: !isMobile,
      links: [
        { icon: 'mdi-home-import-outline', text: 'Dashboard', route: '/home' },
        { icon: 'mdi-archive-plus-outline', text: 'Add Reports', route: '/addReport' },
        { icon: 'mdi-card-bulleted-settings-outline', text: 'Edit Reports', route: '/editReport' },
        { icon: 'mdi-archive-eye-outline', text: 'See All Reports', route: '/seeReports' },
        { icon: 'mdi-archive-cancel-outline', text: 'Delete Reports', route: '/deleteReport' },
        { icon: 'mdi-chart-multiple', text: 'Most Viewed Reports', route: '/viewedReports' },
        { icon: 'mdi-account-group-outline', text: 'See Users', route: '/seeUsers' },
        { icon: 'mdi-charity', text: 'Manage HIS Partners', route: '/manageHISPartners' },
        { icon: 'mdi-cog-clockwise', text: 'Manage Report Permissions', route: '/managePermissions' },
        { icon: 'mdi-account-group-outline', text: 'Manage Stakeholder Groups', route: '/manageUserGroups' }
      ],
      menuItems: [
        { text: 'Manage Report Categories', icon: 'mdi-lightbulb-group-outline' },
        { text: 'Manage User Permissions', icon: 'mdi-cog-clockwise' },
        { text: 'HIS Partners Views', icon: 'mdi-charity' },
        { text: 'Edit Report Categories', icon: 'mdi-orbit' },
        { text: 'User Views', icon: 'mdi-account-reactivate-outline' },
        { text: 'Report Ratings', icon: 'mdi-hexagram-outline' },
        { text: 'Edit Profile', icon: 'mdi-account-edit-outline' },
        { text: 'Deactivated Users', icon: 'mdi-account-multiple-remove-outline' },
        { text: 'Report Updates', icon: 'mdi-chart-box-outline' },
        { text: 'Access Requests', icon: 'mdi-lock-reset' },
        { text: 'Sign Out', icon: 'mdi-power-settings' }
      ],
      isLoading: false,
      items: [],
      model: null,
      search: null,
      snackbarError: { icon: 'mdi-server-security', label: 'Cannot connect to the server, network error!' }
    }
  },
  methods: {
    itemClick (i) {
      switch (i) {
        case 0:
          this.$router.push('manageReportCategories')
          break
        case 1:
          this.$router.push('manageUserPermissions')
          break
        case 2:
          this.selectedItem = 1
          this.$router.push('/userTypeViewedReports')
          break
        case 3:
          this.selectedItem = 1
          this.$router.push('/editReportCategory')
          break
        case 4:
          this.selectedItem = 1
          this.$router.push('/usersViewedReports')
          break
        case 5:
          this.selectedItem = 1
          this.$router.push('/userReportRatings')
          break
        case 6:
          this.selectedItem = 1
          this.$router.push('/editProfile')
          break
        case 7:
          this.selectedItem = 1
          this.$router.push('/deactivatedUsers')
          break
        case 8:
          this.selectedItem = 1
          this.$router.push('/reportUpdates')
          break
        case 9:
          this.selectedItem = 1
          this.$router.push('/accessRequests')
          break
        default:
          this.$router.push('/')
      }
    },
    pageRoute (route) {
      // finding the position of the route
      for (var i = 0; i < this.links.length; i++) {
        if (route === this.links[i].route) {
          localStorage.setItem('sl', i)
          break
        }
      }
      this.$router.push(route).catch(failure => {
        window.location.reload()
      })
    },
    loadReport (item) {
      this.reportName = item.type
      localStorage.setItem('name', item.name)
      if (item.type === 'Power Bi') {
        this.searchDialog = true
        const reportId = item.report_id
        const datasetId = item.dataset_id
        const embedUrl = item.embed_url
        window.axios.post('/viewership/getEmbedToken', { reportId: reportId, datasetId: datasetId })
          .then(async (response) => {
            // getting the encryption passphrase
            const token = response.data.token
            this.searchDialog = false
            const passphrase = process.env.VUE_APP_PASSPHRASE
            const encryptedToken = this.CryptoJS.AES.encrypt(token, passphrase).toString()
            const encryptedReportId = this.CryptoJS.AES.encrypt(reportId, passphrase).toString()
            const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
            const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
            const encryptedDatasetId = this.CryptoJS.AES.encrypt(datasetId, passphrase).toString()
            localStorage.setItem('datasetId', encryptedDatasetId)
            localStorage.setItem('name', encryptedName)
            localStorage.setItem('tken', encryptedToken)
            localStorage.setItem('reportId', encryptedReportId)
            localStorage.setItem('embUrl', encryptedEmbedUrl)
            this.$router.push('powerBiVisual')
            window.location.reload()
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.searchDialog = false
          })
      } else if (item.type === 'Web Embed' || item.type === 'Dash') {
        this.searchDialog = true
        this.$router.push('seeWebMap')
        this.dialog = false
        const embedUrl = item.embed_url
        const passphrase = process.env.VUE_APP_PASSPHRASE
        const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
        const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
        localStorage.setItem('name', encryptedName)
        localStorage.setItem('embUrl', encryptedEmbedUrl)
        this.$router.push('seeWebMap')
        window.location.reload()
      } else {
      }
    }
  },
  mounted: function () {
    const passphrase = process.env.VUE_APP_PASSPHRASE
    const accessToken = localStorage.getItem('token')
    const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
    window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

    var id = localStorage.getItem('kwkk')

    if (id === '' || id === null) {
      this.$router.push('/')
    } else {
      // test if the user is an admin
      window.axios.post('/admin/find', { id: id })
        .then((response) => {
          if (response.data.length === 0) {
            this.$router.push('/')
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
        })
    }
    // check if there are any new admin status requests
    window.axios.get('/adminRequest/findAllUnaccepted')
      .then((response) => {
        if (response.data.length > 0) {
          this.visibilityAdminRequest = true
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch((error) => {
      })
  },
  watch: {
    model (val) {
      if (val != null) this.tab = 0
      else this.tab = null
    },
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return
      this.isLoading = true
      var url = '/report/search' + '?searchText=' + val
      window.axios.get(url)
        .then((response) => {
          this.items = response.data
          this.isLoading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #egpaf-logo {
    height: 40px;
    width: 80%;
  }
  #pepfar-logo {
    height: 50px;
    width: 26%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #egpaf-logo {
    height: 40px;
    width: 80%;
  }
  #pepfar-logo {
    height: 50px;
    width: 68%;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #egpaf-logo {
    height: 40px;
    width: 80%;
  }
  #pepfar-logo {
    height: 50px;
    width: 50px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #egpaf-logo {
    height: 40px;
    width: 80%;
  }
  #pepfar-logo {
    height: 50px;
    width: 50px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #egpaf-logo {
    height: 40px;
    width: 80%;
  }
  #pepfar-logo {
    height: 48px;
    width: 48px;
  }
}
.text-size {
  font-size: 0.8em;
}
.text-size-input {
  font-size: 0.9em;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
