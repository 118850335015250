<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2">
              Edit HIS Partner
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-8 ma-6">
          <v-list dense  class="ma-0 pa-0">
              <v-autocomplete
                class="ma-0 pa-0"
                v-model="id"
                item-value="id"
                :items="item"
                label="Select HIS Partner"
                color="#006622"
                filled
                :item-text=" item=>item.name"
                required
                prepend-icon="mdi-doctor"
              />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                class="ma-0 pa-0"
                v-model="name"
                label="Rename HIS Partner"
                color="#006622"
                filled
                required
                prepend-icon="mdi-account-box"
              />
          </v-list>
           <v-list dense class="ma-0 pa-0">
              <v-autocomplete
                class="ma-0 pa-0"
                v-model="userGroupId"
                item-value="id"
                :items="groupItem"
                label="Select Stakeholder Group"
                color="#006622"
                filled
                :item-text=" item=>item.name"
                required
                prepend-icon="mdi-account-group"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
              <v-card-actions id="btn-group" class="pb-6" width="100%">
                      <v-btn @click="updateUserType" class="px-12 text-none" color="#339966" dark style="font-size: 0.88em;"> Edit HIS Partner </v-btn>
                    </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      snackbar: false,
      loading: false,
      systemMessage: null,
      item: [],
      groupItem: [],
      id: null,
      userGroupId: null,
      name: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.get('/userType/findAll')
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.get('/userGroup/findAll')
        .then((response) => {
          this.groupItem = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    reset () {
      this.name = ''
      this.userGroupId = null
      this.id = null
    },
    updateUserType () {
      if (this.name === '' || this.name === null) {
        this.snackbar = true
        this.snackbarError = { icon: 'mdi-server-security', label: `Please enter the HIS partner's name.` }
      } else if (this.userGroupId === '' || this.userGroupId === null) {
        this.snackbar = true
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please select the Stakeholder group.' }
      } else if (this.id == null) {
        this.snackbar = true
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please select the HIS partner to edit.' }
      } else {
        this.loading = true
        this.systemMessage = ''
        window.axios.post('/userType/update', { id: this.id, name: this.name, userGroupId: this.userGroupId })
          .then((response) => {
            // eslint-disable-next-line
            var affectedRows = response.data.affectedRows
            if (affectedRows === 1) {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the user type.' }
              this.reset()
            } else {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to edit the user type.' }
            }
            this.allUserType()
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.loading = false
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again.' }
          })
      }
    },
    allUserType () {
      window.axios.get('/userType/findAll')
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
