<template>
<v-layout row wrap>
    <v-flex v-for="(item) in headerItems" xs12  md3 :key="item.title" pl-5 pr-5 pt-4 >
      <v-card
        tile
        id="header-size"
        class="mx-auto elevation-1"
        @click="cardClick(item.route)"
        :ripple="{ class: 'white--text', center: true }"
      >
      <v-progress-linear
        :color="item.color"
        height="4"
        value="100"
        />
      <v-list dense>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 text-left font-bold text-size-title">
              {{item.title}}
            </v-list-item-subtitle>
            <v-list-item class="">
                <v-icon size="30" :color="item.color"  class="pr-2 text-size">{{item.icon}}</v-icon>
                <v-list-item-subtitle class="text-left" style="font-size: 0.9em;">{{item.description}}</v-list-item-subtitle>
            </v-list-item>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
     <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="dialog"
          persistent
          max-width="300"
        >
          <v-card tile>
            <v-card-title class="subtitle-2">
              Access Authorization
            </v-card-title>
            <v-card-text class="pb-2 pt-1"><v-icon color="red">mdi-door-closed</v-icon> Your access token has expired.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                color="green darken-1"
                @click="logout"
              >
                Ok
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
 </v-layout>
</template>
<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      dialog: false,
      headerItems: [
        { icon: 'mdi-account-group-outline', title: 'Total Users', description: 'Users', color: '#44a08d', apiRoute: '/user/findCount', route: '/seeUsers' },
        { icon: 'mdi-chart-box-outline', title: 'Total Reports', description: 'Reports', color: '#e73827', apiRoute: '/report/findCount', route: '/seeReports' },
        // { icon: 'mdi-bell-plus-outline', title: 'Total Views', description: 'Views', color: '#f8b500', apiRoute: '/viewership/findCount', route: '/totalViews' },
        { icon: 'mdi-bell-plus-outline', title: 'Total Views', description: 'Views', color: '#44a04c', apiRoute: '/viewership/findCount', route: '/totalViews' },
        { icon: 'mdi-finance', title: 'Today\'s Views', description: 'Views', color: '#44a04c', apiRoute: '/viewership/findTodayCount', route: '/totalViewsToday' }
      ]
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      for (var i = 0; i < this.headerItems.length; i++) {
        this.getHeaderItemCount(this.headerItems[i].apiRoute, i, decryptedAccessToken)
      }
    }
  ],
  methods: {
    logout () {
      this.$router.push('/')
    },
    getHeaderItemCount (route, counter, accessToken) {
      // window.axios.defaults.headers.post['Authorization'] = `Bearer ${accessToken}`
      window.axios.get(route, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          var count = response.data[0].count
          if (count === -1) {
            // show the dialog that the baccess token has expired
            // const message = response.data[0].message
            // if (message === 'jwt malformed') {
            //   this.$router.push('/')
            // } else {
            //   this.dialog = true
            // }
          } else if (counter === 0) {
            if (count === 1) {
              this.headerItems[counter].description = count + ' User'
            } else {
              this.headerItems[counter].description = count + ' Users'
            }
          } else if (counter === 1) {
            if (count === 1) {
              this.headerItems[counter].description = count + ' Report'
            } else {
              this.headerItems[counter].description = count + ' Reports'
            }
          } else if (counter === 2) {
            if (count === 1) {
              this.headerItems[counter].description = count + ' View'
            } else {
              this.headerItems[counter].description = count + ' Views'
            }
          } else if (counter === 3) {
            if (count === 1) {
              this.headerItems[counter].description = count + ' View'
            } else {
              this.headerItems[counter].description = count + ' Views'
            }
          }
          return response.data[0].count
        }).catch((err) => {
          if (err.response.status === 401 && (err.response.data === 'jwt malformed' || err.response.data === 'empty jwt token')) {
            this.$router.push('/')
          } else {
            // show the dialog that the access token has expired
            this.tokenDialog = true
          }
          return 'unavailable'
        })
    },
    cardClick (route) {
      this.$router.push(route).catch(failure => {
        window.location.reload()
      })
    }
  }
}
</script>
<style scoped>
.text-size-title {
  font-size: 0.88em;
  font-weight: 416;
  color: #595959;
}
.text-size {
  font-size: 0.92em;
  color: #595959;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
