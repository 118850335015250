<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 pt-2 pb-6" style=" font-size: 0.98em;">
              My Messages
            </v-list-item-subtitle>
          </v-list-item-content>
          <div class="px-4 pb-8">
            <v-timeline
              dense
              clipped>
              <v-list-item-subtitle v-if="items.length==0" style=" font-size: 0.98em;" class="pl-4 pt-2 pb-6 text-center">
                No new messages
              </v-list-item-subtitle>
              <v-timeline-item
                class="mb-1"
                small
                color="#339966"
                v-for="item in items" :key="item.id">
                <v-row>
                  <v-col cols="8">
                  <div class="" style=" font-size: 0.98em;"> {{item.message}} </div>
                  </v-col>
                  <v-col
                    class="text-right pr-12"
                    cols="4"
                  >
                    <div class="" style=" font-size: 0.98em;">{{item.date_created}}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>

            </v-timeline>
            <div>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      notification: '',
      items: []
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var userId = localStorage.getItem('kwkk')
      window.axios.post('/message/findByUserId', { userId: userId })
        .then((response) => {
          this.items = response.data
          // getting the message id to set that the message has been seen
          const messageIds = []
          for (var i = 0; i < this.items.length; i++) {
            // adding the message ids to the message ids list
            messageIds.push(this.items[i].id)
          }
          // inserting seen messages
          window.axios.post('/seen/insert', { userId: userId, messageIds: messageIds })
            .then((response) => {
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
            })
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
