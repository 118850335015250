<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
        <v-list-item-subtitle class="pl-4 pt-2 " style=" font-size: 0.98em;">
          Edit My Password
        </v-list-item-subtitle>
        </v-list>
        <v-form class="px-8 ma-6">
          <v-list dense  class="ma-0 pa-0">
            <v-text-field
                v-model="oldPassword"
                color="#006622"
                filled
                dense
                label="Old Password"
                name="Old Password"
                prepend-icon="mdi-server-security"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
            />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
            <v-text-field
                v-model="newPassword"
                color="#006622"
                filled
                dense
                label="New Password"
                name="New Password"
                prepend-icon="mdi-security"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
            />
          </v-list>
          <v-list>
            <v-card-actions id="btn-group" class="pb-6" width="100%">
              <v-btn @click="updatePassword" class="px-12 text-none" color="#39ac73" dark tile style="font-size: 0.88em;"> Edit Password </v-btn>
            </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white" > {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      snackbar: false,
      show1: false,
      show2: false,
      loading: false,
      systemMessage: null,
      id: null,
      firstname: null,
      surname: null,
      email: null,
      phone: null,
      password: null,
      search: null,
      newPassword: null,
      oldPassword: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      var id = localStorage.getItem('kwkk')
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.post('/admin/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.id = response.data[0].id
            this.password = response.data[0].password
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    updatePassword () {
      this.loading = true
      this.systemMessage = ''
      if (this.newPassword === null || this.newPassword.trim() === '' || this.oldPassword === null || this.oldPassword.trim() === '') {
        this.loading = false
        this.systemMessage = 'Please fill the old password and new password fields below'
        this.snackbar = true
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill the old password and new password fields' }
      } else {
        window.axios.post('/admin/updatePassword', { id: this.id, oldPassword: this.oldPassword, email: this.email, password: this.newPassword })
          .then((response) => {
            // eslint-disable-next-line
            var affectedRows = response.data.response.affectedRows
            if (affectedRows === 1) {
              this.systemMessage = 'Successfully edited your password'
              this.oldPassword = ''
              this.newPassword = ''
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Sucessfully edited your password' }
            } else if (affectedRows === 0) {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: response.data.response.message }
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            // console.log(error)
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
            this.snackbar = true
          })
      }
    },
    load () {
      var id = localStorage.getItem('kwkk')
      window.axios.post('/admin/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.id = response.data[0].id
            this.password = response.data[0].password
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
