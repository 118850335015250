<template>
  <v-app id="inspire" class="grey lighten-3">
   <UserNavbar/>
   <v-main>
     <v-flex>
         <v-container
        fluid
      >
        <v-layout px-5 row class="mt-1">
          <v-flex xs6 md10 dense>
            <div class="subtitle" style=" font-size: 0.98em;">Dashboard</div>
          </v-flex>
          <v-flex class="text-right" xs6 md2 >
            <v-spacer/>
             <v-breadcrumbs class="pa-0" :items="crumbItems" id="crumbs">
              <template v-slot:item="{item}">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" @click="breadCrumbClick(item)" style=" font-size: 0.98em;">
                  {{item.text}}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>
        <UserHeader/>
        <UserNotifications/>
      </v-container>
     </v-flex>
   </v-main>
  </v-app>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import UserHeader from '@/components/UserHeader'
import UserNotifications from '@/components/UserNotifications'
export default {
  data: () => {
    return {
      crumbItems: [
        {
          text: 'Home',
          disabled: false,
          href: '#',
          route: '/userHome'
        },
        {
          text: 'My Messages',
          disabled: false,
          href: '#',
          route: '/userNotifications'
        }
      ]
    }
  },
  components: {
    UserNavbar,
    UserHeader,
    UserNotifications
  },
  mounted: [
  ],
  methods: {
    breadCrumbClick (item) {
      this.$router.push(item.route).catch(failure => {
        window.location.reload()
      })
    }
  }
}
</script>

<style scoped>
#crumbs{
  align-content: right;
  justify-content: right;
}
</style>
