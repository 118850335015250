import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import LoadScript from 'vue-plugin-load-script'
// http://localhost:3001 'http://192.168.200.132:5000' '/map-back-end/'
window.axios = axios
axios.defaults.baseURL = '/map-back-end/'
axios.defaults.timeout = 40000
Vue.use(LoadScript)
Vue.use(VueCryptojs)
Vue.config.productionTip = false
export const bus = new Vue()

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: h => h(App)
}).$mount('#app')
