<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2 text-left" style=" font-size: 0.98em;">
              Edit {{ownerName}} Report
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-4 ma-6">
          <v-list dense  class="ma-0 pa-0">
              <v-autocomplete
                class="ma-0 pa-0"
                v-model="id"
                item-value="id"
                :items="item"
                label="Select Report"
                color="#006622"
                dense
                filled
                :item-text=" item=>item.name + ', Report type ' + item.type + ', Workspace ' + item.workspace_name "
                required
                prepend-icon="mdi-tag-heart"
                return-object
                @change="setupFields"
              />
          </v-list>
         <v-list dense  class="ma-0 pa-0">
              <v-autocomplete
                v-model="type"
                item-value="typeId"
                :items="typeItem"
                label="Report Type"
                color="#006622"
                filled
                dense
                :item-text="item=>item.name"
                required
                return-object
                prepend-icon="mdi-card-bulleted-settings-outline"
                @change="reportTypeSelected"
              />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="name"
                    color="#006622"
                    filled
                    label="Report Name"
                    name="Report Name"
                    prepend-icon="mdi-card-bulleted-settings"
                    type="text"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
            <v-text-field
                  v-model="datasetId"
                  color="#006622"
                  filled
                  label="Dataset Id"
                  name="Dataset Id"
                  prepend-icon="mdi-openid"
                  type="text"
                  :disabled="leafletDisable"
            />
          </v-list>
          <v-list dense  class="ma-0 pa-0">
              <v-text-field
                    v-model="reportId"
                    color="#006622"
                    filled
                    label="Report Id"
                    name="Report Id"
                    prepend-icon="mdi-link"
                    type="text"
                    :disabled="leafletDisable"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
             <v-text-field
                    v-model="embedUrl"
                    color="#006622"
                    filled
                    label="Embed Url / Docker URL"
                    name="Embed Url"
                    prepend-icon="mdi-web"
              />
              <v-card-actions id="btn-group" class="pb-6" width="100%">
                      <v-btn @click="updateReport" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;">Edit Report </v-btn>
                    </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
       <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white" > {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      ownerName: '',
      leafletDisable: false,
      snackbar: false,
      loading: false,
      systemMessage: null,
      item: [],
      typeItem: [],
      id: null,
      selectedId: null,
      typeId: null,
      name: null,
      type: null,
      datasetId: null,
      embedUrl: null,
      reportId: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/report/findByReportOwner', { userTypeId: userTypeId })
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/reportType/findAll')
        .then((response) => {
          this.typeItem = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/userType/find', { id: userTypeId })
        .then((response) => {
          this.ownerName = response.data[0].name
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/userType/find', { id: userTypeId })
        .then((response) => {
          this.ownerName = response.data[0].name
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    reportTypeSelected (item) {
      var name = item.name
      if (name === 'Web Embed' || name === 'Dash') {
        this.leafletDisable = true
      } else {
        this.leafletDisable = false
      }
    },
    updateReport () {
      if (this.type !== null) {
        this.systemMessage = ''
        if (this.selectedId === null) {
          this.loading = false
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please select a report to edit' }
        } else {
          var name = ''
          if (this.type.id !== null) {
            for (var i = 0; i < this.typeItem.length; i++) {
              if (this.type.id === this.typeItem[i].id) {
                name = this.typeItem[i].name
              }
            }
          }
          const reportTypeId = this.type.id
          if ((name === 'Web Embed' || name === 'dash') && this.name !== '' && this.name !== null && this.embedUrl !== null && this.embedUrl !== '' && this.reportTypeId !== null) {
            this.loading = true
            window.axios.post('/report/update', { id: this.id.id, name: this.name, reportId: null, embedUrl: this.embedUrl, datasetId: null, reportTypeId: reportTypeId })
              .then((response) => {
                // eslint-disable-next-line
                var affectedRows = response.data.affectedRows
                if (affectedRows === 1) {
                  this.systemMessage = 'Successfully edited the report'
                  this.snackbar = true
                  this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the report' }
                } else {
                  this.systemMessage = 'Failed to edit the report'
                  this.snackbar = true
                  this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to edit the report' }
                }
                this.allUserType()
                this.loading = false
              })
              // eslint-disable-next-line handle-callback-err
              .catch(() => {
                this.systemMessage = 'Sorry an error occurred, please try again. '
                this.loading = false
                this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again ' }
              })
          } else if (this.name === '' || this.name === null) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the Report Name' }
          } else if (this.reportId === '' || this.reportId === null) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the report Id' }
          } else if (this.type === null || this.type === null) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the report type' }
          } else if (this.datasetId === '' || this.datasetId === null) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the Dataset Id' }
          } else if (this.embedUrl === null || this.embedUrl === '') {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter the report Embed URL' }
          } else {
            this.loading = true
            const reportTypeId = this.type.id
            window.axios.post('/report/update', { id: this.id.id, name: this.name, reportId: this.reportId, embedUrl: this.embedUrl, datasetId: this.datasetId, reportTypeId: reportTypeId })
              .then((response) => {
                // eslint-disable-next-line
                var affectedRows = response.data.affectedRows
                if (affectedRows === 1) {
                  this.systemMessage = 'Successfully edited the report'
                  this.snackbar = true
                  this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the report' }
                } else {
                  this.systemMessage = 'Failed to edit the report'
                  this.snackbar = true
                  this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to edit the report' }
                }
                this.allUserType()
                this.loading = false
              })
              // eslint-disable-next-line handle-callback-err
              .catch(() => {
                this.systemMessage = 'Sorry an error occurred, please try again. '
                this.loading = false
                this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again ' }
              })
          }
        }
      }
    },
    allUserType () {
      var userTypeId = localStorage.getItem('utp')
      window.axios.post('/report/findByReportOwner', { userTypeId: userTypeId })
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    setupFields (item) {
      this.selectedId = item.id
      this.name = item.name
      this.embedUrl = item.embed_url
      this.datasetId = item.dataset_id
      this.reportId = item.report_id
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
