<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4" style=" font-size: 0.98em;">
              Report Ratings Table
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search reports"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"
              />
          </v-list-item-content>
          <v-list-item-content >
            <v-data-table
              flat
              :search="search"
              class="px-4 elevation-0"
              :sort-desc="true"
              color="#339966"
              :headers="headers"
              :items="items"
              :items-per-page=15
              @click:row="loadReport"
              style="cursor: pointer;"
              return-object
              >
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
    <template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
      max-width="315"
    >
      <v-card tile>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-1">
          Loading the {{reportName}} Report
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> processing please wait...</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  </template>
   <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      reportName: '',
      snackbar: false,
      dialog: false,
      search: null,
      items: [],
      headers: [ { text: 'Report Name', value: 'name' },
        { text: '5 Stars', value: '5_stars' },
        { text: '4 Stars', value: '4_stars' },
        { text: '3 Stars', value: '3_stars' },
        { text: '2 Stars', value: '2_stars' },
        { text: '1 Star', value: '1_star' },
        { text: '0 Stars', value: '0_stars' }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'Network error, cannot load the report, please check your network.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/rating/findAllReportRatings')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    loadReport (item) {
      this.reportName = item.type
      var userId = localStorage.getItem('kwkk')
      var reportId = item.id
      window.axios.post('/viewership/insert', { userId: userId, reportId: reportId })
        .then((response) => {
          const viewershipId = response.data.insertId
          localStorage.setItem('viewershipId', viewershipId)
          if (item.type === 'Power Bi') {
            this.dialog = true
            const reportId = item.report_id
            const datasetId = item.dataset_id
            const embedUrl = item.embed_url
            window.axios.post('/viewership/getEmbedToken', { reportId: reportId, datasetId: datasetId })
              .then(async (response) => {
                const token = response.data.token
                this.dialog = false
                const passphrase = process.env.VUE_APP_PASSPHRASE
                const encryptedToken = this.CryptoJS.AES.encrypt(token, passphrase).toString()
                const encryptedReportId = this.CryptoJS.AES.encrypt(reportId, passphrase).toString()
                const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
                const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
                const encryptedDatasetId = this.CryptoJS.AES.encrypt(datasetId, passphrase).toString()
                localStorage.setItem('datasetId', encryptedDatasetId)
                localStorage.setItem('name', encryptedName)
                localStorage.setItem('tken', encryptedToken)
                localStorage.setItem('reportId', encryptedReportId)
                localStorage.setItem('embUrl', encryptedEmbedUrl)
                this.$router.push('userPowerBiVisual')
              })
              // eslint-disable-next-line handle-callback-err
              .catch(() => {
                this.snackbar = true
                this.dialog = false
              })
          } else if (item.type === 'Web Embed' || item.type === 'Dash') {
            this.dialog = true
            this.dialog = false
            const embedUrl = item.embed_url
            const passphrase = process.env.VUE_APP_PASSPHRASE
            const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
            const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
            localStorage.setItem('name', encryptedName)
            localStorage.setItem('embUrl', encryptedEmbedUrl)
            this.$router.push('userSeeWebMap')
          } else {
            // this.$router.push('userJavaScriptVisual')
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
