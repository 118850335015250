<template>
  <v-container
    id="abstract"
    fluid
    class="fill-height"
   >
       <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
          <v-card tile class="elevation-8">
          <v-stepper v-model="el">
            <v-stepper-header>
              <v-stepper-step
                color="#339966"
                :complete="el>1"
                step="1" >
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="#339966"
                :complete="el>4"
                step="2" >
              </v-stepper-step>
            </v-stepper-header>
            <v-flex>
            <v-progress-linear
              color="#009933"
              height="4"
              indeterminate
              :active="loading"
            />
            </v-flex>
            <v-stepper-items>
              <v-stepper-content step="1">
                  <v-card tile flat>
                  <v-card-title class="layout justify-center pt-4 pb-1 mt-0" >
                    <h1 class="text--center" style="color: #339966; font-size: 0.98em;"> User Activation </h1>
                  </v-card-title>
                  <v-form>
                  <v-layout row justify-center align-center>
                  <v-icon
                    id="icon"
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                  >
                    mdi-account-tie
                  </v-icon>
                  <v-card-text class="pb-6 pt-4 text-center"><v-icon color="red">mdi-account-lock</v-icon>  {{fullname}} account</v-card-text>
                  </v-layout>
                  <v-flex>
                    <v-card-actions id="btn-group" class="pb-6" width="100%">
                      <v-btn  @click="status = 'Active'; activation();" tile class="px-8 text-none"  color="#339966" dark style="font-size: 0.88em;"> Activate </v-btn>
                      <v-btn @click="status = 'Blocked'; activation();" tile class="px-8 text-none"  color="#339966" dark style="font-size: 0.88em;"> Block </v-btn>
                    </v-card-actions>
                  </v-flex>
                  </v-form>
               </v-card>
              </v-stepper-content>
               <v-stepper-content step="2">
                <v-card
                  tile
                  flat
                >
                  <v-card-title class="layout justify-center pt-4 pb-4 mt-0" >
                    <h1 class="text--center" style="color: #339966; font-size: 0.98em;"> Successful Activation  </h1>
                  </v-card-title>
                  <v-form>
                    <v-layout row justify-center align-center>
                      <v-icon
                        id="icon"
                        color="#339966"
                        size="64"
                        transition="scale-transition"
                        @click="deleteItem(item)"
                      >
                        mdi-sticker-check
                      </v-icon>
                    </v-layout>
                    <v-flex>
                      <v-card-actions id="btn-group" class="pt-10" width="100%">
                        <v-btn @click="finish" tile class="px-8 text-none"  color="#339966" dark style="font-size: 0.88em;"> Finish </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </v-form>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          </v-card>
          </v-col>
            <v-snackbar
              id="snack"
              color="#339966"
              v-model="snackbar"
              class="mb-6"
            >
            <v-layout class="pa-4">
              <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
              <v-spacer/>
            </v-layout>
            <template>
            </template>
          </v-snackbar>
       </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      fullname: '',
      status: '',
      userId: '',
      disable: false,
      code: null,
      snackbar: false,
      email: null,
      enteredResetCode: null,
      newPassword: null,
      el: 1,
      loading: false,
      emailEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty email!' },
      passwordEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty password!' },
      activationFailed: { icon: 'mdi-server-security', label: 'The acctivation process failed, please try again!' },
      codeEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty reset code!' },
      emailError: { icon: 'mdi-server-security', label: 'You have submitted an invalid email!' },
      emailNotAvailable: { icon: 'mdi-server-security', label: 'The email submitted is not available in our system!' },
      networkError: { icon: 'mdi-server-network-off', label: 'Cannot connect to the server.' },
      sendingEmailError: { icon: 'mdi-server-network-off', label: 'Failed to send the reset code email, please try again.' },
      systemError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      wrongCodeError: { icon: 'mdi-server-security', label: 'Wrong reset code, please verify your reset !' }
    }
  },
  mounted: [
    function () {
      this.userId = this.$route.query.id
      window.axios.post('/user/find', { id: this.userId })
        .then((response) => {
          // eslint-disable-next-line
          const user = response.data[0]
          const firstname = user.firstname
          const surname = user.surname
          this.fullname = ' ' + firstname + ' ' + surname
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    activation () {
      this.disable = true
      this.loading = true
      window.axios.post('/user/updateStatus', { status: this.status, id: this.userId })
        .then((response) => {
          // eslint-disable-next-line
          this.loading = false
          this.disable = false
          var affectedRows = response.data.affectedRows
          if (affectedRows === 1) {
            this.el = 2
          } else {
            this.snackbar = true
            this.snackbarError = this.activationFailed
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.disable = false
          this.loading = false
          this.snackbarError = this.networkError
          this.snackbar = true
        })
    },
    finish () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
#abstract{
  background: url("../assets/abstract.svg");
  background-repeat: no-repeat;
}
#icon{
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  align-items: center;
}
#btn-group{
  justify-content: center;
}
#forgot-text{
  text-decoration: underline;
}
a:link, a:visited {
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
a:hover, a:active {
  color: #339966;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
