<template>
  <v-container
    id="abstract"
    fluid
    class="fill-height">
       <v-row
          align="center"
          justify="center">
          <v-col
            cols="12"
            xs="8"
            sm="8"
            md="6"
            lg="4"
            xl="3">
          <v-card tile class="elevation-8 mx-8">
          <v-stepper v-model="el">
            <v-stepper-header>
              <v-stepper-step
                color="#339966"
                :complete="el>1"
                step="1" >
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="#339966"
                :complete="el>2"
                step="2" >
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="#339966"
                :complete="el>3"
                step="3" >
              </v-stepper-step>
              <v-divider></v-divider>
            </v-stepper-header>
            <v-flex>
            <v-progress-linear
              color="#009933"
              height="4"
              indeterminate
              :active="loading"/>
            </v-flex>
            <v-stepper-items>
              <v-stepper-content step="1">
                  <v-card tile flat>
                  <v-card-title class="layout justify-center pt-4 pb-0 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> REGISTRATION </h1>
                  </v-card-title>
                  <v-form>
                  <v-layout row justify-center align-center>
                  <v-icon
                    id="icon"
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                    class="mb-2">
                    mdi-charity
                  </v-icon>
                  </v-layout>
                  <v-text-field
                    class="pt-4"
                    v-model="firstname"
                    color="#006622"
                    filled
                    dense
                    label="Firstname"
                    placeholder="Enter firstname"
                    name="Firstname"
                    prepend-icon="mdi-account-box-outline"
                    type="text"
                    :disabled="firstDisable"
                  ></v-text-field>
                  <v-text-field
                    v-model="surname"
                    color="#006622"
                    filled
                    dense
                    label="Surname"
                    placeholder="Enter surname"
                    name="Surname"
                    prepend-icon="mdi-account-box-multiple-outline"
                    type="text"
                    :disabled="firstDisable"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    color="#006622"
                    filled
                    dense
                    label="Email"
                    placeholder="Enter work email"
                    name="Email"
                    prepend-icon="mdi-email-multiple-outline"
                    type="text"
                    :disabled="firstDisable"
                  ></v-text-field>
                  <v-flex>
                    <v-card-actions id="btn-group" class="pb-6" width="100%">
                      <v-btn @click="loadLoginPage" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Back </v-btn>
                      <v-btn @click="firstStep" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Next </v-btn>
                    </v-card-actions>
                  </v-flex>
                  </v-form>
               </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
               <v-card tile flat>
                  <v-card-title class="layout justify-center pt-4 pb-0 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> REGISTRATION </h1>
                  </v-card-title>
                  <v-form>
                  <v-layout row justify-center align-center>
                  <v-icon
                    id="icon"
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                    class="mb-4">
                    mdi-charity
                  </v-icon>
                  </v-layout>
                  <v-Autocomplete
                    v-if="showList"
                    label="HIS Partner"
                    color="#339966"
                    prepend-icon="mdi-strategy"
                    :items="userTypeItems"
                    :item-text="item=>item.name"
                    return-object
                    @change="selectedUserType "/>
                  <v-text-field
                    v-model="phone"
                    color="#006622"
                    filled
                    dense
                    label="Phone Number"
                    placeholder="Enter phone number"
                    name="Phone"
                    prepend-icon="mdi-deskphone"
                    type="number"
                    :rules="[rules.required,rules.phoneMin]"
                    min="1"
                    max="10"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    color="#006622"
                    filled
                    dense
                    label="Password"
                    placeholder="Enter password"
                    name="Password"
                    :rules="[rules.required,rules.min]"
                    prepend-icon="mdi-form-textbox-lock"
                    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                  ></v-text-field>
                  <v-flex>
                    <v-card-actions id="btn-group" class="pb-6" width="100%">
                      <v-btn @click="el = 1" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Back </v-btn>
                      <v-btn @click="register" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Submit </v-btn>
                    </v-card-actions>
                  </v-flex>
                  </v-form>
               </v-card>
              </v-stepper-content>
               <v-stepper-content step="3">
                <v-card
                  tile
                  flat>
                  <v-card-title class="layout justify-center pt-4 pb-4 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> REGISTRATION </h1>
                  </v-card-title>
                  <v-form>
                     <v-layout row justify-center align-center>
                  <v-icon
                    id="icon"
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                    @click="deleteItem(item)"
                    class="mb-4">
                    mdi-charity
                  </v-icon>
                  </v-layout>
                    <v-card-text class="text-center" style=" font-size: 1.04em; font: medium;">
                      You have been successfully registered, an email will be sent to you once your account has been activated.
                    </v-card-text>
                    <v-flex>
                      <v-card-actions id="btn-group" class="pb-6" width="100%">
                        <v-btn @click="loadLoginPage" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Finish </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </v-form>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          </v-card>
          </v-col>
            <v-snackbar
              id="snack"
              color="#339966"
              v-model="snackbar"
              right
              style="position: fixed; "
              class="mb-6 ma-19">
            <v-layout class="pa-4">
              <span name="app" style=" font-size: 0.88em;" dark><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
              <v-spacer/>
            </v-layout>
            <template>
            </template>
          </v-snackbar>
       </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      showList: false,
      firstDisable: false,
      userTypeItems: [],
      snackbar: false,
      show: false,
      firstname: '',
      surname: '',
      email: '',
      phone: '',
      password: '',
      el: 1,
      loading: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        phoneMin: v => v.length >= 10 || 'Min 10 characters',
        emailMatch: () => ('Email and password do not match')
      },
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      // eslint-disable-next-line no-useless-escape
      emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    selectedUserType (item) {
      this.selectedUserTypeId = item.id
    },
    loadLoginPage () {
      this.$router.push('/')
    },
    firstStep () {
      this.snackbar = false
      if (this.firstname === null || this.firstname.trim() === '') {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'Fill in your firstname to continue to the next step.' }
        this.snackbar = true
      } else if (this.surname === null || this.surname.trim() === '') {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'Fill in your surname to continue to the next step.' }
        this.snackbar = true
      } else if (this.email === null || this.email.trim() === '') {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'Fill in your email to continue to the next step.' }
        this.snackbar = true
      } else if (!this.emailRegex.test(this.email)) {
        this.snackbarError = { icon: 'mdi-server-security', label: 'Invalid email address.' }
        this.snackbar = true
        this.loading = false
      } else {
        // fetch the user type
        this.loading = true
        this.firstDisable = true
        window.axios.post('/user/findEmailPattern', { email: this.email })
          .then((response) => {
            // eslint-disable-next-line
            this.firstDisable = false
            this.loading = false
            this.userTypeItems = response.data
            // if (this.userTypeItems.length === 0) {
            //   this.loading = false
            //   this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter your work email to proceed to the next step.' }
            //   this.snackbar = true
            // } else
            if (this.userTypeItems.length === 1) {
              // hide the v-autocomplete
              this.selectedUserTypeId = response.data[0].id
              this.showList = false
              this.el = 2
            } else if (this.email.includes('yahoo') || this.email.includes('gmail') || this.email.includes('outlook') || this.email.includes('hotmail')) {
              // get all the unexclusive user types
              this.getAllUnexclusiveUsertypes()
              // show the v-autocomplete
              this.el = 2
              this.showList = true
              this.selectedUserTypeId = null
            } else {
              this.loading = false
              this.snackbarError = { icon: 'mdi-server-security', label: 'Please enter your work email to proceed to the next step.' }
              this.snackbar = true
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.firstDisable = false
            this.snackbar = true
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
          })
      }
    },
    getAllUnexclusiveUsertypes () {
      window.axios.get('/userType/findAllNotExclusive')
        .then((response) => {
          // eslint-disable-next-line
          this.userTypeItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    register () {
      this.snackbar = false
      if (this.selectedUserTypeId === null) {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please select your HIS partner user group.' }
        this.snackbar = true
      } else if (this.phone === null || this.phone.trim() === '') {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please, fill in your phone number.' }
        this.snackbar = true
      } else if (this.phone.length < 10) {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'The phone number entered is invalid.' }
        this.snackbar = true
      } else if (this.password.length == null || this.password === null) {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'Please, fill in your password.' }
        this.snackbar = true
      } else if (this.password.length < 8) {
        this.loading = false
        this.snackbarError = { icon: 'mdi-server-security', label: 'The password entered is too short.' }
        this.snackbar = true
      } else {
        this.loading = true
        window.axios.post('/user/insertByUser', { firstname: this.firstname, surname: this.surname, email: this.email, phone: this.phone, password: this.password, userTypeId: this.selectedUserTypeId })
          .then((response) => {
            // eslint-disable-next-line
            var affectedRows = response.data.affectedRows
            if (affectedRows === 1) {
              this.el = 3
            } else {
              var message = response.data.message
              this.snackbarError = { icon: 'mdi-server-security', label: message }
              this.snackbar = true
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
#abstract{
  background-size: cover;
  width: 100%;
  background: url("../assets/abstract.svg");
  background-repeat: no-repeat;
}
#icon{
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  align-items: center;
}
#btn-group{
  justify-content: center;
}
#forgot-text{
  text-decoration: underline;
}
a:link, a:visited {
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
a:hover, a:active {
  color: #339966;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
