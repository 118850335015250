<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-flex>
          <v-progress-linear
            id="progress"
            color="#009933"
            height="4.5"
            indeterminate
            :active="loading"
          />
        </v-flex>
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4" style=" font-size: 0.96em;">
              <span>{{name}}</span>
              <v-icon
                @click="fullscreen"
                style="float: right;"
                color="green"
                class=" pr-7" >
                mdi-presentation
              </v-icon>
              <v-icon
                  @click="() => {recommendationDialog = true}"
                  style="float: right;"
                  color="green"
                  class=" pr-4" >
                    mdi-share-all-outline
                </v-icon>
            </v-list-item-subtitle>
          </v-list-item-content>
          <div id="container">
            <v-card flat tile class="ml-4 mr-4">
            <iframe :src="embedUrl" id="map" scrolling="yes" target="_parent"></iframe>
            </v-card>
            <div style="text-align: center;" class="mt-2">
              <v-btn
                @click="() => {feedbackDialog=!feedbackDialog} "
                color="#339966"
                class="text-none pa-4 mt-2 mb-4 mr-2"
                dark
                tile
                style="font-size: 0.88em;">
                Submit Feedback
              </v-btn>
              <v-btn
                @click="() => {recommendationDialog = true}"
                color="#339966"
                class="text-none pa-4 mt-2 mb-4 mr-2"
                dark
                tile
                style="font-size: 0.88em;">
                Recommend to Others
              </v-btn>
              <v-btn
                @click="() => {ratingDialog=!ratingDialog} "
                color="#339966"
                class="text-none pa-4 mt-2 mb-4"
                dark
                tile
                style="font-size: 0.88em;">
                Rate the Visual
              </v-btn>
              <v-btn
                color="#339966"
                class="text-none pa-4 mt-2 mb-4 ml-2"
                dark
                tile
                style="font-size: 0.88em;"
                @click="fullscreen">
                Enter fullscreen mode
              </v-btn>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-flex>
    <!-- the feedback pop up dialog -->
    <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="feedbackDialog"
          max-width="520"
        >
          <v-card tile>
            <v-progress-linear
              id="progress"
              color="green darken-3"
              height="4.5"
              indeterminate
              :active="feedbackLoader"
              striped
            />
            <v-card-title class="subtitle-2">
              Provide Feedback
            </v-card-title>
            <v-card-actions class="mt-2">
              <v-textarea
                dense
                outlined
                class="px-2"
                v-model="feedback"
                color="green darken-2"
                label="Feedback"
                hint="Rate the Report's Appearance, Disaggregation, Usefulness, & Make any Suggestions"
                name="Feedback"
                type="text"
                clearable
                @keyup.enter='signin'
              />
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                style="font-size: 0.90em;"
                color="green darken-1 text-none"
                @click="submitFeedback"
              >
                Submit
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
     <!-- the recommendtation pop up dialog -->
     <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="recommendationDialog"
          max-width="720"
        >
          <v-card tile>
            <v-progress-linear
              id="progress"
              color="green darken-3"
              height="4.5"
              indeterminate
              :active="feedbackLoader"
              striped
            />
            <v-card-title class="subtitle-2">
              Recommend Visual
            </v-card-title>
            <v-card-actions class="mt-2">
              <v-col
                cols="12"
                sm="12"
                md="12">
              <v-autocomplete
                v-model="userIds"
                item-value="id"
                :items="items"
                label="Recommend To"
                color="#006622"
                filled
                :item-text="item=>item.firstname + ' ' + item.surname + ' - ' + item.group_name"
                required
                dense
                multiple
                prepend-icon="mdi-account-group-outline"
              />
              <v-textarea
                dense
                outlined
                class=""
                v-model="message"
                color="green darken-2"
                label="Message"
                hint="The Reason for making this recommendation."
                name="Recommendation"
                type="text"
                clearable
                @keyup.enter='signin'
                prepend-icon="mdi-message-text-outline"
              />
              </v-col>
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                style="font-size: 0.90em;"
                color="green darken-1 text-none"
                @click="submitRecommendation()"
              >
                Submit
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
     <!-- the rating pop up dialog -->
     <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="ratingDialog"
          max-width="520"
        >
          <v-card tile>
            <v-progress-linear
              id="progress"
              color="green darken-3"
              height="4.5"
              indeterminate
              :active="feedbackLoader"
              striped
            />
            <v-card-title class="subtitle-2">
              Rate The Visual
            </v-card-title>
            <div class="text-center mb-2">
              <v-rating
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                hover
                size="26"
              ></v-rating>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                text
                style="font-size: 0.90em;"
                color="green darken-1 text-none "
                @click="submitRating"
              >
                Submit
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
     </template>
     <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
   <v-snackbar
        id="snack"
        color="#339966"
        timeout="-1"
        v-model="ratingSnackbar"
        right
        closable
        style="position: fixed; "
        class="mb-6 ma-19"
      >
        <span
          style=" font-size: 0.82em;"
        >
          Would You Like to Rate this Analytics Visual?
        </span>
        <v-layout  class="mt-2">
          <v-spacer/>
          <v-chip
            style=" font-size: 0.82em;"
            class="mr-2"
            @click="ratingSnackbar=!ratingSnackbar"
          >
            <v-icon
              class="mr-1"
            >
              mdi-star-remove
            </v-icon>
              No Thanks
          </v-chip>
          <v-chip
            style=" font-size: 0.82em;"
            class=" ml-2"
            @click="ratingSnackbar=!ratingSnackbar; feedbackDialog=!feedbackDialog;"
          >
            <v-icon
              class="mr-1"
            >
              mdi-star-shooting
            </v-icon>
            Yes Please
          </v-chip>
          <v-spacer/>
        </v-layout>
      </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      fullscreenPattern: [],
      feedback: '',
      rating: 0,
      items: [],
      userIds: null,
      message: '',
      name: '',
      embedUrl: '',
      reportId: '',
      ratingSnackbar: false,
      recommendationDialog: false,
      feedbackLoader: false,
      loading: true,
      feedbackDialog: false,
      ratingDialog: false,
      hasFeedback: false,
      snackbar: false,
      search: null,
      headers: [
        { text: 'Report Name', value: 'name' },
        { text: 'Date Uploaded', value: 'date_uploaded' },
        { text: 'Report Type', value: 'type' },
        { text: 'Report Id', value: 'report_id' }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'Network error, cannot load the report, please check your network.' }
    }
  },
  mounted: [
    function () {
      setTimeout(() => {
        if (!this.hasFeedback) {
          this.ratingSnackbar = true
        }
      }, 1000 * 60 * 2)
    },
    async function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)

      const userId = localStorage.getItem('kwkk')

      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      // here decrypt the data
      const encryptedName = localStorage.getItem('name')
      this.name = await this.CryptoJS.AES.decrypt(encryptedName, passphrase).toString(this.CryptoJS.enc.Utf8)
      // Read embed URL from textbox
      var encryptedEmbedUrl = localStorage.getItem('embUrl')
      this.embedUrl = await this.CryptoJS.AES.decrypt(encryptedEmbedUrl, passphrase).toString(this.CryptoJS.enc.Utf8)
      // removing the loader if the iframe has been w
      var mapIframe = document.getElementById('map')
      mapIframe.onload = function () {
        this.loading = false
        parent.document.getElementById('progress').style.visibility = 'hidden'
      }
      // check if the feedback is already available
      this.feedbackCheck(this.embedUrl, userId)
    },
    // listen for full screen key presses
    function () {
      document.addEventListener('keydown', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        // and only add the key if its not available
        const keyCheck = this.fullscreenPattern.some((data) => {
          return data === event.key
        })
        var patternSize = this.fullscreenPattern.length

        if (patternSize <= 3 && !keyCheck && (event.key === 'Control' || event.key === 'Shift' || event.key === 'F')) {
          this.fullscreenPattern.push(event.key)
          // check if the Ctrl, Shift & F pattern was pressed
          // console.log('added ---->', event.key)
          patternSize = this.fullscreenPattern.length
          if (patternSize === 3 && this.fullscreenPattern[0] === 'Control' && this.fullscreenPattern[1] === 'Shift' && this.fullscreenPattern[2] === 'F') {
            this.fullscreen()
          }
        }
        // console.log(this.fullscreenPattern)
      })

      document.addEventListener('keyup', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        if (event.key === 'Control' || event.key === 'Shift' || event.key === 'F') {
          // remove the key from the pattern
          const size = this.fullscreenPattern.length

          for (let i = 0; i < size; i++) {
            const key = this.fullscreenPattern[i]

            // if it there remove it
            if (event.key === key) {
              this.fullscreenPattern.splice(i, 1)
              break
            }
          }
        }
      })
    }
  ],
  methods: {
    submitRecommendation () {
      if (this.message !== '' && this.message !== null) {
        this.recommendationLoader = true
        var userId = localStorage.getItem('kwkk')
        window.axios.post('/suggestion/insert', {
          reportId: this.reportId,
          suggestedBy: userId,
          usersSuggested: this.userIds,
          message: this.message
        })
          .then((response) => {
            // getting the response from the server
            this.recommendationDialog = false
            this.recommendationLoader = false
            this.snackbarError = { icon: 'mdi-server-network', label: 'Your recommendation has been submitted.' }
            this.snackbar = true
          })
          .catch(() => {
            // dismiss with an error
            this.recommendationDialog = false
            this.recommendationLoader = false
            this.snackbarError = { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
            this.snackbar = true
          })
      } else {
        // dismiss with an error
        this.recommendationDialog = false
        this.recommendationLoader = false
        this.snackbarError = { icon: 'mdi-server-network-off', label: 'You cannot submit an empty feedback...' }
        this.snackbar = true
      }
    },
    feedbackCheck (reportId, userId) {
      window.axios.get('/feedback/exists', { params: { reportId: reportId, userId: userId } })
        .then((response) => {
          if (response.data.length !== 0) {
            this.hasFeedback = true
          }
        })
        .catch(() => {
        }
        )
    },
    submitFeedback () {
      if (this.feedback !== '' && this.feedback !== null) {
        this.feedbackLoader = true
        var userId = localStorage.getItem('kwkk')
        window.axios.post('/feedback/insert', { reportIdOrUrl: this.embedUrl, userId: userId, star: this.rating, feedback: this.feedback })
          .then((response) => {
            // getting the response from the server
            this.feedbackDialog = false
            this.feedbackLoader = false
            this.snackbarError = { icon: 'mdi-server-network', label: 'Your feedback has been submitted.' }
            this.snackbar = true
          })
          .catch(() => {
            // dismiss with an error
            this.feedbackDialog = false
            this.feedbackLoader = false
            this.snackbarError = { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
            this.snackbar = true
          })
      } else {
        // dismiss with an error
        this.feedbackDialog = false
        this.feedbackLoader = false
        this.snackbarError = { icon: 'mdi-server-network-off', label: 'You cannot submit an empty feedback...' }
        this.snackbar = true
      }
    },
    submitRating () {
      this.feedbackLoader = true
      var userId = localStorage.getItem('kwkk')
      window.axios.post('/rating/insert', { reportIdOrUrl: this.embedUrl, userId: userId, star: this.rating })
        .then((response) => {
          // getting the response from the server
          this.ratingDialog = false
          this.feedbackLoader = false
          this.snackbarError = { icon: 'mdi-server-network', label: 'Your feedback has been submitted.' }
          this.snackbar = true
        })
        .catch(() => {
          // dismiss with an error
          this.dialog = false
          this.feedbackLoader = false
          this.snackbarError = { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
          this.snackbar = true
        })
    },
    fullscreen () {
      // check if fullscreen mode is available
      if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled) {
        // which element will be fullscreen
        var iframe = document.querySelector('#container iframe')
        // Do fullscreen
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen()
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen()
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen()
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen()
        }
      } else {
        document.querySelector('.error').innerHTML = 'Your browser is not supported'
      }
    },
    fullscreenChange () {
      if (document.fullscreenEnabled ||
          document.webkitIsFullScreen ||
          document.mozFullScreen ||
          document.msFullscreenElement) {
      } else {
      }
      var iframe = document.querySelector('iframe')
      var iframe2 = document.querySelector('iframe')
      iframe.src = iframe2.src
    }
  }
}
</script>
<style scoped>
.frame-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 100%;
}
#container {
  text-align: center;
  height: 100%;
  width: 100%;
}
iframe {
  width: 100%;
  height: 76vh;
}
.v-text-field >>> input {
    font-size: 0.84em;
}

.v-text-field >>> label {
    font-size: 0.84em;
}
</style>
