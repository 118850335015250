import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    kwkk: '',
    utp: '',
    partners: []
  },
  mutations: {
    // this is synchronous
    setPartners (state, payload) {
      state.partners.push(payload)
    },

    // setting the user id
    setKwkk (state, payload) {
      state.kwkk = payload
    },

    // setting the user id
    setUtp (state, payload) {
      state.utp = payload
    }
  },
  actions: {
    // this is asynchronous
  },
  modules: {
  },
  getters: {
    // getting the user id
    getKwkk: (state) => state.kwkk,
    getUtp: (state) => state.utp,
    getPartners: (state) => state.partners
  }
})
