<template>
<v-layout row wrap class="px-2 pb-2">
    <v-flex v-for="(item, i) in headerItems" :key="item.title" xs12 md4 pl-5 pr-5 pt-5>
      <v-card
        tile
        outlined
        id="header-size"
        class="mx-auto elevation-1 mb-2"
        :ripple="{ class: 'white--text', center: true }">
      <v-list dense>
        <v-list-item-content>
          <v-icon :color="colors[i]" size="36"  class="pr-2">{{icons[i]}}</v-icon>
          <v-list-item-subtitle class="text-center text-size-title font-bold mt-2" >
            {{item.name}}
          </v-list-item-subtitle>
          <v-list-item class="pb-0 mb-0 dense">
            <v-divider class=""></v-divider>
          </v-list-item>
          <v-list-item-subtitle class="text-left pl-6 font-bold text-size mt-2">
            File Description
          </v-list-item-subtitle>
          <v-list-item class="pb-0 mb-0 mt-0 px-2 dense">
            <v-card-subtitle v-if="item.description == null || item.description.trim() == '' || item.description.trim().length == 0" style="font-size: 0.76rem;">The category description is not available.</v-card-subtitle>
            <v-card-subtitle v-else style="font-size: 0.76rem;">{{item.description}}</v-card-subtitle>
          </v-list-item>
          <v-list-item-content style="position: absolute; bottom: 16px; width:100%;">
            <v-list-item class="pb-0 mb-0 dense" >
              <v-divider class="" style="background: white;" ></v-divider>
            </v-list-item>
            <v-list-item class="pb-0 mb-0 dense" >
              <a @click="download()" style="text-decoration: none;" download><v-chip color="#339966" dark class="ml-4" style="font-size: 0.72rem;" label>
                <v-icon size="18"  class="pr-2">{{icons[i]}}</v-icon>
                  Download the {{item.name}}
                </v-chip>
              </a>
            </v-list-item>
          </v-list-item-content>
        </v-list-item-content>
      </v-list>
      </v-card>
    </v-flex>
 </v-layout>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  props: {
    source: String
  },
  computed: {
    ...mapState([
      'partners'
    ])
  },
  data: () => {
    return {
      icons: ['mdi-microsoft-word', 'mdi-file-cabinet', 'mdi-city', 'mdi-chart-tree', 'mdi-microscope', 'mdi-chart-multiple', 'mdi-chart-scatter-plot', 'mdi-chart-line'],
      colors: ['#44a08d', '#e73827', '#f8b500', '#c5796d', '#e4a36c', '#e58494', '#8bb8a8', '#e5cd9b'],
      oneCard: false,
      twoCards: false,
      moreThanTwoCards: false,
      headerItems: []
    }
  },
  mounted: [
    function () {
      var download = {
        name: 'Data Request Form',
        description: 'Click the button Below to download the MOH-DHA data access request form word document.',
        link: ''
      }
      this.headerItems.push(download)
      // getting the reports that the user can view
      // window.axios.get('reportCategory/findReportCount')
      //   .then((response) => {
      //     var dataSize = response.data.length
      //     this.headerItems = response.data
      //     if (dataSize === 1) {
      //       this.oneCard = true
      //     } else if (dataSize === 2) {
      //       this.twoCards = true
      //     } else {
      //       this.moreThanTwoCards = true
      //     }
      //     // use these categories to get their views and the number of HIS partners viewing them
      //   }).catch(() => {
      //     return 'unavailable'
      //   })
    }
  ],
  methods: {
    cardClick (item) {
      if (item.count !== 0) {
        localStorage.setItem('cData', item.name)
        var route = '/userCardReports'
        this.$router.push(route).catch(failure => {
          window.location.reload()
        })
      }
    },
    getReportDetails (name) {
    },
    download () {
      const url = 'https://analytics.hismalawi.org/map-back-end/document/download'
      const filename = 'MOH-DHA-Data-Access-Request-Form.docx'
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.post(url, { filename: filename }, { responseType: 'blob' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', filename)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          // console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.text-size {
  font-size: 0.92em;
  font-weight: 420;
  color: #595959;
}

.text-size-title {
  font-size: 0.94em;
  font-weight: 430;
  color: #595959;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1600px) {
  #header-size{
    height: 360px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  #header-size{
    height: 360px;
  }
}
</style>
