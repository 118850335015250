<template>
  <v-container
    id="abstract"
    fluid
    class="fill-height"
   >
       <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            xs="8"
            sm="8"
            md="6"
            lg="4"
            xl="3"
          >
          <v-card tile class="elevation-8 mx-8">
          <v-stepper v-model="el">
            <v-stepper-header>
              <v-stepper-step
                color="#339966"
                :complete="el>1"
                step="1" >
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="#339966"
                :complete="el>2"
                step="2" >
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="#339966"
                :complete="el>3"
                step="3" >
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="#339966"
                :complete="el>4"
                step="4" >
              </v-stepper-step>
            </v-stepper-header>
            <v-flex>
            <v-progress-linear
              color="#009933"
              height="4"
              indeterminate
              :active="loading"
            />
            </v-flex>
            <v-stepper-items>
              <v-stepper-content step="1">
                  <v-card tile flat>
                  <v-card-title class="layout justify-center pt-2 pb-4 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> FORGOT PASSWORD </h1>
                  </v-card-title>
                  <v-form>
                  <v-layout row justify-center align-center>
                  <v-icon
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                    class="icon"
                  >
                    mdi-lock-reset
                  </v-icon>
                  </v-layout>
                  <v-text-field
                    class="pt-4"
                    v-model="email"
                    color="#006622"
                    filled
                    dense
                    label="Email"
                    placeholder="Enter email"
                    name="Email"
                    prepend-icon="mdi-email-open-multiple-outline"
                    type="text"
                    :disabled="disable"
                  ></v-text-field>
                  <v-flex>
                    <v-row id="btn-group">
                      <v-card-actions id="btn-group" class="pb-8 pt-4" width="100%">
                        <v-btn @click="loadLoginPage" class="px-10 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Back </v-btn>
                        <v-btn @click="emailSubmission" class="px-10 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Next </v-btn>
                      </v-card-actions>
                    </v-row>
                  </v-flex>
                  </v-form>
               </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card
                  tile
                  flat
                >
                 <v-card-title class="layout justify-center pt-4 pb-4 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> RESET CODE </h1>
                  </v-card-title>
                  <v-form>
                  <v-layout row justify-center align-center>
                  <v-icon
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                    class="mb-4 icon"
                  >
                    mdi-lock-reset
                  </v-icon>
                  </v-layout>
                    <v-text-field
                      class="pt-2"
                      v-model="enteredResetCode"
                      color="#006622"
                      filled
                      dense
                      label="Reset Code"
                      name="Reset Code"
                      placeholder="Enter reset code sent to your email"
                      prepend-icon="mdi-arch"
                      :disabled="disable"
                      type="text"
                    ></v-text-field>
                    <v-flex>
                      <v-row id="btn-group">
                        <v-card-actions id="btn-group" class="pb-8 pt-4" width="100%">
                          <v-btn @click="el = 1" class="px-10 text-none" tile color="#339966" dark style="font-size: 0.88em;"> Back </v-btn>
                          <v-btn @click="checkResetCode" class="px-10 text-none" tile color="#339966" dark style="font-size: 0.88em;"> Next </v-btn>
                        </v-card-actions>
                      </v-row>
                    </v-flex>
                  </v-form>
                </v-card>
              </v-stepper-content>
               <v-stepper-content step="3">
                <v-card
                  tile
                  flat
                >
                  <v-card-title class="layout justify-center pt-4 pb-4 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> RESET PASSWORD </h1>
                  </v-card-title>
                  <v-form>
                     <v-layout row justify-center align-center>
                  <v-icon
                    color="#339966"
                    size="64"
                    transition="scale-transition"
                    @click="deleteItem(item)"
                    class="mb-4 icon"
                  >
                    mdi-form-textbox-password
                  </v-icon>
                  </v-layout>
                    <v-text-field
                      class="pt-2"
                      v-model="newPassword"
                      color="#006622"
                      filled
                      dense
                      label="New Password"
                      placeholder="Enter new password"
                      name="New Password"
                      prepend-icon="mdi-form-textbox"
                      type="password"
                      :disabled="disable"
                    ></v-text-field>
                    <v-flex>
                      <v-card-actions id="btn-group" class="pb-8 pt-4" width="100%">
                        <v-btn @click="resetPassword" class="px-12 text-none" tile color="#339966" dark style="font-size: 0.88em;"> Submit </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </v-form>
                </v-card>
              </v-stepper-content>
               <v-stepper-content step="4">
                <v-card
                  tile
                  flat
                >
                  <v-card-title class="layout justify-center pt-4 pb-4 mt-0" >
                    <h1 class="text--center font-weight-bold" style="color:#339966; font-size:0.88em;"> SUCCESSFUL RESET </h1>
                  </v-card-title>
                  <v-form>
                    <v-layout row justify-center align-center>
                      <v-icon
                        color="#339966"
                        size="64"
                        transition="scale-transition"
                        @click="deleteItem(item)"
                        class="icon"
                      >
                        mdi-sticker-check
                      </v-icon>
                    </v-layout>
                    <v-flex>
                      <v-card-actions id="btn-group" class="pt-10 pb-8" width="100%">
                        <v-btn @click="finish" class="px-12 text-none" color="#339966" tile dark style="font-size: 0.88em;"> Finish </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </v-form>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          </v-card>
          </v-col>
            <v-snackbar
              id="snack"
              color="#339966"
              v-model="snackbar"
              class="mb-6"
            >
            <v-layout class="pa-2">
              <span name="app" style=" font-size: 0.88em;" dark><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
              <v-spacer/>
            </v-layout>
            <template>
            </template>
          </v-snackbar>
       </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      disable: false,
      userId: null,
      code: null,
      snackbar: false,
      email: null,
      enteredResetCode: null,
      newPassword: null,
      el: 1,
      loading: false,
      emailEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty email!' },
      passwordEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty password!' },
      passwordResetFailed: { icon: 'mdi-server-security', label: 'Pasword reset failed, please try again!' },
      codeEmpty: { icon: 'mdi-email-variant', label: 'You cannot submit an empty reset code!' },
      emailError: { icon: 'mdi-server-security', label: 'You have submitted an invalid email!' },
      emailNotAvailable: { icon: 'mdi-server-security', label: 'The email submitted is not available in our system!' },
      networkError: { icon: 'mdi-server-network-off', label: 'Cannot connect to the server.' },
      sendingEmailError: { icon: 'mdi-server-network-off', label: 'Failed to send the reset code email, please try again.' },
      systemError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      wrongCodeError: { icon: 'mdi-server-security', label: 'Wrong reset code, please verify your reset !' }
    }
  },
  methods: {
    emailSubmission () {
      this.loading = true
      if (this.email == null || this.email.trim() === '') {
        this.loading = false
        this.snackbar = true
        this.snackbarError = this.emailEmpty
      } else {
        this.disable = true
        this.loading = true
        window.axios.post('/user/resetCode', { email: this.email })
          .then((response) => {
            // eslint-disable-next-line
            this.loading = false
            this.userId = response.data.id
            this.code = response.data.code
            this.disable = false
            if (this.code === 0) {
              this.snackbar = true
              this.snackbarError = this.sendingEmailError
            } else if (this.code === 1) {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'The email entered is not available in the system !' }
            } else {
              this.el = 2
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.disable = false
            this.loading = false
            this.snackbarError = this.networkError
            this.snackbar = true
          })
      }
    },
    loadLoginPage () {
      this.$router.push('/')
    },
    checkResetCode () {
      this.loading = true
      if (this.enteredResetCode == null || this.enteredResetCode.trim() === '') {
        this.loading = false
        this.snackbarError = this.codeEmpty
        this.snackbar = true
      } else if (this.code !== this.enteredResetCode.trim()) {
        this.loading = false
        this.snackbarError = this.wrongCodeError
        this.snackbar = true
      } else {
        this.loading = false
        this.el = 3
      }
    },
    resetPassword () {
      this.disable = true
      this.loading = true
      if (this.newPassword == null || this.newPassword.trim() === '') {
        this.loading = false
        this.snackbar = true
        this.snackbarError = this.passwordEmpty
      } else {
        this.loading = true
        window.axios.post('/user/resetPassword', { password: this.newPassword, id: this.userId })
          .then((response) => {
            // eslint-disable-next-line
            this.loading = false
            this.disable = false
            var affectedRows = response.data.affectedRows
            if (affectedRows === 1) {
              this.el = 4
            } else {
              this.snackbar = true
              this.snackbarError = this.passwordResetFailed
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.disable = false
            this.loading = false
            this.snackbarError = this.networkError
            this.snackbar = true
          })
      }
    },
    finish () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
#abstract{
  background: url("../assets/abstract.svg");
  background-repeat: no-repeat;
}
.icon{
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  align-items: center;
}
#btn-group{
  justify-content: center;
}
#forgot-text{
  text-decoration: underline;
}
a:link, a:visited {
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
a:hover, a:active {
  color: #339966;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
