<template>
<v-layout row wrap>
    <v-flex v-for="(item, i) in headerItems" :key="item.title" xs12 :md6="twoCards" :md3="moreThanTwoCards"   pl-5 pr-5 pt-5>
      <v-card
        tile
        id="header-size"
        @click="cardClick(item)"
        class="mx-auto elevation-2"
        :ripple="{ class: 'white--text', center: true }">
      <v-progress-linear
        :color="colors[i]"
        height="4"
        value="100"
        />
      <v-list dense>
        <v-list-item-content>
          <v-list-item-subtitle class="pl-4 text-left font-bold text-size">
            {{item.name}} Reports
          </v-list-item-subtitle>
          <v-list-item class="pb-0 mb-0">
            <v-icon :color="colors[i]" size="30"  class="pr-2">{{icons[i]}}</v-icon>
            <v-list-item-subtitle v-if="item.count==1" class="" style="font-size: 0.76rem;" >{{item.count}} Report</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.count>1" style="font-size: 0.76rem;">{{item.count}} Reports</v-list-item-subtitle>
          </v-list-item>
        </v-list-item-content>
      </v-list>
      </v-card>
    </v-flex>
 </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      icons: ['mdi-chart-donut-variant', 'mdi-chart-bell-curve', 'mdi-chart-box-plus-outline', 'mdi-chart-timeline-variant-shimmer'],
      colors: ['#44a08d', '#e73827', '#44a04c', '#44a04c'],
      oneCard: false,
      twoCards: false,
      moreThanTwoCards: false,
      headerItems: []
    }
  },
  mounted: [
    function () {
      // getting the reports that the user can view
      const userTypeId = localStorage.getItem('utp')
      const userId = localStorage.getItem('kwkk')
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.post('report/findCardData', { userTypeId: userTypeId, userId: userId })
        .then((response) => {
          var dataSize = response.data.length
          this.headerItems = response.data
          if (dataSize === 1) {
            this.oneCard = true
          } else if (dataSize === 2) {
            this.twoCards = true
          } else {
            this.moreThanTwoCards = true
          }
        }).catch((err) => {
          if (err.response.status === 401 && (err.response.data === 'jwt malformed' || err.response.data === 'empty jwt token')) {
            this.$router.push('/')
          } else {
            // show the dialog that the access token has expired
            this.tokenDialog = true
          }
          return 'unavailable'
        })
    }
  ],
  methods: {
    cardClick (item) {
      localStorage.setItem('cData', item.name)
      var route = '/userCardReports'
      this.$router.push(route).catch(failure => {
        window.location.reload()
      })
    }
  }
}
</script>
<style scoped>
.text-size {
  font-size: 0.92em;
  font-weight: 420;
  color: #595959;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1600px) {
  #header-size{
    height: 100%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  #header-size{
    height: 110px;;
  }
}
</style>
