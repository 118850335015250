<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-flex>
          <v-progress-linear
            id="progress"
            color="#009933"
            height="4.5"
            indeterminate
            :active="loading"/>
        </v-flex>
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4" style=" font-size: 0.96em;">
              <span>{{name}}</span>
              <v-icon
                @click="fullscreen"
                style="float: right;"
                color="green"
                class=" pr-7" >
                mdi-presentation
              </v-icon>
            </v-list-item-subtitle>
          </v-list-item-content>
          <div id="container">
            <v-card tile flat class="ml-4 mr-4">
            <iframe :src="embedUrl" id="map" scrolling="yes" target="_parent"  ></iframe>
            </v-card>
            <div>
              <v-btn
                color="#339966"
                class="text-none pa-4 mt-2 mb-4"
                dark
                tile
                style="font-size: 0.88em;"
                @click="fullscreen">
                Enter fullscreen mode
              </v-btn>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-flex>
        <template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
      max-width="300">
      <v-card tile>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-2">
          Loading the Power Bi Report
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> processing please wait...</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  </template>
     <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      fullscreenPattern: [],
      name: '',
      embedUrl: '',
      loading: true,
      dialog: false,
      snackbar: false,
      search: null,
      items: [],
      headers: [
        { text: 'Report Name', value: 'name' },
        { text: 'Date Uploaded', value: 'date_uploaded' },
        { text: 'Report Type', value: 'type' },
        { text: 'Report Id', value: 'report_id' }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'Network error, cannot load the report, please check your network.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/report/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    async function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      // here decrypt the data
      const encryptedName = localStorage.getItem('name')
      this.name = await this.CryptoJS.AES.decrypt(encryptedName, passphrase).toString(this.CryptoJS.enc.Utf8)
      // Read embed URL from textbox
      var encryptedEmbedUrl = localStorage.getItem('embUrl')
      this.embedUrl = await this.CryptoJS.AES.decrypt(encryptedEmbedUrl, passphrase).toString(this.CryptoJS.enc.Utf8)

      // removing the loader if the iframe has been w
      var mapIframe = document.getElementById('map')
      mapIframe.onload = function () {
        this.loading = false
        parent.document.getElementById('progress').style.visibility = 'hidden'
      }
    },
    // listen for full screen key presses
    function () {
      document.addEventListener('keydown', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        // and only add the key if its not available
        const keyCheck = this.fullscreenPattern.some((data) => {
          return data === event.key
        })
        var patternSize = this.fullscreenPattern.length

        if (patternSize <= 3 && !keyCheck && (event.key === 'Control' || event.key === 'Shift' || event.key === 'F')) {
          this.fullscreenPattern.push(event.key)
          // check if the Ctrl, Shift & F pattern was pressed
          // console.log('added ---->', event.key)
          patternSize = this.fullscreenPattern.length
          if (patternSize === 3 && this.fullscreenPattern[0] === 'Control' && this.fullscreenPattern[1] === 'Shift' && this.fullscreenPattern[2] === 'F') {
            this.fullscreen()
          }
        }
        // console.log(this.fullscreenPattern)
      })

      document.addEventListener('keyup', (event) => {
        // listen for the following keys to go into fullscreen mode
        // Control, Shift & F
        // this.fullscreen()
        if (event.key === 'Control' || event.key === 'Shift' || event.key === 'F') {
          // remove the key from the pattern
          const size = this.fullscreenPattern.length

          for (let i = 0; i < size; i++) {
            const key = this.fullscreenPattern[i]

            // if it there remove it
            if (event.key === key) {
              this.fullscreenPattern.splice(i, 1)
              break
            }
          }
        }
      })
    }
  ],
  methods: {
    fullscreen () {
      // check if fullscreen mode is available
      if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled) {
        // which element will be fullscreen
        var iframe = document.querySelector('#container iframe')
        // Do fullscreen
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen()
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen()
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen()
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen()
        }
      } else {
        document.querySelector('.error').innerHTML = 'Your browser is not supported'
      }
    },
    fullscreenChange () {
      if (document.fullscreenEnabled ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement) {
      } else {
      }
      // force to reload iframe once to prevent the iframe source didn't care about trying to resize the window
      var iframe = document.querySelector('iframe')
      var iframe2 = document.querySelector('iframe')
      iframe.src = iframe2.src
    }
  }
}
</script>
<style scoped>
.frame-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 100%;
}
#container {
  text-align: center;
  height: 100%;
  width: 100%;
}
iframe {
  width: 100%;
  height: 76vh;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
