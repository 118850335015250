<template>
  <div id="">
      <v-app >
        <v-main>
          <login/>
        </v-main>
      </v-app>
  </div>
</template>

<script>
import login from '../components/Login'
export default {
  props: {
    source: String
  },
  components: {
    login
  }
}
</script>
