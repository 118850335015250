<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2 text-left" style=" font-size: 0.98em;">
              Edit Report Category
            </v-list-item-subtitle>
        </v-list>
        <v-form class="px-4 ma-6">
          <v-list dense  class="ma-0 pa-0">
              <v-autocomplete
                class="ma-0 pa-0"
                v-model="id"
                item-value="id"
                :items="item"
                label="Select Report"
                color="#006622"
                dense
                filled
                :item-text="item=>item.name + ', ' + item.category"
                required
                prepend-icon="mdi-tag-heart"
                return-object
                multiple
                chips
              />
          </v-list>
         <v-list dense  class="ma-0 pa-0 pb-8">
              <v-autocomplete
                v-model="type"
                item-value="typeId"
                :items="typeItem"
                label="Report Category"
                color="#006622"
                filled
                dense
                :item-text="item=>item.name"
                required
                return-object
                prepend-icon="mdi-card-bulleted-settings-outline"
              />
              <v-card-actions id="btn-group" class="pb-6" width="100%">
                <v-btn @click="deleteReport" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;">Delete Report category</v-btn>
                <v-btn @click="updateReport" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;">Edit Report Category</v-btn>
              </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
       <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white" > {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      leafletDisable: false,
      snackbar: false,
      loading: false,
      systemMessage: null,
      item: [],
      typeItem: [],
      id: null,
      selectedId: null,
      typeId: null,
      name: null,
      type: null,
      datasetId: null,
      embedUrl: null,
      reportId: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.get('/report/findAllAndCategory')
        .then((response) => {
          // console.log(response.data[0])
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`
      window.axios.get('/reportCategory/findAll')
        .then((response) => {
          this.typeItem = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    deleteReport () {
      const reports = this.id
      if (reports !== null) {
        const size = reports.length
        if (size > 0) {
          var categoryId = null
          // console.log(reportCategory)
          window.axios.post('/report/updateCategory', { reports: reports, categoryId: categoryId })
            .then((response) => {
              // eslint-disable-next-line
              this.systemMessage = 'Successfully edited the report'
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the report' }
              this.snackbar = true
              this.allUserType()
              this.loading = false
            })
            .catch(() => {
              this.systemMessage = 'Sorry an error occurred, please try again. '
              this.loading = false
              this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again ' }
            })
        }
      }
    },
    updateReport () {
      const reports = this.id
      if (reports !== null) {
        const size = reports.length
        const reportCategory = this.type
        // console.log('----->', reportCategory)
        if (reportCategory !== null && size > 0) {
          var categoryId = reportCategory.id
          // console.log(reportCategory)
          window.axios.post('/report/updateCategory', { reports: reports, categoryId: categoryId })
            .then((response) => {
              // eslint-disable-next-line
              this.systemMessage = 'Successfully edited the report'
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the report' }
              this.snackbar = true
              this.allUserType()
              this.loading = false
            })
            .catch(() => {
              this.systemMessage = 'Sorry an error occurred, please try again. '
              this.loading = false
              this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred, please try again ' }
            })
        }
      }
    },
    allUserType () {
      window.axios.get('/report/findAllAndCategory')
        .then((response) => {
          this.item = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    setupFields (item) {
      this.selectedId = item.id
      this.name = item.name
      this.embedUrl = item.embed_url
      this.datasetId = item.dataset_id
      this.reportId = item.report_id
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
