<template>
  <v-app id="inspire" class="grey lighten-3">
   <UserNavbar/>
   <v-main>
     <v-flex>
         <v-container
        fluid
      >
        <v-layout px-5  row>
          <v-flex xs10 dense>
            <div class="subtitle" style=" font-size: 0.98em;">Dashboard</div>
          </v-flex>
          <v-flex class="text-right" xs2 >
            <v-spacer/>
             <v-breadcrumbs class="pa-0" :items="crumbItems" id="crumbs">
              <template v-slot:item="{item}">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" @click="breadCrumbClick(item)" style=" font-size: 0.98em;">
                  {{item.text}}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>
        <UserHeader/>
        <v-layout row>
          <v-flex xs12 pa-5>
            <v-card
              tile
              class="mx-auto"
            >
              <v-list>
                <v-list-item-content>
                  <v-list-item-subtitle :v-model="name" class="pl-4">
                    {{name}}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content >
                  <VChart v-if="chartloaded" :chartdata="chartdata" :options="chartoptions" />
                </v-list-item-content>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
     </v-flex>
   </v-main>
  </v-app>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import UserHeader from '@/components/UserHeader'
import VChart from '../barchart'
export default {
  data: () => {
    return {
      name: localStorage.getItem('name'),
      crumbItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '#'
        }
      ],
      chartloaded: false,
      chartdata: null,
      chartoptions: null,
      chartloading: false,
      items: [],
      headers: [ { text: 'Report Name', value: 'name' },
        { text: 'Author', value: 'report_by' },
        { text: 'Date Created', value: 'date_created' },
        { text: 'Report Type', value: 'type' }
      ]
    }
  },
  components: {
    UserNavbar,
    UserHeader,
    VChart
  },
  mounted: [
    function () {
      this.name = localStorage.getItem('name')
      this.chartdata = {
        labels: ['This Week', 'Last Week', '3 Weeks Ago', '4 Weeks Ago', '5 Weeks Ago', '6 Weeks Ago', '7 Weeks Ago'],
        datasets: [
          {
            label: `Central region Data`,
            borderColor: ['#339966', '#339966', '#339966', '#339966', '#339966', '#339966', '#339966'],
            backgroundColor: ['#339966', '#339966', '#339966', '#339966', '#339966', '#339966', '#339966'],
            fill: true,
            data: [111, 333, 644, 50, 120, 556, 987]
          },
          {
            label: `Southern Region Data`,
            borderColor: ['rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)', 'rgba(231, 56, 39, 0.7)'],
            backgroundColor: ['rgba(248, 181, 0,0.7)', 'rgba(248, 181, 0, 0.7)', 'rgba(248, 181, 0, 0.7)', 'rgba(248, 181, 0, 0.7)', 'rgba(248, 181, 0, 0.7)', 'rgba(248, 181, 0, 0.7)', 'rgba(248, 181, 0, 0.7)'],
            fill: true,
            data: [400, 233, 644, 98, 120, 756, 687]
          }
        ]
      }

      this.chartoptions = {
        responsive: true,
        maintainAspectRatio: false
      }
      this.chartloaded = true
      this.chartloading = false
    }
  ],
  methods: {
    breadCrumbClick (item) {
      this.$router.push(item.route).catch(failure => {
        window.location.reload()
      })
    }
  }
}
</script>

<style scoped>
#crumbs{
  align-content: right;
  justify-content: right;
}
</style>
