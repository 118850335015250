<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list dense flat disabled>
            <v-list-item-subtitle class="pl-2 pt-6">
              My Profile
            </v-list-item-subtitle>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-2 pt-4">
              <p class="caption black--text font-weight-medium">Firstname:</p>
              <v-list shaped dense  >
                <v-list-item-group>
                  <v-list-item>
                    <v-icon color="#44a08d">mdi-account</v-icon>
                    <v-list-item-title class="pl-4">{{firstname}}</v-list-item-title>
                  </v-list-item>
              </v-list-item-group>
              </v-list>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-2  pt-2">
              <p class="caption black--text font-weight-medium">Surname:</p>
               <v-list shaped dense disabled>
                <v-list-item-group>
                  <v-list-item>
                    <v-icon color="#f8b500">mdi-account-box</v-icon>
                    <v-list-item-title class="pl-4">{{surname}}</v-list-item-title>
                  </v-list-item>
              </v-list-item-group>
              </v-list>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-2 pt-2">
              <p class="caption black--text font-weight-medium">Phone:</p>
               <v-list shaped dense disabled>
                <v-list-item-group>
                  <v-list-item>
                    <v-icon color="#e73827">mdi-phone</v-icon>
                    <v-list-item-title class="pl-4">{{phone}}</v-list-item-title>
                  </v-list-item>
              </v-list-item-group>
              </v-list>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content class="ma-0 pa-0">
            <v-list-item-subtitle class="pl-2 pb-10 pt-2">
              <p class="caption black--text font-weight-medium">Email:</p>
               <v-list shaped flat dense disabled>
                <v-list-item-group>
                  <v-list-item>
                      <v-icon color="#c5796d">mdi-email-variant</v-icon>
                      <v-list-item-title class="pl-4"> {{email}}</v-list-item-title>
                  </v-list-item>
              </v-list-item-group>
              </v-list>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list>
        <v-spacer/>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      firstname: null,
      surname: null,
      email: null,
      phone: null,
      search: null
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var id = localStorage.getItem('kwkk')
      window.axios.post('/user/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ]
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
