<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto">
        <v-list dense>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4" style=" font-size: 0.96em;">
              {{totalViews}} Total Report Views Today
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for reports"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"/>
          </v-list-item-content>
          <v-list-item-content >
            <v-data-table
              flat
              :sort-by="['type', 'date_uploaded']"
              :sort-desc="[true, true]"
              :search="search"
              class="px-4 elevation-0"
              color="#339966"
              :headers="headers"
              :items="items"
              :items-per-page=15
              @click:row="loadReport"
              style="cursor: pointer;"
              return-object>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
     <template>
  <v-row justify="center">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
      max-width="315">
      <v-card tile>
        <v-progress-linear
          indeterminate
          color="green"
          striped
          class="mb-0"
        ></v-progress-linear>
        <v-card-title class="subtitle-2">
          Loading the {{reportName}} Report
        </v-card-title>
        <v-card-text pb-4><v-icon color="#f8b500">mdi-server</v-icon> processing please wait...</v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  </template>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19">
      <v-layout class="pa-4">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      reportName: '',
      dialog: false,
      snackbar: false,
      totalViews: 0,
      search: null,
      items: [],
      headers: [ { text: 'Report Name', value: 'name' },
        { text: 'Date Uploaded', value: 'date_uploaded' },
        { text: 'Report Type', value: 'type' },
        { text: 'Times Viewed', value: 'count' }
      ],
      snackbarError: { icon: 'mdi-server-network-off', label: 'Network error, cannot load the report, please check your network.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/report/findMostViewedToday')
        .then((response) => {
          this.items = response.data

          for (var i = 0; i < this.items.length; i++) {
            this.totalViews += this.items[i].count
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    loadReport (item) {
      this.reportName = item.type
      if (item.type === 'Power Bi') {
        this.dialog = true
        const reportId = item.report_id
        const datasetId = item.dataset_id
        const embedUrl = item.embed_url
        window.axios.post('/viewership/getEmbedToken', { reportId: reportId, datasetId: datasetId })
          .then(async (response) => {
            // getting the encryption passphrase
            const token = response.data.token
            this.dialog = false
            const passphrase = process.env.VUE_APP_PASSPHRASE
            const encryptedToken = this.CryptoJS.AES.encrypt(token, passphrase).toString()
            const encryptedReportId = this.CryptoJS.AES.encrypt(reportId, passphrase).toString()
            const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
            const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
            const encryptedDatasetId = this.CryptoJS.AES.encrypt(datasetId, passphrase).toString()
            localStorage.setItem('datasetId', encryptedDatasetId)
            localStorage.setItem('name', encryptedName)
            localStorage.setItem('tken', encryptedToken)
            localStorage.setItem('reportId', encryptedReportId)
            localStorage.setItem('embUrl', encryptedEmbedUrl)
            this.$router.push('powerBiVisual')
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.dialog = false
          })
      } else if (item.type === 'Web Embed' || item.type === 'Dash') {
        this.dialog = true
        this.dialog = false
        const embedUrl = item.embed_url
        const passphrase = process.env.VUE_APP_PASSPHRASE
        const encryptedEmbedUrl = this.CryptoJS.AES.encrypt(embedUrl, passphrase).toString()
        const encryptedName = this.CryptoJS.AES.encrypt(item.name, passphrase).toString()
        localStorage.setItem('name', encryptedName)
        localStorage.setItem('embUrl', encryptedEmbedUrl)
        this.$router.push('seeWebMap')
      } else {
        // this.$router.push('javaScriptVisual')
      }
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
