<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
        <v-list>
          <v-list-item-content>
            <v-list-item-subtitle class="pl-4 " style=" font-size: 0.98em;">
              Manage Report Categories
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
              <v-text-field
                class="px-4"
                v-model="search"
                label="Search for report categories"
                single-line
                hide-details
                color="#009933"
                prepend-icon="mdi-magnify"
              />
          </v-list-item-content>
          <v-list-item-content >
          <v-data-table
              class="px-4 elevation-0"
              :headers="headers"
              :items="items"
              sort-by="userGroupName"
              :search="search"
              style="cursor: pointer;"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                <p>Groups Permissions</p>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        tile
                        style="font-size: 0.88em;"
                        class="px-12 text-none"
                        v-bind="attrs"
                        v-on="on"
                        color="#339966"
                      >
                        Add a Category
                      </v-btn>
                    </template>
                    <v-card tile>
                      <v-card-title class="subtitle-1">
                        <span style="font-size: 0.88em;" >{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              class="mt-4 mb-4 pa-0"
                            >
                              <v-text-field
                                class="ma-0 pa-0"
                                v-model="editedItem.name"
                                label="Category Name"
                                color="#006622"
                                filled
                                dense
                                required
                                prepend-icon="mdi-stamper"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              class="mt-0 mb-4 pa-0"
                            >
                              <v-text-field
                                class="ma-0 pa-0"
                                v-model="editedItem.fullname"
                                label="Category Fullname"
                                color="#006622"
                                filled
                                dense
                                required
                                prepend-icon="mdi-steam"
                              />
                            </v-col>
                             <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              class="mt-0 mb-2 pa-0"
                            >
                              <v-text-field
                                class="ma-0 pa-0"
                                v-model="editedItem.description"
                                label="Category Description"
                                color="#006622"
                                filled
                                dense
                                required
                                prepend-icon="mdi-clover"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#006622"
                          text
                          style="font-size: 0.88em;"
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="#006622"
                          text
                          @click="save"
                          style="font-size: 0.88em;"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card tile>
                    <v-card-text class="text-center pt-4 pb-2" style=" font-size: 0.98em;" ><h4>Are you sure you want to delete this category?</h4></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#339966" text @click="closeDelete" style=" font-size: 0.88em;">Cancel</v-btn>
                        <v-btn color="#339966" text @click="deleteItemConfirm" style=" font-size: 0.88em;">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-2"
                  color="#339966"
                  medium
                  @click="editItem(item)"
                >
                  mdi-fountain-pen
                </v-icon>
                <v-icon
                  color="#339966"
                  medium
                  @click="deleteItem(item)"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  dark
                  tile
                  style="font-size: 0.88em;"
                  class="text-none"
                  color="#339966"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-list-item-content>
        </v-list>
      </v-card>
    </v-flex>
        <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      name: '',
      fullname: '',
      description: '',
      snackbar: false,
      selectedUserId: null,
      selectedReportIds: [],
      seletedDeleteItem: null,
      userId: null,
      reportId: null,
      dialog: false,
      dialogDelete: false,
      search: null,
      items: [],
      userItems: [],
      reportItems: [],
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' },
      headers: [ { text: 'Name', value: 'name' },
        { text: 'Fullname', value: 'fullname' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        fullname: '',
        description: ''
      },
      defaultItem: {
        id: '',
        name: '',
        fullname: '',
        description: ''
      }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/reportCategory/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      window.axios.get('/user/findAll')
        .then((response) => {
          this.userItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add a Category' : 'Edit Category'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    reloadReportCategories () {
      window.axios.get('/reportCategory/findAll')
        .then((response) => {
          this.items = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      // this.editedIndex = this.items.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      // check the item to delete here
      this.selectedDeleteItem = item
    },

    deleteItemConfirm () {
      // delete the permission here
      this.closeDelete()
      const id = this.selectedDeleteItem.id
      window.axios.post('/reportCategory/delete', { id: id })
        .then((response) => {
          this.reloadReportCategories()
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully deleted the report category.' }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'A system error occurred please try again.' }
        })
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        // above is the edited item
        const id = this.editedItem.id
        const name = this.editedItem.name
        const fullname = this.editedItem.fullname
        const description = this.editedItem.description
        window.axios.post('/reportCategory/update', { name: name, fullname: fullname, description: description, id: id })
          .then((response) => {
            if (response.data.affectedRows !== 0) {
              this.selectedUserId = null
              this.selectedReportIds = []
              this.reloadReportCategories()
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited the report category.' }
            } else {
              const message = response.data.message
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: message }
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to edit the report category.' }
          })
      } else {
        const name = this.editedItem.name
        const fullname = this.editedItem.fullname
        const description = this.editedItem.description
        if (name !== '' && fullname !== null && description !== '') {
          window.axios.post('/reportCategory/insert', { name: name, fullname: fullname, description: description })
            .then((response) => {
              if (response.data.affectedRows !== 0) {
                this.selectedUserId = null
                this.selectedReportIds = []
                this.reloadReportCategories()
                this.snackbar = true
                this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully added the report category.' }
              } else {
                const message = response.data.message
                this.snackbar = true
                this.snackbarError = { icon: 'mdi-server-security', label: message }
              }
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.snackbar = true
              this.snackbarError = { icon: 'mdi-server-security', label: 'Failed to add the report category.' }
            })
        }
      }
      this.close()
    },
    userClick (item) {
      this.selectedUserId = item
      window.axios.post('/report/findUnselectedByUserId', { userId: item })
        .then((response) => {
          this.reportItems = response.data
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    reportClick (item) {
      this.selectedReportIds = item
    }
  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 0.84em;
}

.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
