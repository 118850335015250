<template>
  <v-layout row>
    <v-flex xs12 pa-5>
      <v-card
        tile
        class="mx-auto"
      >
      <v-flex>
        <v-progress-linear
          color="#009933"
          height="4.5"
          indeterminate
          :active="loading"
        />
        </v-flex>
      <v-list dense>
            <v-list-item-subtitle class="pl-4 pt-2" style=" font-size: 0.98em;">
              Edit Profile
            </v-list-item-subtitle>
        </v-list>
            <v-card-actions width="100%" class="px-12 mx-2">
              <v-spacer/>
                <v-btn-toggle tile>
                  <v-btn  v-if="isAdmin == true" @click="adminRequest" class="text-none" color="#339966" small dark tile style="font-size: 0.88sem;"> Request Admin Status </v-btn>
                  <v-btn @click="updatePassword" class="text-none" color="#339968" small dark tile style="font-size: 0.88em;"> Edit Password </v-btn>
                </v-btn-toggle>
              </v-card-actions>
        <v-form class="px-8 mr-6 ml-6 mb-6 mt-2">
          <v-list dense class="ma-0 pa-0">
              <v-text-field
                v-model="firstname"
                color="#006622"
                filled
                dense
                label="Firstname"
                name="Firstname"
                prepend-icon="mdi-account-box-outline"
                type="text"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
              <v-text-field
                    v-model="surname"
                    color="#006622"
                    filled
                    dense
                    label="Surname"
                    name="Surname"
                    prepend-icon="mdi-account-box-multiple-outline"
                    type="text"
              />
          </v-list>
          <v-list dense class="ma-0 pa-0">
            <v-text-field
                  v-model="email"
                  color="#006622"
                  filled
                  dense
                  label="Email"
                  name="Email"
                  prepend-icon="mdi-email-variant"
                  type="text"
            />
          </v-list>
          <v-list dense class="ma-0 pa-0">
              <v-text-field
                    v-model="phone"
                    color="#006622"
                    filled
                    dense
                    label="Phone"
                    name="Phone"
                    prepend-icon="mdi-cellphone"
                    type="text"
              />
              <v-card-actions id="btn-group" class="pb-6" width="100%">
                <v-btn @click="update" class="px-12 text-none" color="#339966" dark tile style="font-size: 0.88em;" > Edit Profile </v-btn>
              </v-card-actions>
          </v-list>
        </v-form>
        <v-spacer/>
      </v-card>
    </v-flex>
    <v-snackbar
      id="snack"
      color="#339966"
      v-model="snackbar"
      right
      style="position: fixed; "
      class="mb-6 ma-19"
    >
      <v-layout class="pa-2">
        <span name="app" dark style=" font-size: 0.88em;"><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
        <v-spacer/>
      </v-layout>
   </v-snackbar>
     <template>
      <v-row justify="center">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="dialog"
          persistent
          max-width="300"
        >
          <v-card tile>
            <v-progress-linear
              indeterminate
              color="green"
              striped
              class="mb-0"
            ></v-progress-linear>
            <v-card-title class="subtitle-2">
              Admin Status Request
            </v-card-title>
            <v-card-text pb-4><v-icon color="#f8b500">mdi-account-tie</v-icon> processing please wait...</v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar
          id="snack"
          color="#339966"
          v-model="snackbar"
          right
          style="position: fixed; "
          class="mb-6 ma-19"
        >
          <v-layout class="pa-2">
            <span name="app" dark><v-icon color="white"> {{snackbarError.icon}}</v-icon> {{snackbarError.label}}  </span>
            <v-spacer/>
          </v-layout>
      </v-snackbar>
      </v-row>
    </template>
  </v-layout>
</template>

<script>
export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => {
    return {
      isAdmin: false,
      dialog: false,
      snackbar: false,
      loading: false,
      systemMessage: null,
      id: null,
      firstname: null,
      surname: null,
      email: null,
      phone: null,
      password: null,
      search: null,
      snackbarError: { icon: 'mdi-server-network-off', label: 'A system error occurred, please try again.' }
    }
  },
  mounted: [
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var id = localStorage.getItem('kwkk')
      window.axios.post('/user/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.id = response.data[0].id
            this.password = response.data[0].password
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    function () {
      const passphrase = process.env.VUE_APP_PASSPHRASE
      const accessToken = localStorage.getItem('token')
      const decryptedAccessToken = this.CryptoJS.AES.decrypt(accessToken, passphrase).toString(this.CryptoJS.enc.Utf8)
      window.axios.defaults.headers.common.Authorization = `Bearer ${decryptedAccessToken}`

      var id = localStorage.getItem('kwkk')
      window.axios.post('/user/findNonAdmin', { id: id })
        .then((response) => {
          const length = response.data.length
          if (length > 0) {
            this.isAdmin = true
          } else {
            this.isAdmin = false
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    }
  ],
  methods: {
    reset () {
      this.firstname = ''
      this.surname = ''
      this.email = ''
      this.phone = ''
    },
    update () {
      var userTypeId = localStorage.getItem('utp')
      if (this.firstname === '' || this.firstname === null || this.surname === '' || this.surname === null || this.email === '' || this.email === null) {
        if (this.firstname === '' || this.firstname === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in your firstname' }
        } else if (this.surname === '' || this.surname === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in your surname' }
        } else if (this.email === '' || this.email === null) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in your email address' }
        } else if (this.phone === '' || this.phone.length < 10) {
          this.snackbar = true
          this.snackbarError = { icon: 'mdi-server-security', label: 'Please fill in the textfield with your correct phone number' }
        }
      } else {
        this.loading = true
        this.systemMessage = ''
        window.axios.post('/user/update', { id: this.id, firstname: this.firstname, surname: this.surname, email: this.email, phone: this.phone, password: this.password, userTypeId: userTypeId })
          .then((response) => {
            // eslint-disable-next-line
            var affectedRows = response.data.affectedRows
            this.snackbar = true
            if (affectedRows === 1) {
              this.systemMessage = 'Successfully edited your profile'
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited your profile' }
            } else {
              this.systemMessage = 'Successfully edited your profile'
              this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully edited your profile' }
            }
            this.loading = false
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.snackbar = true
            this.systemMessage = 'Sorry an error occurred, please try again. '
            this.loading = false
          })
      }
    },
    updatePassword () {
      this.$router.push('/userEditPassword')
    },
    load () {
      var id = localStorage.getItem('kwkk')
      window.axios.post('/user/find', { id: id })
        .then((response) => {
          if (response.data[0] != null) {
            this.firstname = response.data[0].firstname
            this.surname = response.data[0].surname
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.id = response.data[0].id
            this.password = response.data[0].password
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
        })
    },
    adminRequest () {
      this.dialog = true
      var id = localStorage.getItem('kwkk')
      window.axios.post('/adminRequest/insert', { userId: id })
        .then((response) => {
          // console.log(response.data.affectedRows)
          if (response.data.affectedRows === 0) {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: response.data.message }
          } else {
            this.snackbar = true
            this.snackbarError = { icon: 'mdi-server-security', label: 'Successfully sent the admin status request !' }
            window.location.reload()
          }
          this.dialog = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.dialog = false
          this.snackbarError = { icon: 'mdi-server-security', label: 'Cannot connect to the server, network error !' }
          this.snackbar = true
        })
    }
  }
}
</script>

<style scoped>
#btn-group{
  justify-content: center;
}
.v-text-field >>> input {
  font-size: 0.84em;
}
.v-text-field >>> label {
  font-size: 0.84em;
}
</style>
